<template>
    <div class="tw-flex tw-flex-col tw-w-full tw-max-w-screen-lg xl:tw-max-w-screen-xl tw-px-5 lg:tw-px-0 md:tw-mx-auto tw-mb-10">
        <ul class="breadcrumbs">
            <li>
                <router-link to="/">Pages</router-link>
            </li>
        </ul>

        <div class="article-page__container">
            <h1>Not Found</h1>
            <div class="article-page__content">
                <p class="tw-text-gray-dark">The page you have requested could not be found.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    };
</script>

<style src="@/assets/scss/views/Article.scss" lang="scss"></style>
