<template>
    <teleport to="#overlay">
        <div class="overlay__right">
            <div class="cart">
                <div class="overlay__header">
                    <div class="overlay__header__cart-icon">
                        <div>
                            <img v-svg-inline src="@/assets/img/cart.svg" />
                        </div>
                    </div>

                    <div class="overlay__header-text">
                        Warenkorb
                        <div class="overlay__header-text-items">
                            {{ qty }}
                        </div>
                    </div>
                    <span @click="close" class="overlay__header__close-icon" >
                        <div>
                            <img v-svg-inline src="@/assets/img/x.svg"/>
                        </div>
                    </span>
                </div>
                <div class="cart-modal-content">
                    <transition name="appear" :duration="1000" appear>
                        <div class="cart__empty" v-if="empty">
                            <h4>DEIN WARENKORB IST LEER</h4>
                            <p>
                                Du hast noch keine Produkte in deinen Warenkorb gelegt.
                                Bei uns findest du bestimmt etwas passendes.
                            </p>
                            <div class="cart-page__empty__action">
                                <button class="td td-160" @click="to('/')">Weiter einkaufen</button>
                            </div>
                        </div>
                    </transition>
                    <ul class="cart__list">
                        <li class="cart__list__item" v-for="it in cart.items" :key="it.id">
                            <router-link :to="it.url || '/'">
                                <img :src="it.image" />
                            </router-link>
                            <div class="cart__list__item__details">
                                <span>{{ it.qty }}x</span>
                                <router-link :to="it.url || '/'">
                                    <p style="padding-right: 34px">
                                        <span class="cart__list__item__details__text">
                                            {{ it.title }}
                                        </span>
                                        <span v-if="it.subtitle" style="opacity:.5">
                                            <span>{{ it.subtitle }}</span>
                                        </span>
                                    </p>
                                </router-link>
                                <p class="cart__list__item__details__price">{{ price(it.price * it.qty, 2) }}</p>
                                <div @click="clear(it)" class="cart__list__item__details__delete-icon">
                                    <div>
                                        <img v-svg-inline src="@/assets/img/icons/trash.svg" />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="cart__footer">
                        <div class="cart__footer__subtotoal">
                            <span class="cart__footer__subtotoal__title">Zwischensumme</span>

                            <span>{{ price(total) }}</span>
                        </div>
                        <div class="layout-row cart__footer__buttons">
                            <div class="cart__footer__button">
                                <router-link to="/cart">
                                    <button class="secondary">Zum Warenkorb</button>
                                </router-link>
                            </div>
                            <div class="cart__footer__button">
                                <router-link to="/checkout">
                                    <button>Zur Kasse</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
    import { price } from "@/services/Formatters";
    import Gtm from "@/services/GtmHelper";

    export default {
        inject: ["cart", "top"],
        methods: {
            price,
            to(url) {
                this.close();
                this.$router.push(url);
            },
            close() {
                this.top.overlay = null;
                this.top.cart = false;
            },
            clear(variant)  {
                this.cart.clear(variant);
                Gtm.removeFromCart(this.$gtm, variant)
            }
        },
        computed: {
            qty() {
                return this.cart.items.length;
            },
            empty() {
                return this.qty == 0;
            },
            total() {
                return this.empty ? 0 : this.cart.items.reduce((a, b) => a + b.price * b.qty, 0);
            }
        },
    }
</script>

<style src="@/assets/scss/views/Cart.scss" lang="scss"></style>
