<template>
    <div :class="['step', { 'active current': active }]">
        <router-link :to="url">
            <span>{{ text }}</span>
            <div class="lines">
                <span>
                    <img src="@/assets/img/icons/check-white.svg" />
                    <img class="step-mob-icon" src="@/assets/img/icons/check-green.svg" alt=""/>
                </span>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        props: ["url", "text", "active"],
    }
</script>
