<template>
    <teleport to="#overlay">
        <div class="overlay__modal" v-if="modelValue" @click.stop="outside">
            <div class="motolino-modal">
                <div class="modal-close" @click.stop="cancel">
                    <img v-svg-inline src="@/assets/img/x.svg" />
                </div>
                <div class="modal-title">
                    <p>{{ title }}</p>
                </div>
                <div class="modal-text">
                    <slot></slot>
                </div>
                <div class="layout-row">
                    <button class="modal-button green" @click.stop="sayYes" v-if="yes">
                        {{ yes }}
                    </button>
                    
                    <button class="modal-button" @click.stop="sayNo" v-if="no">
                        {{ no }}
                    </button>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        props: ["modelValue", "required", "title", "yes", "no"],
        inject: ["top"],
        emits: ["yes", "no", "cancel", "update:modelValue"],
        data: () => ({
            changing: false,
        }),
        methods: {
            sayYes() {
                this.$emit("yes");
                this.$emit("update:modelValue", false);
            },
            sayNo() {
                this.$emit("no");
                this.$emit("update:modelValue", false);
            },
            cancel() {
                this.$emit("cancel");
                this.$emit("update:modelValue", false);
            },
            outside(e) {
                if (e.target && e.target.classList.contains("overlay__modal"))
                    this.cancel();
            }
        },
        watch: {
            modelValue(value) {
                this.top.overlay = value ? "modal" : null;
            },
            "top.overlay"(value) {
                if (this.modelValue && !value)
                    this.cancel();
            }
        }
    }
</script>

<style src="@/assets/scss/views/Dialog.scss" lang="scss"></style>