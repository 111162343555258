<template>
    <div :class="['checkout-card', { open: open }]">
        <div class="arrow-back" @click="$emit('close')">
            Close
            <img src="@/assets/img/arrow_right.svg" />
        </div>
        <div class="checkout-card__scroll-wrapper">
            <!-- <div class={`arrow-top ${arrow.top ? 'show' : ''}`}>
                <img src={arr} alt=""/>
            </div>
            <div class={`arrow-bottom ${arrow.bottom ? 'show' : ''}`}>
                <img src={arr} alt=""/>
            </div> -->
            <div class="checkout-card__scroll">
                <div class="checkout-card__item" v-for="(it, i) in items" :key="i">
                    <div class="checkout-card__item-image">
                        <img :src="it.image" />
                    </div>
                    <div class="checkout-card__item-info">
                        <div class="name">
                            {{ it.title }}
                            <div class="checkout-card__delete-icon" @click="remove(it)" v-if="!status.completed">
                                <div>
                                    <img v-svg-inline src="@/assets/img/garbage.svg" />
                                </div>
                            </div>
                        </div>
                        <div class="row-price">
                            <div class="price">
                                <span>{{ it.qty }} X </span>
                                {{ price(it.price, 2) }}
                            </div>
                            <div class="to-config" @click="$router.push('/configurator/builder')" v-if="!status.completed && it.mode == 'vespa' && it.tags.includes('Bike')">
                                <img src="@/assets/img/checkout/filter-grey.svg" />
                                <div>ändern</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="checkbox-card__bottom">
            <div class="simple-item">
                <div class="label">Subtotal</div>
                <div class="value">{{ price(subtotal, 2) }}</div>
            </div>
            <div class="simple-item">
                <div class="label">Versandkosten</div>
                <div class="value">{{ price(shipping, 2) }}</div>
            </div>
            <div class="simple-item">
                <div class="label">davon MwSt.</div>
                <div class="value">{{ price(tax, 2) }}</div>
            </div>
            <div class="simple-item" v-if="fee > 0">
                <div class="label">Zahlungsg. 2.9%</div>
                <div class="value">{{ price(fee, 2) }}</div>
            </div>
            <div class="total">
                <div class="label">Total</div>
                <div class="value">{{ price(total) }}</div>
            </div>
            <div class="simple-item" v-if="leasing.acceptedRate">
                <div class="label">Monatl.</div>
                <div class="value">{{ price(leasing.acceptedRate, 2) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { price } from "@/services/Formatters";
    export default {
        props: ["open"],
        emits: ["close"],
        inject: ["cart", "delivery", "leasing", "order", "status"],
        methods: {
            price,
            remove(it) {
                this.cart.clear(it);

                if (this.cart.items.length == 0)
                    this.$router.push("/cart");
            }
        },
        computed: {
            subtotal() {
                return this.items.reduce((a, b) => a + b.price * b.qty, 0);
            },
            tax() {
                const total = this.subtotal + this.shipping;

                return total - total / 1.077;
            },
            fee() {
                return 0;
            },
            shipping() {
                if (this.delivery.type == "ship")
                    return 99;
                
                return 0;
            },
            items() {
                if (this.status.completed)
                    return this.order;
                else
                    return this.cart.items;
            },
            total() {
                return this.subtotal + this.fee + this.shipping;
            },
            monthly() {
                return 0;
            }
        }
    }
</script>
