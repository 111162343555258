import { watch, reactive } from "vue";

const get = key => {
    const raw = localStorage.getItem(key);
    
    return raw ? JSON.parse(raw) : null;
};

const set = (key, value) => {
    const raw = value ? JSON.stringify(value) : null;

    if (raw)
        localStorage.setItem(key, raw);
    else
        localStorage.removeItem(key);
};

const stored = (key, value) => {
    const data = get(key);
    const react = reactive(data || value);

    local(key, react);

    return react;
};

const local = (key, src, conv) => {
    watch(src, () => {
        const data = conv ? conv(src) : src;

        set(key, data);
    });
};

export { get, set, local, stored };