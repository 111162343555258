const topMenu = [
    {
        "text": "Modelle",
        "subMenu": [
            { 
                "text": "Vespa",
                "subMenu": [
                    { "text": "Vespa Primavera", "url": "/pages/prima125" },
                    { "text": "Vespa GTS", "url": "/pages/gts" },
                    { "text": "Vespa Sprint", "url": "/pages/spirnt125" },
                    { "text": "Vespa Elettrica", "url": "/pages/elettrica" },
                    { "text": "Umbauten", "url": "/pages/vespa-tuning" },
                    { "text": "Oldtimer", "url": "/pages/oldtimer" },
                ]
            },
            { 
                "text": "APE",
                "subMenu": [
                    { "text": "Piaggio APE", "url": "/category/piaggio-ape" },
                    { "text": "Piaggio APE Umbau", "url": "/pages/piaggio-ape-umbau" },
                ]
            },
            { 
                "text": "Aprilia",
                "subMenu": [
                    { "text": "Aprilia RX 125", "url": "/pages/aprilia-rx125" },
                    { "text": "Aprilia SX 125", "url": "/pages/aprilia-sx125" },
                    { "text": "Aprilia RS 125", "url": "/pages/rs125" },
                    { "text": "Aprilia RS 660", "url": "/pages/rs660" },
                ]
            },
            { 
                "text": "Piaggio",
                "subMenu": [
                    { "text": "Piaggio Liberty 125", "url": "/pages/liberty" },
                    { "text": "Piaggio Liberty S", "url": "/pages/liberty125s" },
                    { "text": "Piaggio Medley 125", "url": "/pages/medley" },
                    { "text": "Piaggio Medley S 125", "url": "/pages/meldeys" },
                ]
            },
            { "text": "Oldtimer", "url": "/pages/oldtimer" },
            { "text": "Custom", "url": "/pages/vespa-tuning" },
            { "text": "Aktionen", "url": "/category/aktionen" },
            { "text": "Vorbestellen", "url": "/category/exklusive-vorbestellung" },
        ]
    },
    {
        "text": "Shop",
        "subMenu": [
            { "text": "Wetterschutz", "url": "/category/wetterschutz" },
            { "text": "Helme", "url": "/category/helme" },
            { "text": "Gutscheine", "url": "/category/gutscheine" },
        ]
    },
    {
        "text": "Warum Motolino?",
        "subMenu": [
            { "text": "Motolino Vorteile", "url": "/pages/highlights" },
            { "text": "Sorglos-Paket", "url": "/pages/sorglos-paket" },
            { "text": "4 Jahre Garantie", "url": "/pages/motolino-garantie" },
            { "text": "Mobiler Service-Bus", "url": "/pages/mobiler-service-bus" },
            { "text": "Motolino Personal Shopper", "url": "/pages/video" },
        ]
    },
    {
        "text": "Online Termin",
        "subMenu": [
            { "text": "Service buchen", extUrl: "https://connect.shore.com/bookings/motolino-store-zurich-417567e6-7c54-41a7-9c37-62061c331e5f/services?locale=de&origin=standalone" },
            { "text": "Mobilen Service buchen", extUrl: "https://connect.shore.com/bookings/motolino-store-zurich-417567e6-7c54-41a7-9c37-62061c331e5f/services?locale=de&origin=standalone" },
            { "text": "Beratungstermin buchen", extUrl: "https://connect.shore.com/bookings/motolino-store-zurich-417567e6-7c54-41a7-9c37-62061c331e5f/services?locale=de&origin=standalone" },
            { "text": "Probefahrt buchen", extUrl: "https://connect.shore.com/bookings/motolino-store-zurich-417567e6-7c54-41a7-9c37-62061c331e5f/services?locale=de&origin=standalone" },
            { "text": "Werkstatttermin buchen", extUrl: "https://connect.shore.com/bookings/motolino-store-zurich-417567e6-7c54-41a7-9c37-62061c331e5f/services?locale=de&origin=standalone" },
        ]
    },
    {
        "text": "Finanzierung",
        "subMenu": [
            { "text": "Kauf auf Rechnung", "url": "/pages/kauf" },
            { "text": "Leasing", "url": "/pages/leasing" },
            { "text": "4 Jahre Garantie", "url": "/pages/motolino-garantie" },
            { "text": "Mobiler Service-Bus", "url": "/pages/mobiler-service-bus" },
            { "text": "Motolino Personal Shopper", "url": "/pages/video" },
        ]
    },
    {
        "text": "Motolino TV",
        "url": "/pages/motolino-tv"
    },
    {
        "text": "Standorte",
        "subMenu": [
            { "text": "Zürich", "url": "/pages/zurich" },
            { "text": "Basel", "url": "/pages/basel" },
            { "text": "Sirnach", "url": "/pages/sirnach" },
        ]
    },
];

export default topMenu;