export default {
    shopify: {
        domain: "motolino.myshopify.com",
        token: "46421f15482fea0dc7ae6066b20f4083",
        graphql: "https://motolino.myshopify.com/api/2021-04/graphql.json"
    },
    stripe: {
        apiKey: "pk_live_KdorNFPPs5h8v2EL2eUfGyJP"
    },
    configurator: {
        ids: [
            "configurator-primavera",
            "configurator-sprint",
            "configurator-gts-125",
            "configurator-gts",
            "configurator-elettrica"
        ]
    },
    pbkdf2: {
        salt: "FPVolhR93t7s",
        iterations: 150000
    },
    maritalStatusOptions: [
        { label: "Verheiratet", value: "Verheiratet" },
        { label: "Ledig", value: "Ledig" },
        { label: "Geschieden", value: "Geschieden" },
        { label: "Verwitwet", value: "Verwitwet" },
        { label: "Konkubinat", value: "Konkubinat" }
    ],
    housingSituationOptions: [
        { label: "Eigentum", value: "Eigentum" },
        { label: "Miete", value: "Miete" }
    ],
    residencePermitOptions: [
        { label: "Ausweis B", value: "Ausweis B" },
        { label: "Ausweis C", value: "Ausweis C" },
        { label: "Ausweis Ci", value: "Ausweis Ci" },
        { label: "Ausweis G", value: "Ausweis G" },
        { label: "Ausweis L", value: "Ausweis L" },
        { label: "Ausweis F", value: "Ausweis F" },
        { label: "Ausweis N", value: "Ausweis N" },
        { label: "Ausweis S", value: "Ausweis S" }
    ],
    colors: {
        Schwarz: { "background-color": "rgb(21, 21, 20)" },
        Gelb: { "background-color": "rgb(221, 201, 73)" },
        Weiss: { "background-color": "rgb(236, 238, 235)" },
        Rot: { "background-color": "rgb(203, 17, 21)" },
        Graumatt: { "background-color": "rgb(103, 106, 109)" },
        Blaumatt: { "background-color": "rgb(35, 97, 152)" },
        Schwarzmatt: { "background-color": "rgb(77, 77, 78)" },
        Grün: { "background-color": "rgb(85, 104, 91)" },
        Grau: { "background-color": "rgb(210, 210, 210)" },
        Dunkelblau: { "background-color": "rgb(50, 63, 118)" },
        Mint: { "background-color": "rgb(168, 201, 184)" },
        "Nardo Grey": { "background-color": "rgb(192, 201, 215)" },
        "Grau Delivcato": { "background-color": "rgb(192, 201, 215)" },
        "Grau Delivcato Matt": { "background-color": "rgb(192, 201, 215)" },
        "Grau Titanium": { "background-color": "rgb(192, 201, 215)" },
        "Orange Impulsivo": { "background-color": "rgb(254, 95, 55)" },
        "Blau Vivace": { "background-color": "rgb(59, 106, 166)" },
        "Weiss Innocenza": { "background-color": "rgb(240, 244, 244)" },
        "Grau Titanio": { "background-color": "rgb(106, 112, 117)" },
        "Schwarz Convinto Matt": { "background-color": "rgb(75, 76, 76)" },
        "Nardo Grey Matt": { "background-color": "rgb(149, 157, 160)" },
        "Azzurro Elettrico": { "background-color": "rgb(236, 238, 235)" }
    },
    stores: [
        {
            value: "basel",
            label: "Basel",
            city: "Basel",
            street: "Voltastrasse 88",
            zip: "4056",
            phone: "+41615152323",
            lat: 47.57129272799321,
            lng: 7.575942176850711,
            notes: [
                "Mon - Fri: 9h - 21h",
                "Samstag: 9h - 14h",
                "Sonntag: Closed"
            ]
        },
        {
            value: "sirnach",
            label: "Sirnach",
            city: "Sirnach",
            street: "Mattenrainstrasse 11",
            zip: "8370",
            phone: "+41715710058",
            lat: 47.470897913694415,
            lng: 9.00009482246713,
            notes: [
                "Falls du eine Beratung oder eine Probefahrt wünschst, bitten wir dich, uns im Voraus zu kontaktieren."
            ]
        },
        {
            value: "zurich",
            label: "Zürich",
            city: "Zürich",
            street: "Schaffhauserstrasse 210",
            zip: "8057",
            phone: "+41445585758",
            lat: 47.40131576832368,
            lng: 8.545261465301518,
            notes: [
                "Mon - Fri: 9h - 21h",
                "Samstag: 9h - 14h",
                "Sonntag: Closed"
            ]
        }
    ],
    kaskos: [
        "Allianz Suisse",
        "AXA",
        "Basler",
        "Basler Versicherung",
        "Dextra",
        "Die Mobiliar",
        "ELVIA",
        "Generali",
        "Helvetia",
        "Post Finance",
        "smile.direct",
        "TCS",
        "Vaudoise",
        "Zurich",
        "Zurich Connect"
    ],
    countries: [
        "Afghanistan",
        "Ägypten",
        "Åland",
        "Albanien",
        "Algerien",
        "Amerikanische Jungferninseln",
        "Amerikanisch-Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarktika",
        "Antigua und Barbuda",
        "Äquatorialguinea",
        "Argentinien",
        "Armenien",
        "Aruba",
        "Aserbaidschan",
        "Äthiopien",
        "Australien",
        "Bahamas",
        "Bahrain",
        "Bangladesch",
        "Barbados",
        "Bassas da India",
        "Belarus",
        "Belgien",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivien",
        "Bosnien und Herzegowina",
        "Botsuana",
        "Bouvetinsel",
        "Brasilien",
        "Britische Jungferninseln",
        "Britisches Territorium im Indischen Ozean",
        "Brunei Darussalam",
        "Bulgarien",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Chile",
        "China",
        "Clipperton",
        "Cookinseln",
        "Costa Rica",
        "Côte d'Ivoire",
        "Dänemark",
        "Deutschland",
        "Dominica",
        "Dominikanische Republik",
        "Dschibuti",
        "Ecuador",
        "El Salvador",
        "Eritrea",
        "Estland",
        "Eswatini",
        "Europa",
        "Falklandinseln",
        "Färöer",
        "Fidschi",
        "Finnland",
        "Frankreich",
        "FX",
        "Französische Süd- und Antarktisgebiete",
        "Französisch-Guayana",
        "Französisch-Polynesien",
        "Gabun",
        "Gambia",
        "Gazastreifen",
        "Georgien",
        "Ghana",
        "Gibraltar",
        "Glorieuses",
        "Grenada",
        "Griechenland",
        "Grönland",
        "Großbritannien",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard und McDonaldinseln",
        "Honduras",
        "Hongkong",
        "Indien",
        "Indonesien",
        "Insel Man",
        "Irak",
        "Iran",
        "Irland",
        "Island",
        "Israel",
        "Italien",
        "Jamaika",
        "Japan",
        "Jemen",
        "Jersey",
        "Jordanien",
        "Juan de Nova",
        "Kaimaninseln",
        "Kambodscha",
        "Kamerun",
        "Kanada",
        "Kasachstan",
        "Katar",
        "Kenia",
        "Kirgisistan",
        "Kiribati",
        "Kleinere Amerikanische Überseeinseln",
        "Kokosinseln (Keelinginseln)",
        "Kolumbien",
        "Komoren",
        "Kongo",
        "Kongo, Demokratische Republik",
        "Korea, Demokratische Volksrepublik",
        "Korea, Republik",
        "Kroatien",
        "Kuba",
        "Kuwait",
        "Laos",
        "Lesotho",
        "Lettland",
        "Libanon",
        "Liberia",
        "Libyen",
        "Liechtenstein",
        "Litauen",
        "Luxemburg",
        "Macau",
        "Madagaskar",
        "Malawi",
        "Malaysia",
        "Malediven",
        "Mali",
        "Malta",
        "Marokko",
        "Marshallinseln",
        "Martinique",
        "Mauretanien",
        "Mauritius",
        "Mayotte",
        "Mexiko",
        "Mikronesien",
        "Moldau",
        "Monaco",
        "Mongolei",
        "Montenegro",
        "Montserrat",
        "Mosambik",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Neukaledonien",
        "Neuseeland",
        "Nicaragua",
        "Niederlande",
        "Niederländische Antillen",
        "Niger",
        "Nigeria",
        "Niue",
        "Nördliche Marianen",
        "Nordmazedonien",
        "Norfolkinsel",
        "Norwegen",
        "Oman",
        "Österreich",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua-Neuguinea",
        "Paraguay",
        "Peru",
        "Philippinen",
        "Pitcairninseln",
        "Polen",
        "Portugal",
        "Puerto Rico",
        "Réunion",
        "Ruanda",
        "Rumänien",
        "Russische Föderation",
        "Saint-Martin",
        "Salomonen",
        "Sambia",
        "Samoa",
        "San Marino",
        "São Tomé und Príncipe",
        "Saudi-Arabien",
        "Schweden",
        "Schweiz",
        "Senegal",
        "Serbien",
        "CS",
        "Seychellen",
        "Sierra Leone",
        "Simbabwe",
        "Singapur",
        "Slowakei",
        "Slowenien",
        "Somalia",
        "Spanien",
        "Spitzbergen",
        "Sri Lanka",
        "St. Barthélemy",
        "St. Helena, Ascension und Tristan da Cunha",
        "St. Kitts und Nevis",
        "St. Lucia",
        "St. Pierre und Miquelon",
        "St. Vincent und die Grenadinen",
        "Südafrika",
        "Sudan",
        "Südgeorgien und die Südlichen Sandwichinseln",
        "Südsudan",
        "Suriname",
        "Syrien",
        "Tadschikistan",
        "Taiwan",
        "Tansania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad und Tobago",
        "Tromelin",
        "Tschad",
        "Tschechische Republik",
        "Tunesien",
        "Türkei",
        "Turkmenistan",
        "Turks- und Caicosinseln",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "Ungarn",
        "Uruguay",
        "Usbekistan",
        "Vanuatu",
        "Vatikanstadt",
        "Venezuela",
        "Vereinigte Arabische Emirate",
        "Vereinigte Staaten",
        "Vietnam",
        "Wallis und Futuna",
        "Weihnachtsinsel",
        "Westjordanland",
        "Westsahara",
        "Zentralafrikanische Republik",
        "Zypern"
    ]
};
