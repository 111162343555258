<template>
    <div class="configurator-root">
        <Spinner v-if="loading" />

        <section class="configurator-selection layout-column flex" v-if="!loading">
            <div :class="['layout-row flex configurator-wrapper', { 'wrapper-inspect': step == 1 }]">
                <div :class="['scooter-selection', { 'inspect': step == 1 }]" style="flex:1">
                    <transition name="move-down" :duration="{enter:700,leave:0}" appear>
                        <div class="header-wrapper" v-if="!loading">
                            <div>
                                <div class="header-arrow__left" @click="prev">
                                    <div>
                                        <img v-svg-inline src="@/assets/img/icons/arrow_left.svg" />
                                    </div>
                                </div>
                            </div>
                            <div class="header-logo">
                                <router-link to="/">
                                    <img src="@/assets/img/motolino-logo-stroke.svg" alt="Motolino logo"/>
                                </router-link>
                            </div>
                            <div class="closeImage">
                                <router-link to="/">
                                    <div>
                                        <img src="@/assets/img/x.svg"/>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </transition>
                    <transition name="move-up" :duration="{enter:600,leave:0}" appear>
                        <div class="title" v-if="!loading && step == 0">Wähle hier dein Modell als Basis deiner Konfiguration aus.</div>
                    </transition>
                    <transition name="c-move-up" :duration="{enter:800,leave:0}" appear>
                        <div class="title left" v-if="!loading && step == 1">
                            Vespa-Modell auswählen
                            <div>
                                <span class="changeBM" @click="prev">
                                    ÅNDERN VESPA
                                </span>
                            </div>
                        </div>
                    </transition>
                    <div class="scroll-hide__wrapper" style="width:100%">
                        <Spinner v-if="selecting" />
                        <div :class="['layout-row wrap flex scooters', { 'inspect': step == 1 }]" style="width:100%;" v-if="!selecting">
                            <transition-group name="appear" :duration="{enter:1200,leave:0}" appear>
                                <div :class="[step == 1 ? 'scooter-wrapper' : 'scooter-wrapper-base']" v-for="(it, i) in data" :key="i" v-show="!loading">
                                    <div :class="['scooter', { 'selected': it.picked }]" @click="pick(it)">
                                        <img class="scooter-thumb" :src="it.image" />
                                        <div class="scooter-name">{{ it.title }}</div>
                                        <div class="scooter-description" v-html="it.teaser"></div>
                                        <transition name="appear" :duration="300">
                                            <div class="scooter-selected" v-if="it.picked">
                                                <img v-svg-inline src="@/assets/img/check.svg"/>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="layout-row variant-color-boxes" v-if="step == 1">
                                        <div :style="c" class="variant-color-box" v-for="(c, i) in colors(it)" :key="i"></div>
                                    </div>
                                </div>
                            </transition-group>
                        </div>
                    </div>
                    <div v-if="!loading && !selecting && step == 0">
                        <transition name="appear" :duration="{enter:900,leave:0}" appear>
                            <div :class="['configure-button', { 'disabled': !active }]" @click="select()" v-if="!loading && !selecting && step == 0">
                                <img src="@/assets/img/arrow_right_w.svg" alt="arrow right icon"/>
                                Wähle ein Modell
                            </div>
                        </transition>
                    </div>
                </div>
                <transition name="appear" :duration="{enter:500,leave:0}" appear>
                    <div :class="['scooter-info-box', { 'scooter-mob-open': active }]" v-if="step == 1 && !askResume">
                        <div :class="['scooter-info__inner flex layout-column', { 'placeholder-section': active }]">
                            <div class="close-modal-scooter" @click="active = null">
                                <img class="icon" src="@/assets/img/icons/arrow_left.svg" />
                            </div>
                            <div class="scooter-info flex" v-if="!active">
                                <div class="scooter-name"><img v-svg-inline src="@/assets/img/text-example.svg"/></div>
                                <div class="scooter-image-shadow">
                                    <img src="@/assets/img/active-bike-example.png"/>
                                </div>
                            </div>
                            <div class="configure-button-shadow" v-if="!active"></div>
                            <div class="scooter-info flex layout-row" v-if="active">
                                <div class="flex layout-column" style="max-width: 290px">
                                    <transition-group name="scooter-opacity" appear :duration="{enter:700, leave:0}">
                                        <div class="scooter-name" v-if="active">{{ active.title }}</div>
                                        <div class="scooter-about" v-if="active">
                                            <div v-html="active.descriptionHtml"></div>
                                        </div>
                                        <div v-if="active">
                                            <div class="scooter-price">{{ price(bike.price) }}</div>
                                            <div class="scooter-price-info">einmalig inkl. MwSt.</div>
                                            <div class="scooter-price-or">oder</div>
                                            <div class="scooter-price">{{ price(bike.price / 60) }}</div>
                                            <div class="scooter-price-info">über 60 Monate inkl. MwSt.</div>
                                        </div>
                                    </transition-group>
                                </div>
                                <transition name="appear" appear :duration="{enter:800, leave:0}">
                                    <div class="scooter-image" v-if="active">
                                        <img :src="bike.image" />
                                    </div>
                                </transition>
                            </div>
                            <transition name="appear" :duration="{enter:900, leave:0}" appear>
                                <div class="configurator-button-link" v-if="active">
                                    <div class="configure-button" @click="configure()">
                                        <img v-svg-inline src="@/assets/img/arrow_right_w.svg" />
                                        KONFIGURIEREN
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div class="scooter-text">
                            <transition name="appear" appear :duration="{enter:1000, leave:0}">
                                <div class="scooter-text-desc" v-if="!active">
                                    <span>Wähle nun dein gewünschtes Modell aus, um die Konfiguration zu starten.</span>
                                </div>
                            </transition>
                            <transition name="appear" appear :duration="{enter:1000, leave:0}">
                                <img src="@/assets/img/config-arrow.svg" v-if="!active" />
                            </transition>
                        </div>
                    </div>
                </transition>
            </div>
        </section>

        <Dialog2 v-model="askResume"
                title="Konfiguration fortsetzen"
                yes="Ja, fortsetzen"
                no="Neue Konfiguration starten"
                @cancel="reset"
                @yes="resume"
                @no="reset"

        >
            <p>Möchtest du mit der Konfiguration von <strong>{{ rator.state.bike?.title }}</strong> fortsetzen ?</p>
        </Dialog2>
    </div>
</template>

<script>
    import { price } from "@/services/Formatters";
    import config from "@/services/Config";

    export default {
        inject: ["rator", "top", "cart"],
        data: () => ({
            active: null,
            loading: true,
            selecting: false,
            askResume: false,
        }),
        mounted() {
            this.load();
        },
        methods: {
            price,
            resume() {
                this.$router.replace("/configurator/builder");
            },
            reset() {
                this.rator.reset();
                this.cart.clearMode("vespa");
                if (this.data) {
                    this.data.forEach(d => {
                        if (d.bikes) {
                            d.bikes.forEach(b => b.picked = false)
                        }
                    });
                }
            },
            async load() {
                this.loading = true;

                await this.rator.load();

                if (this.rator.state.step == 1)
                    this.reset();

                this.loading = false;

                if (this.rator.state.step == 2)
                    this.askResume = true;
            },
            select() {
                if (!this.active)
                    return;

                const self = this;

                this.rator.next(this.active);
                this.active = null;
                this.selecting = true;

                setTimeout(function() {
                    self.selecting = false;
                }, 250);
            },
            prev() {
                const self = this;

                if (this.rator.state.step == 0)
                    this.$router.push("/");

                if (this.rator.state.step == 1) {
                    setTimeout(function() {
                        self.loading = false;
                    }, 250);
                }

                this.active = null;
                this.loading = true;
                this.rator.prev();
            },
            pick(item) {
                if (this.step == 1 && item.picked)
                    return;

                const oldId = this.active ? this.active.id : null;
                const newId = item.id;

                this.active = oldId === newId ? null : item;
            },
            configure() {
                this.rator.next(this.active);
                this.active = null;

                this.$router.push("/configurator/builder");
            },
            colors(bike) {
                const opt = bike.options.find(opt => opt.name == "Vespa Farbe");

                if (opt)
                    return opt.values.map(v => config.colors[v]);

                return [];
            }
        },
        watch: {
            active(newValue, oldValue) {
                if (oldValue) oldValue.picked = false;
                if (newValue) newValue.picked = true;
            },
        },
        computed: {
            step() {
                if (this.askResume)
                    return 0;

                return this.rator.state.step;
            },
            bike() {
                return this.active ? this.active.variants[0] : null;
            },
            data() {
                if (this.askResume)
                    return this.rator.state.data;

                return this.rator.state.collection ? this.rator.state.collection.bikes : this.rator.state.data;
            }
        }
    }
</script>

<style lang="scss" src="@/assets/scss/views/Configurator.scss"></style>
