<template>
    <transition name="appear" :duration="1000">
        <div class="message message__status-neutral" v-if="data">
            <p class="message__title">{{ data.title }}</p>
            <div class="message__content" v-if="data.content">{{ data.content }}</div>
            <div @click="close">
                <img v-svg-inline src="@/assets/img/x.svg" class="message__close-icon" />
            </div>
        </div>
    </transition>
</template>

<script>
    /* eslint vue/no-mutating-props: off */
    export default {
        inject: ["top"],
        methods: {
            close() {
                this.top.notification = null;
            }
        },
        computed: {
            data() {
                if (!this.top.notification)
                    return null;
                else if (this.top.notification instanceof Object)
                    return this.top.notification;
                else
                    return { title: this.top.notification };
            },
            style() {
                if (this.data)
                    return ["message", `message__status-${this.data.status || 'neutral'}`];
                
                return ["message"];
            }
        }
    };
</script>

<style src="@/assets/scss/views/Notification.scss" lang="scss"></style>
