import { reactive } from "vue";

const width = () => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const height = () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

const viewport = reactive({
    width: width(),
    height: height(),
})

window.addEventListener("resize", () => {
    viewport.width = width();
    viewport.height = height();
});

export default {
    vp: viewport,
};