<template>
    <teleport to="#overlay">            
        <div class="overlay__modal">
            <div class="modal">
                <div class="modal__title">
                    <p>{{ address.id ? "Adresse editieren" : "Adresse hinzufügen" }}</p>
                    <div class="modal__close" @click="close">
                        <img v-svg-inline src="@/assets/img/x.svg" />
                    </div>
                </div>
                <form @submit.prevent="save">
                    <div class="address-form">
                        <div class="address-form__grid">
                            <div class="input">
                                <span class="input__label">Vorname</span>
                                <input v-model="address.firstName" class="input__field" required />
                            </div>
                            <div class="input">
                                <span class="input__label">Nachname</span>
                                <input v-model="address.lastName" class="input__field" required />
                            </div>
                        </div>
                        <div class="input">
                            <span class="input__label">Firma</span>
                            <input v-model="address.company" class="input__field" />
                        </div>
                        <div class="input">
                            <span class="input__label">Strasse &amp; Nr.</span>
                            <input v-model="address.address1" class="input__field" required />
                        </div>
                        <div class="input">
                            <span class="input__label">Adresszeile 2</span>
                            <input v-model="address.address2" class="input__field" />
                        </div>
                        <div class="address-form__grid">
                            <div class="input">
                                <span class="input__label">Stadt</span>
                                <input v-model="address.city" class="input__field" required />
                            </div>
                            <div class="input">
                                <span class="input__label">Region</span>
                                <input v-model="address.province" class="input__field" />
                            </div>
                        </div>
                        <div class="address-form__grid">
                            <div class="input">
                                <span class="input__label">Postleitzahl</span>
                                <input v-model="address.zip" class="input__field" required />
                            </div>
                            <div class="input">
                                <span class="input__label">Land</span>
                                <Dropdown v-model="address.country" :options="['Switzerland', 'Liechtenstein']" />
                            </div>
                        </div>
                        <div class="address-form__grid">
                            <div class="input">
                                <span class="input__label">Telefon</span>
                                <Phone v-model="address.phone" custom-class="input__field" required />
                            </div>
                        </div>

                        <button type="submit">
                            {{ saving ? "Lädt..." : (this.address.id ? "Speichern" : "Hinzufügen") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        props: ["data", "noPhone"],
        emits: ["change"],
        inject: ["user"],
        data: () => ({
            address: {
                id: null,
                firstName: null,
                lastName: null,
                company: null,
                address1: null,
                address2: null,
                city: null,
                zip: null,
                province: null,
                country: "Switzerland",
                phone: null,
            },
            billing: false,
            copyFromBilling: false,
            saving: false,
        }),
        mounted() {
            this.copyData();
        },
        methods: {
            copyData() {
                this.address = {
                    ...this.address,
                    ...this.data,
                };
            },
            close() {
                this.$emit("change", null);
            },
            async save() {
                if (this.phoneError)
                    return;

                this.saving = true;

                const data = {
                    id: this.address.id,
                    firstName: this.address.firstName,
                    lastName: this.address.lastName,
                    address1: this.address.address1,
                    address2: this.address.address2,
                    city: this.address.city,
                    zip: this.address.zip,
                    country: this.address.country,
                    province: this.address.province,
                    phone: this.address.phone,
                    company: this.address.company
                }

                if (this.billing) {
                    data.email = this.address.email;
                }

                await this.user.updateAddress(data);

                this.saving = false;
                this.$emit("change", data);
            }
        }
    };
</script>
