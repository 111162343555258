<template>
    <div class="checkout-body">
        <section class="builder-header layout-row">
            <div class="konf-logo">
                <router-link to="/">
                    <img src="@/assets/img/motolino-logo-stroke.svg" />
                </router-link>
            </div>
            <div :class="['dark-layout', { show: cartOpen }]" @click="cartOpen = false"></div>
            <div class="total-header" @click="cartOpen = true">
                <img src="@/assets/img/checkout/check.svg" alt=""/>
                <div class="value">{{ price(subtotal) }}</div>
            </div>
        </section>
        <Steps :step="step" :mode="state.status.mode" v-if="!state.status.completed" />
        <div class="checkout-body__inner">
            <div class="checkout-body__left" v-if="loading">
                <Spinner />
            </div>
            <div class="checkout-body__left" v-if="!loading">
                <Start v-if="step == 'start'" />
                <Shipping v-if="step == 'shipping'" />
                <Warranty v-if="step == 'warranty'" />
                <Insurance v-if="step == 'insurance'" />
                <Delivery v-if="step == 'delivery'" />
                <Payment v-if="step == 'payment'" />
                <Review v-if="step == 'review'" />
            </div>
            <Cart :open="cartOpen" @close="cartOpen = false" />
        </div>
    </div>
</template>

<script>
    import Steps from "@/components/Checkout/Steps";
    import Start from "@/components/Checkout/Start";
    import Shipping from "@/components/Checkout/Shipping";
    import Insurance from "@/components/Checkout/Insurance";
    import Warranty from "@/components/Checkout/Warranty";
    import Delivery from "@/components/Checkout/Delivery";
    import Payment from "@/components/Checkout/Payment";
    import Review from "@/components/Checkout/Review";
    import Cart from "@/components/Checkout/Cart";
    import State from "@/state/Checkout";
    import { price } from "@/services/Formatters";

    export default {
        components: { Steps, Shipping, Start, Cart, Insurance, Delivery, Warranty, Payment, Review },
        inject: ["user", "cart"],
        data: () => ({
            loading: true,
            state: State,
            cartOpen: false,
        }),
        provide() {
            return this.state;
        },
        mounted() {
            this.init();
        },
        unmounted() {
            if (this.state.status.completed)
                this.state.reset();
        },
        methods: {
            price,
            async init() {
                this.loading = true;

                if (this.user.authed.value)
                    await this.state.init();
                
                this.loading = false;
            }
        },
        computed: {
            step() {
                return this.$route.params.step || (this.user.authed.value ? "shipping" : "start");
            },
            subtotal() {
                const items = this.state.status.completed ? this.state.order : this.cart.items;

                return items.reduce((a, b) => a + b.price * b.qty, 0);
            },
        },
        watch: {
            "$route.params.step"() {
                window.scrollTo(0, 0);
            }
        }
    };
</script>


<style src="@/assets/scss/views/Checkout.scss" lang="scss"></style>