<template>
    <div>
        <div class="step-counter">
            <span>1</span>
            {{ title }}
        </div>
        <div class="tabs">
            <div class="tabs-header">
                <a
                    href=""
                    :class="{ active: type == 'login' }"
                    @click.prevent="type = 'login'"
                    >Einloggen</a
                >
                <a
                    href=""
                    :class="{ active: type == 'register' }"
                    @click.prevent="type = 'register'"
                    >Registrieren</a
                >
                <hr />
            </div>
        </div>
        <div class="checkout-form">
            <div class="tabs-body">
                <form @submit.prevent="next" v-if="type == 'login'">
                    <div class="mt-40 input-wrapper input-single-row">
                        <span>Ihre E-Mail Adresse</span>
                        <input
                            placeholder="E-Mail"
                            type="text"
                            v-model="email"
                            autofocus
                            required
                            :class="[
                                'input__field',
                                { 'input__field--error': errors.login }
                            ]"
                        />
                        <span class="input__error" v-if="errors.login">{{
                            errors.login
                        }}</span>
                    </div>
                    <div class="input-wrapper input-single-row">
                        <span>Ihr Passwort</span>
                        <input
                            placeholder="Passwort"
                            type="password"
                            v-model="password"
                            required
                        />
                    </div>
                    <div class="buttons__wrapper">
                        <button class="button-green" :disabled="loading">
                            <div class="hover-arr">
                                <img
                                    src="@/assets/img/arrow_right_w.svg"
                                    alt=""
                                />
                            </div>
                            Einloggen
                        </button>
                    </div>
                    <div @click="type = 'reset'" class="row-link">
                        Passwort vergessen
                    </div>
                </form>

                <form @submit.prevent="next" v-if="type == 'register'">
                    <div class="mt-40 input-wrapper input-single-row">
                        <input
                            placeholder="E-Mail"
                            type="text"
                            v-model="email"
                            autofocus
                            required
                            :class="[
                                'input__field',
                                { 'input__field--error': errors.register }
                            ]"
                        />
                        <span class="input__error" v-if="errors.register">{{
                            errors.register
                        }}</span>
                    </div>
                    <div class="input-wrapper input-single-row">
                        <span>Ihr Passwort</span>
                        <input
                            placeholder="Passwort"
                            type="password"
                            minlength="6"
                            v-model="password"
                            required
                        />
                    </div>
                    <div class="input-wrapper input-single-row">
                        <span>Passwort wierderholen</span>
                        <input
                            placeholder="Passwort"
                            type="password"
                            minlength="6"
                            v-model="password2"
                            required
                        />
                    </div>
                    <div class="buttons__wrapper">
                        <button class="button-green" :disabled="loading">
                            <div class="hover-arr">
                                <img
                                    src="@/assets/img/arrow_right_w.svg"
                                    alt=""
                                />
                            </div>
                            Registrieren
                        </button>
                    </div>
                </form>

                <form @submit.prevent="next" v-if="type == 'reset'">
                    <div class="mt-40 input-wrapper input-single-row">
                        <span>Ihre E-Mail Adresse</span>
                        <input
                            placeholder="E-Mail"
                            type="text"
                            v-model="email"
                            autofocus
                            required
                            :class="[
                                'input__field',
                                { 'input__field--error': errors.reset }
                            ]"
                        />
                        <span class="input__error" v-if="errors.reset">{{
                            errors.reset
                        }}</span>
                    </div>
                    <div class="buttons__wrapper">
                        <button class="button-green" :disabled="loading">
                            <div class="hover-arr">
                                <img
                                    src="@/assets/img/arrow_right_w.svg"
                                    alt=""
                                />
                            </div>
                            Passwort zurücksetzen
                        </button>
                    </div>
                    <div @click="type = 'login'" class="row-link">
                        Zurück zum vorherigen Schritt
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import State from "@/state/Checkout";

export default {
    data: () => ({
        type: "login",
        email: null,
        password: null,
        password2: null,
        recovered: false,
        loading: false,
        errors: {
            login: null,
            register: null,
            reset: null
        }
    }),
    inject: ["user", "top"],
    methods: {
        async next() {
            this.loading = true;

            switch (this.type) {
                case "register":
                    {
                        const result = await this.user.register(
                            this.email,
                            this.password
                        );

                        if (result.success) await State.init();
                        else this.errors.register = result.message;
                    }
                    break;
                case "login":
                    {
                        const result = await this.user.login(
                            this.email,
                            this.password
                        );

                        if (result.success) await State.init();
                        else this.errors.login = result.message;
                    }
                    break;
                case "reset": {
                    const result = await this.user.recover(this.email);

                    if (result.success) {
                        this.top.notification = {
                            title: "Wiederherstellungs-Link versendet",
                            content:
                                "Soeben haben wir dir eine E-Mail mit der Anleitung wie du dein Passwort neu setzen kannst an deine E-Mail Adresse gesendet."
                        };
                        this.type = "login";
                    } else this.errors.reset = result.error;
                }
            }

            this.loading = false;
        },
        prev() {
            this.type = null;
        },
        async recover() {
            this.recovered = true;
            this.top.notification = "Check your email!";

            await this.user.recover(this.email);
        }
    },
    computed: {
        title() {
            if (!this.type) return "Identifizieren";

            return this.type == "login" ? "Einloggen" : "Registrieren";
        }
    }
};
</script>
