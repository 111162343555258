import { reactive, watch } from "vue";

const createFilter = products => {
    const filterMap = {};
    const filter = {
        price: {
            min: 999999,
            max: 0,
        },
        options: []
    };

    for (let p of products) {
        filter.price.min = p.variants.map(v => v.price).reduce((a, b) => Math.min(a, b), filter.price.min);
        filter.price.max = p.variants.map(v => v.price).reduce((a, b) => Math.max(a, b), filter.price.max);

        for (let o of p.options) {
            let option = filterMap[o.name];

            if (!option) {
                option = filterMap[o.name] = {
                    name: o.name,
                    values: []
                };
                filter.options.push(option);
            }

            option.values = [...new Set(option.values.concat(o.values))];
        }
    }

    filter.options = filter.options.filter(opt => opt.values.length > 1);

    return filter;
};

const updateCurrent = view => {
    view.current = view.products[0].variant;
};

const addWatchers = view => {
    watch(() => view.options, () => {
        updateCurrent(view);
    });
};

const addFunctions = view => {
    view.search = () => view.products.map(p => match(view, p)).filter(v => v);
    view.select = id => {
        for (let p of view.products) {
            const variant = match(view, p, id);

            if (variant) {
                if (id) {
                    view.filter.options.forEach((opt, i) => {
                        view.options[i] = variant.selectedOptionsMap[opt.name];
                    });
                }

                return variant;
            }
                
        }

        return null;
    };
};

const match = (view, product, id) => {
    const hasOptions = v => view.options.every((val, i) => {
        const name = view.filter.options[i].name;

        return (!val || v.selectedOptionsMap[name] == val);
    });

    if (id)
        return product.variants.find(v => v.id === id);
        
    for (let v of product.variants) {
        if (v.price < view.price[0] || v.price > view.price[1])
            continue;
        else if (hasOptions(v))
            return v;
    }
    
    return null;
};

export default products => {
    products = Array.isArray(products) ? products : [products];

    const filter = createFilter(products);
    const options = filter.options.map(() => null);
    const view = reactive({
        products,
        filter,
        options,
        current: null,
        price: [filter.price.min, filter.price.max],
    });

    addFunctions(view);
    addWatchers(view);

    return view;
};