<template>
    <input type="text" v-model="value" ref="input" placeholder="+41 789 123 456" :class="customClass" :required="required" />
</template>

<script>
    import { validPhone } from "@/services/Formatters";

    export default {
        props: ["modelValue", "customClass", "required"],
        emits: ["update:modelValue"],
        data: () => ({
            value: null,
        }),
        mounted() {
            this.value = this.modelValue;
            this.validate();
        },
        methods: {
            validate() {
                if (this.$refs.input) {
                    const valid = validPhone(this.value);
                    let msg = valid ? "" : "Bitte gib deine Telefonnummer im Format +41 79 123 45 67 an";

                    if (!this.required && !this.value)
                        msg = "";

                    this.$refs.input.setCustomValidity(msg);
                }
            }
        },
        watch: {
            modelValue(newValue) {
                this.value = newValue;
                this.validate();
            },
            value(newValue) {
                this.$emit("update:modelValue", newValue);
                this.validate();
            }
        }
    }
</script>
