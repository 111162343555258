import { createApp } from 'vue'
import App from '@/App.vue'
import Router from "@/routing/Router";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import ConfiguratorState from "@/state/Configurator";
import CartState from "@/state/Cart";
import UserState from "@/state/User";
import TopState  from "@/state/Top";
import AppState  from "@/state/App";
import Spinner from "@/components/Common/Spinner";
import Dropdown from "@/components/Common/Dropdown";
import Listdown from "@/components/Common/Listdown";
import Slider from "@/components/Common/Slider";
import Carousel from "@/components/Common/Carousel";
import Datepicker from "@/components/Common/Datepicker";
import Phone from "@/components/Common/Phone";
import Dialog from "@/components/Common/Dialog";
import Dialog2 from "@/components/Common/Dialog2";
import Dialog3 from "@/components/Common/Dialog3";
import Spinner2 from "@/components/Common/Spinner2";
import { createGtm } from '@gtm-support/vue-gtm';

const app = createApp(App);

app.provide("cart", CartState);
app.provide("rator", ConfiguratorState);
app.provide("user", UserState);
app.provide("top", TopState);
app.provide("app", AppState);

app.component("Spinner", Spinner);
app.component("Slider", Slider);
app.component("Dropdown", Dropdown);
app.component("Listdown", Listdown);
app.component("Carousel", Carousel);
app.component("Datepicker", Datepicker);
app.component("Phone", Phone);
app.component("Dialog", Dialog);
app.component("Dialog2", Dialog2);
app.component("Dialog3", Dialog3);
app.component("Spinner2", Spinner2);

app.use(Router);
app.use(VueSvgInlinePlugin);

const gtmId = process.env.VUE_APP_GTM_ID;

app.use(
    createGtm({
        id: gtmId,
        defer: false,
        compatibility: false,
        nonce: '2726c7f26c',
        enabled: true,
        debug: true,
        loadScript: true,
        vueRouter: Router,
        ignoredViews: [],
        trackOnNextTick: false
    })
);


app.mount("#app");
