<template>
    <transition name="m-a-right" :duration="{enter: 1100, leave: 300}" @after-leave="clear">
        <div :class="style" id="overlay" v-show="top.overlay" @close="close"
            @mousedown="overlay($event)" @touchstart="overlay($event)">    
        </div>
    </transition>
</template>

<script>
    /* eslint vue/no-mutating-props: off */
    export default {
        inject: ["top"],
        data: () => ({
            type: null,
        }),
        methods: {
            overlay(e) {
                if (e.target.id == "overlay")
                    this.close();
            },
            close() {
                this.top.overlay = null;
            },
            clear() {
                this.type = null;
            }
        },
        computed: {
            style() {
                const style = ["overlay"];

                if (this.type)
                    style.push(`overlay--${this.type}`);

                return style;
            },
        },
        watch: {
            "top.overlay": function (value) {
                const style = value ? "position:fixed;overflow:hidden" : "";

                document.body.setAttribute("style", style);

                this.type = value ? value : this.type;
            },
        }
    };
</script>

<style src="@/assets/scss/views/Overlay.scss" lang="scss"></style>
