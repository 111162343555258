import { reactive, readonly } from "vue";

export default variant => {
    const state = reactive({
        variant: variant,
        options: []
    });

    const selected = (name, value) => {
        return variant.selectedOptions.some(
            opt => opt.name == name && opt.value == value
        );
    };

    state.options = variant.product.options.map(opt => ({
        name: opt.name,
        values: opt.values.map(v => ({
            name: opt.name,
            text: v,
            selected: selected(opt.name, v)
        }))
    }));

    const select = opt => {
        let variants = state.variant.product.variants;

        state.options.forEach(g => {
            let value = g.values.find(v => v.selected);

            if (g.name == opt.name) {
                if (value) value.selected = false;

                value = g.values.find(v => v.text == opt.text);
                value.selected = true;
            }

            variants = variants.filter(v => {
                var opt2 = v.selectedOptions.find(o => o.name == g.name);

                return opt2.value == value.text;
            });
        });

        state.variant = variants[0];
    };

    return reactive({
        select,
        state: readonly(state)
    });
};
