<template>
    <div>
        <h1>Meine Bestellungen</h1>
        <div class="table-order" v-if="!empty">
            <div class="table-order__row header-row">
                <div>Bestellnummer</div>
                <div class="desktop">Produktanzahl</div>
                <div class="desktop">Bestelldatum</div>
                <div class="desktop">Bestellsumme</div>
                <div class="status">Status</div>
            </div>
            <div class="table-order__row" v-for="o in orders" :key="o.id" @click="goto(o)">
                <div style="color: #72ad77">#{{ o.orderNumber }}</div>
                <div class="desktop">{{ o.lineItems.reduce((a, b) => a + b.quantity, 0) }}</div>
                <div class="desktop">{{ date(o.processedAt) }}</div>
                <div class="desktop">{{ price(o.totalPriceV2.amount) }}</div>
                <div class="status unfulfilled">{{ fulfillment(o.fulfillmentStatus) }}</div>
            </div>
        </div>
        <div class="no-orders" v-if="empty">
            <p>Du hast bisher noch nichts bestellt</p>
            <router-link to="/">
                <button>Zur Startseite</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { price, date, fulfillment } from "@/services/Formatters";

    export default {
        props: ["data"],
        data: () => ({
            orders: [],
        }),
        mounted() {
            this.load();
        },
        methods: {
            price,
            date,
            fulfillment,
            async load() {
                this.orders = this.data.orders;
            },
            goto(o) {
                this.$router.push(`/account/orders/${o.orderNumber}`);
            },
        },
        computed: {
            empty() {
                return (this.orders.length == 0);
            },
        }
    };
</script>
