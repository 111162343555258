import { ref } from "vue";
import { api } from "@/services/Query";
import { stored } from "@/state/Local";
import config from "@/services/Config";
import pbkdf2 from "pbkdf2";

const state = {
    authed: ref(false),
    sidebar: ref(false),
    expiresAt: null,
    auth: stored("user", {
        id: null,
        token: null,
        expiresAt: null,
        email: null,
    })
};

const init = () => {
    if (!state.auth.expiresAt || state.auth.expiresAt < new Date())
        state.auth.token = state.auth.expiresAt = state.auth.email = null;
    else
        state.authed.value = true;
};

const status = errors => {
    if (errors && errors.length > 0)
        return { success: false, code: errors[0].code, message: errors[0].message };

    return { success: true };
};

const changeAccessToken = data => {
    if (state.authed.value && data?.accessToken && data?.expiresAt) {
        state.auth.token = data.accessToken;
        state.auth.expiresAt = new Date(data.expiresAt);
    }
};

const tryQL = async promise => {
    try {
        return await promise;
    }
    catch {
        return {
            customerUserErrors: [
                { code: "GQL_ERROR", message: "Unable to process your request. Please try again later." }
            ]
        }
    }
};

const login = async (email, password) => {
    const result = await tryQL(api.login(email, password));

    if (result.customerAccessToken) {
        state.authed.value = true;

        state.auth.email = email;

        changeAccessToken(result.customerAccessToken);
    }

    return status(result.customerUserErrors);
};

const loginWithChange = async (email, password) => {
    let result = await login(email, password);

    if (!result.success) {
        result = await login(email, await hash(password));

        if (result.success)
            await changePassword(password);
    }

    return result;
};

const register = async (email, password) => {
    let result = await tryQL(api.register(email, password));

    if (result.customer)
        return await login(email, password);

    return status(result.customerUserErrors);
};

const changePassword = async newPassword => {
    const result = await tryQL(api.updateCustomer(state.auth.token, { password: newPassword }));
    const data = result.customerAccessToken;

    if (data)
        changeAccessToken(data);

    return status(result);
};

const resetPassword = async (id, data) => {
    const result = await tryQL(api.resetPassword(id, data));

    if (result.customerAccessToken) {
        state.authed.value = true;
        state.auth.email = result.customer.email;

        changeAccessToken(result.customerAccessToken);
    }

    return status(result?.customerUserErrors);
};

const logout = () => {
    state.authed.value = false;
    state.auth.email = null;
    state.auth.token = null;
    state.auth.expiresAt = null;
};

const hash = async text => {
    const salt = config.pbkdf2.salt;
    const iter = config.pbkdf2.iterations;
    const len = 32;

    return new Promise((resolve, reject) => {
        pbkdf2.pbkdf2(text, salt, iter, len, 'sha256', function (err, key) {
            if (err)
                reject(err);
            else
                resolve(key.toString("base64").substr(0, 40));
        });
    });
};

const recover = email => {
    const result = api.recoverPassword(email);

    return status(result.customerUserErrors);
}

const account = async () => {
    const data = await api.account(state.auth.token);

    return data;
};

const checkout = () => api.checkout(state.auth.token);

const setDefaultAddress = addressId => api.address.setDefault(state.auth.token, addressId);

const updateProfile = async (firstName, lastName, phone) => {
    await api.updateCustomer(state.auth.token, {
        firstName, lastName, phone
    });
};

const removeAddress = id => api.address.remove(state.auth.token, id);
const updateAddress = async data => {
    const oldId = data.id;

    delete data["id"];

    const result = await api.address.createOrUpdate(state.auth.token, oldId, data);

    data.id = result.customer ? result.customer.id : oldId;

    return result;
};

const exists = email => api.emailExists(email);
const createOrder = data => api.createOrder(data);
const getWarrantyState = data => api.getWarrantyState(data);
const activateWarranty = data => api.activateWarranty(data);

export default {
    authed: state.authed,
    sidebar: state.sidebar,
    auth: state.auth,
    email: state.email,
    login: loginWithChange,
    logout,
    register,
    init,
    account,
    updateProfile,
    updateAddress,
    removeAddress,
    setDefaultAddress,
    changePassword,
    checkout,
    recover,
    exists,
    createOrder,
    resetPassword,
    changeAccessToken,
    getWarrantyState,
    activateWarranty
};
