
<template>
    <div :class="['dropdown', { open: open }]" @click="toggle">
        <div class="dropdown-title">
            {{ data.name }}
        </div>
        <div class="dropdown-content" v-if="open">
            <div :class="['dropdown-item layout-row', { selected: v.selected }]" v-for="(v, i) in data.values" :key="i" @click.stop="pick(v)">
                <div :class="{'dropdown-dot': !multi, 'dropdown-square': multi}">
                    <div>
                        <div>
                            <img v-svg-inline src="@/assets/img/icons/check-white.svg"/>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <span>{{ v.subtitle || v.title }}</span>
                </div>
                <!-- <div class="Tooltip">
                    <div class="information"><span class="question-mark">?</span></div>
                </div> -->
            <div class="dropdown-price" v-if="v.price">+ CHF {{ v.price }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["data", "required", "multi"],
        emits: ["pick"],
        data: () => ({
            open: false
        }),
        methods: {
            pick(value) {
                this.$emit("pick", value);
            },
            toggle() {
                this.open = !this.open;
            }
        }
    };
</script>
