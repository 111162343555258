<template>
    <div>
        <h2 class="mt-40 mb-40">
            Bitte wählen Sie oben zwischen Barzahlung oder Leasing. <br />
            Nachfolgend die Zahlarten bei Barzahlung.
        </h2>
        <div>
            <div class="toggle-card">
                <div
                    :class="[
                        'toggle-card__header',
                        { active: payment.method == 'card' }
                    ]"
                    @click="payment.method = 'card'"
                >
                    Kreditkarte
                </div>
                <div class="toggle-card__body" v-if="payment.method == 'card'">
                    <p>Zzgl. 2.9% Kreditkartengebühren</p>
                    <Stripe ref="stripe" @change="cardUpdate" />
                </div>
            </div>
            <div class="toggle-card">
                <div
                    :class="[
                        'toggle-card__header',
                        { active: payment.method == 'invoice' }
                    ]"
                    @click="payment.method = 'invoice'"
                >
                    Vorkasse
                </div>
                <div
                    class="toggle-card__body"
                    v-if="payment.method == 'invoice'"
                >
                    <p>
                        Sie überweisen uns die komplette Summe auf unsere Post-
                        oder Bank- konto und direkt nach Eingang Ihrer Zahlung
                        versenden wir Ihre Bestellung.
                    </p>
                    <p>
                        Sie erhalten die Rechnung und Bankverbindung nach
                        Abschluss Ihrer Bestellung per E-Mail.
                    </p>
                </div>
            </div>
            <div class="toggle-card">
                <div
                    :class="[
                        'toggle-card__header',
                        { active: payment.method == 'store' }
                    ]"
                    @click="payment.method = 'store'"
                >
                    Barzahlung im Laden
                </div>
                <div class="toggle-card__body" v-if="payment.method == 'store'">
                    <p>
                        Sie bezahlen Ihre Vespa bei uns im Laden in Basel oder
                        Zürich.
                    </p>
                    <p>
                        Sie erhalten die Rechnung nach Abschluss Ihrer
                        Bestellung per E-Mail.
                    </p>
                </div>
            </div>
            <div class="buttons__wrapper">
                <button class="button-green" @click="next" :disabled="!hasNext">
                    <div class="hover-arr" v-if="status.ready">
                        <img src="@/assets/img/icons/save-white.svg" alt="" />
                        <img
                            src="@/assets/img/icons/save-green.svg"
                            class="hovered"
                            alt=""
                        />
                    </div>
                    Zum Bestellabschluss
                </button>
            </div>
            <div @click="prev" class="row-link">
                Zurück zum vorherigen Schritt
            </div>
        </div>
    </div>
</template>

<script>
import Stripe from "./_Stripe.vue";
import Gtm from "@/services/GtmHelper";

export default {
    components: { Stripe },
    inject: ["payment", "status"],
    data: () => ({
        type: null,
        loading: false,
        cardValid: false
    }),
    methods: {
        async next() {
            this.loading = true;

            if (this.payment.method == "card") {
                const method = await this.$refs.stripe.create();

                this.payment.methodId = method.id;
                this.payment.last4 = method.card.last4;
                this.payment.expMonth = method.card.exp_month;
                this.payment.expYear = method.card.exp_year;
                this.payment.name = method.card.name;
            }

            this.loading = false;

            Gtm.additionalStep(this.$gtm, 7, "Payment");
            this.$router.push("/checkout/review");
        },
        cardUpdate(e) {
            this.cardValid = e.complete;
        },
        prev() {
            this.$router.push("/checkout/delivery");
        }
    },
    computed: {
        hasNext() {
            if (this.loading) return false;
            else if (this.payment.method == "card" && !this.cardValid)
                return false;

            return true;
        }
    }
};
</script>
