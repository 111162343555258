import { reactive } from "vue";

const resettable = state => {
    const copy = JSON.parse(JSON.stringify(state));
    const live = reactive(state);

    live.reset = () => {
        for (let key of Object.keys(copy))
            live[key] = copy[key];
    };

    return live;
};

export { resettable };