<template>
    <div class="step-counter">
        <span>2</span>
        Adresse
    </div>
    <div class="checkout-form">
        <h2>{{ title }}</h2>

        <div class="row-cards">
            <div class="card card-shipping">
                <div class="card-inner">
                    <div class="card-logo">
                        <img src="@/assets/img/icons/delivery.png" alt="" />
                    </div>
                    <div
                        class="tw--mr-14 card-title tw-min-w-full tw-text-center"
                        v-html="
                            address.shipping ? 'Lieferadresse' : 'Kundenadresse'
                        "
                    ></div>
                    <ul class="card-info" v-if="address.shipping">
                        <li>
                            {{ address.shipping.firstName }}
                            {{ address.shipping.lastName }}
                        </li>
                        <li>{{ address.shipping.address1 }}</li>
                        <li>
                            {{ address.shipping.zip }}
                            {{ address.shipping.city }}
                        </li>
                        <li>{{ address.shipping.phone }}</li>
                    </ul>
                    <a href="" class="card-edit" @click.prevent="openShipping">
                        <img
                            src="@/assets/img/icons/edit.svg"
                            v-if="address.shipping"
                        />
                        <img
                            src="@/assets/img/icons/plus-black.svg"
                            v-if="!address.shipping"
                        />
                    </a>
                </div>
            </div>

            <div v-show="address.shipping" class="card card-shipping">
                <div class="card-inner">
                    <div class="card-logo">
                        <img src="@/assets/img/icons/address.png" alt="" />
                    </div>
                    <div class="card-title">Rechnungsadresse</div>
                    <ul class="card-info" v-if="address.billing">
                        <li>
                            {{ address.billing.firstName }}
                            {{ address.billing.lastName }}
                        </li>
                        <li>{{ address.billing.address1 }}</li>
                        <li>
                            {{ address.billing.zip }} {{ address.billing.city }}
                        </li>
                        <li>{{ address.billing.phone }}</li>
                    </ul>
                    <a href="" class="card-edit" @click.prevent="openBilling">
                        <img
                            src="@/assets/img/icons/edit.svg"
                            v-if="address.billing"
                        />
                        <img
                            src="@/assets/img/icons/plus-black.svg"
                            v-if="!address.billing"
                        />
                    </a>
                </div>
            </div>
        </div>

        <Address
            v-if="editShipping"
            :data="address.shipping"
            @change="addressChange($event, 'shipping')"
        />
        <Address
            v-if="editBilling"
            :data="address.billing"
            @change="addressChange($event, 'billing')"
        />

        <div class="buttons__wrapper" v-if="!status.ready">
            <button class="button-green" @click="next" :disabled="!ready">
                Ja, weiter
            </button>
        </div>
        <div class="buttons__wrapper" v-if="status.ready">
            <button
                class="button-green"
                @click="$router.push('/checkout/review')"
            >
                <div class="hover-arr">
                    <img src="@/assets/img/icons/save-white.svg" alt="" />
                    <img
                        src="@/assets/img/icons/save-green.svg"
                        class="hovered"
                        alt=""
                    />
                </div>
                Zum Bestellabschluss
            </button>
        </div>
    </div>
</template>

<script>
import Address from "@/components/Common/Address";
import Gtm from "@/services/GtmHelper";

export default {
    inject: ["address", "status", "top"],
    components: { Address },
    data: () => ({
        editShipping: false,
        editBilling: false
    }),
    mounted() {},
    methods: {
        openShipping() {
            this.top.overlay = "modal";
            this.editShipping = true;
        },
        openBilling() {
            this.top.overlay = "modal";
            this.editBilling = true;
        },
        addressChange(data, type) {
            if (type == "billing" && data) {
                this.address.billing = data;
                this.address.shipping = this.address.shipping || { ...data };
            } else if (data) {
                this.address.shipping = data;
                this.address.billing = this.address.billing || { ...data };
            }

            this.top.overlay = null;
            this.editShipping = this.editBilling = false;
        },
        next() {
            Gtm.additionalStep(this.$gtm, 2, "Shipping");
            if (this.status.mode == "simple")
                this.$router.push("/checkout/delivery");
            else this.$router.push("/checkout/warranty");
        }
    },
    computed: {
        ready() {
            return this.address.shipping && this.address.billing;
        },
        title() {
            if (!this.ready) return "Wie lautet deine Adresse?";

            return "Sind diese Daten korrekt?";
        }
    }
};
</script>
