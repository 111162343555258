<template>
    <Slider v-model="value" :min="min" :max="max" :tooltips="true" track @change="$emit('change', value)" />
</template>

<script>
    import Slider from '@vueform/slider';

    export default {
        components: { Slider },
        props: ["modelValue", "min", "max"],
        emits: ["change", "update:modelValue"],
        data: () => ({
            index: 0,
        }),
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                }
            }
        }
    }
</script>

<style src="@vueform/slider/themes/default.css"></style>