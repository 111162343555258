<template>
    <div class="checkout-steps__header">
        <Step :active="step == 'start'" text="Start" url="/checkout" />
        <Step :active="step == 'shipping'" text="Ihre Daten" url="/checkout/shipping" />
        <Step :active="step == 'warranty'" text="Schutz &amp; Vorsorge" url="/checkout/warranty" v-if="mode == 'wizard'" />
        <Step :active="step == 'insurance'" text="Versicherung" url="/checkout/insurance" v-if="mode == 'wizard'" />
        <Step :active="step == 'delivery'" text="Lieferung" url="/checkout/delivery" />
        <Step :active="step == 'payment'" text="Zahlart" url="/checkout/payment" />
    </div>
</template>

<script>
    import Step from "./Step.vue";
    
    export default {
        props: ["step", "mode"],
        components: { Step },
    }
</script>
