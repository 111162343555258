import { watch } from "vue";
import { resettable } from "@/services/Reactive";
import user from "./User";
import cart from "./Cart";
import { price, date } from "@/services/Formatters";
import config from "@/services/Config";

import { parse } from "date-format-parse";

const init = async () => {
    if (user.authed.value) {
        const data = await user.checkout();

        initWarranty(data);

        state.address.shipping = data.customer.defaultAddress
            ? { ...data.customer.defaultAddress }
            : null;
        state.address.billing = data.customer.defaultAddress
            ? { ...data.customer.defaultAddress }
            : null;
        state.address.email = data.customer.email;
    }
};

const initWarranty = data => {
    const mapAction = p => {
        switch (p.variants[0].sku) {
            case "40003":
                return "Hightech Keramikversiegelung";
            case "40002":
                return "Hohlraum der Vespa versiegeln";
            case "40001":
                return "Kostenlos 4 Jahre Garantie";
            default:
                return "Select";
        }
    };

    const mapped = data.warranty.products.map(p => ({
        id: p.variants[0].id,
        image: p.images.length > 0 ? p.images[0].transformedSrc : null,
        product: { title: p.title },
        title: p.title,
        subtitle: p.variants[0].title,
        description: p.descriptionHtml,
        price: p.variants[0].priceV2.amount,
        active: false,
        action: mapAction(p)
    }));

    state.warranty.splice(0, state.warranty.length, ...mapped);
};

const locations = config.stores;

const initialState = {
    status: {
        mode: "simple",
        ready: false,
        completed: false
    },
    address: {
        email: null,
        shipping: null,
        billing: null
    },
    warranty: [],
    payment: {
        method: "card",
        methodId: null,
        category: "cash",
        last4: null,
        expYear: null,
        expMonth: null,
        name: null
    },
    insurance: {
        type: null,
        birthday: null,
        nationality: "Schweiz",
        phone: null,
        hasProvider: false,
        provider: null,
        hasAccidents: false,
        accidents: [],
        hasPenalties: false,
        penalties: []
    },
    leasing: {
        acceptedRate: null,
        deposit: 0,
        months: 60,
        residual: 1000,
        birthday: null,
        nationality: "Schweiz",
        residentAtAddressSince: null,
        householdPeopleSupportNumber: null,
        monthlyHousingCost: null,
        phone: null,
        employedSince: null,
        monthlySalary: null,
        job: null,
        housingSituation: null,
        monthlyForCommuting: null,
        employer: null,
        residencePermitUntil: null,
        residencePermit: null,
        images: []
    },
    delivery: {
        type: null,
        pickupId: "basel",
        locations: locations
    },
    order: []
};

const state = resettable({
    ...initialState,
    init
});

const handleMode = () => {
    state.status.mode = cart.items.some(it => it.mode == "vespa")
        ? "wizard"
        : "simple";
};

watch(cart.items, handleMode);
handleMode();

state.createOrderData = () => createOrderData(state);

const createOrderData = checkout => {
    const data = {
        billingAddress: checkout.address.billing,
        email: checkout.address.email,
        //note: createOrderNote(checkout),
        lineItems: cart.items.map(it => ({
            variantId: it.id,
            quantity: it.qty
        })),
        paymentMethod: checkout.payment.method,
        paymentMethodId: checkout.payment.methodId,
        paymentCategory: checkout.payment.category,
        customAttributes: createOrderAttributes(checkout),
        host: location.host
    };

    if (checkout.delivery.type == "pickup") {
        data.shippingLine = {
            price: 0,
            title: `Pickup in ${checkout.delivery.pickupId.replace(/^\w/, c =>
                c.toUpperCase()
            )}`
        };
    } else if (checkout.delivery.type == "ship") {
        data.shippingAddress = checkout.address.shipping;
        data.shippingLine = {
            price: 99,
            title: `Shipping`
        };
    }

    if (checkout.payment.category == "leasing")
        data.images = checkout.leasing.images;

    return data;
};

const createOrderAttributes = checkout => {
    const attrs = [
        ...createPaymentAttributes(checkout),
        ...createInsuranceAttributes(checkout),
        ...createLeasingAttributes(checkout),
        { key: "Host", value: location.host },
    ];

    attrs.forEach(a => {
        a.value = a.value || "";
    });

    return attrs;
};

// const createOrderNote = checkout => {
//     const leasing = createLeasingNote(checkout);
//     const payment = createPaymentNote(checkout);
//     const insurance = createInsuranceNote(checkout);

//     return [insurance, leasing, payment].filter(x => x).join("\r\n\r\n");
// };

const createPaymentAttributes = checkout => {
    const attribute = { key: "Zahlung" };

    if (checkout.payment.category == "leasing") attribute.value = "Leasing";
    else {
        switch (checkout.payment.method) {
            case "card":
                attribute.value = "Kreditkarte";
                break;
            case "invoice":
                attribute.value = "Vorkasse";
                break;
            case "store":
                attribute.value = "Barzahlung in Laden";
                break;
        }
    }

    return [attribute];
};

const createInsuranceAttributes = checkout => {
    if (!checkout.insurance.type) return [];

    const data = checkout.insurance;
    const attributes = [
        { key: "Type", value: data.type == "full" ? "Vollkasko" : "Teilkasko" },
        { key: "Ihr Geburtstatum", value: parse(data.birthday, "DD-MM-YYYY") },
        { key: "Nationalität", value: data.nationality },
        { key: "Telefonnummer", value: data.phone }
    ];

    if (data.hasProvider) {
        attributes.push({
            key:
                "Hatten Sie bereits eine Motorfahrzeugversicherung auf Ihren Namen?",
            value: data.provider
        });
    }

    if (data.hasAccidents) {
        data.accidents.forEach((a, i) => {
            attributes.push({
                key: "Schadensfall " + (i + 1),
                value: `${date(a.date)}: ${a.reason}`
            });
        });
    }

    if (data.hasPenalties) {
        data.penalties.forEach((a, i) => {
            attributes.push({
                key: "Penalty " + (i + 1),
                value: `${date(a.date)}: ${a.reason}`
            });
        });
    }

    return attributes;
};

const createLeasingAttributes = checkout => {
    if (checkout.payment.category != "leasing") return [];

    const data = checkout.leasing;
    return [
        { key: "Laufzeit", value: `${data.months} Monate` },
        { key: "Anzahlung", value: price(data.deposit, 2) },
        { key: "Restwert", value: price(data.residual, 2) },
        { key: "Geburtsdatum", value: date(data.birthday) },
        {
            key: "Wohnhaft an der angegeben Adresse seit",
            value: date(data.residentAtAddressSince)
        },
        { key: "Telefonnummer", value: data.phone },
        { key: "Zivilstand", value: data.maritalStatus },
        { key: "Nationalität", value: data.nationality },
        { key: "Aufenthaltsgenehmigung", value: data.residencePermit },
        {
            key: "Aufenthaltsgenehmigung gültig bis",
            value: date(data.residencePermitUntil)
        },
        { key: "Wohnverhältnis", value: data.housingSituation },
        { key: "Beruf", value: data.job },
        { key: "Arbeitgeber", value: data.employer },
        { key: "Angestellter seit", value: date(data.employedSince) },
        { key: "monatliches Salär (brutto)", value: data.monthlySalary },
        {
            key: "monatliches Wohnkosten (inkl. NK)",
            value: data.monthlyHousingCost
        },
        {
            key: "Anzahl unterstützungsbedürftige Personen im Haushalt",
            value: data.householdPeopleSupportNumber
        },
        {
            key: "Feste Auslagen/Monat für Arbeitsweg (mind. CHF 250)",
            value: data.monthlyForCommuting
        }
    ];
};

export default state;
