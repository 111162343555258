const runCounterFrame = () => {
    const counter = document.getElementById("counter");
    let start = 0;
    let frame;

    function runCounter() {
        if (counter && start <= 96.1) {
            const mathRand = 3.1;
            start += mathRand;
            counter.innerHTML = `${Math.ceil(start)}`;
            frame = window.requestAnimationFrame(runCounter);
        } else {
            cancelAnimationFrame(frame);
            document.body.classList.add("end");
            setTimeout(() => {
                document.body.className = "";
            }, 3000);
        }
    }
    frame = window.requestAnimationFrame(runCounter);
};

export default () => {
    document.onreadystatechange = () => {
        if (document.readyState === "interactive") {
            document.body.classList.add("ready");
        }
    };

    window.addEventListener("load", () => {
        setTimeout(() => {
            document.body.classList.add("start");
            runCounterFrame();
        }, 300);
    });
};
