<template>
    <Loader />
    <TopMenu v-if="!$route.meta.hideTopMenu" />
    <router-view />
    <Footer v-if="!$route.meta.hideFooter" />
    <Overlay />
    <Notification />
    <NewPassword v-if="$route.query.reset && $route.query.token" />
</template>

<script>
    import Loader from '@/components/Loader.vue'
    import TopMenu from '@/components/TopMenu.vue'
    import Footer from '@/components/Footer.vue'
    import Overlay from "@/components/Overlay.vue";
    import Notification from "@/components/Notification.vue";
    import NewPassword from "@/components/Overlays/NewPassword.vue";

    import userState from "@/state/User";
    import topState from "@/state/Top";
    import analytics from "@/services/Analytics";
    
    export default {
        components: {
            Loader, TopMenu, Footer, Overlay, Notification, NewPassword
        },
        created() {
            userState.init();
            analytics.init();
        },
        watch: {
            $route() {
                topState.overlay = null;
                topState.mobileMenu = false;
                topState.userMenu = false;

                analytics.page(this.$route.fullPath);
            }
        }
    }
</script>

<style lang="scss">
    @import "@/assets/scss/index.scss";
    @import "@/assets/scss/views/Input.scss";
</style>