<template>
    <teleport to="#overlay">
        <div class="overlay__right">
            <div class="password-reset">
                <div class="overlay__header">
                    <div class="overlay__header-text">Passwort zurücksetzen</div>
                    <span @click="close" class="overlay__header__close-icon" >
                        <img v-svg-inline src="@/assets/img/x.svg"/>
                    </span>
                </div>
                <div class="password-reset__content">
                    <transition-group name="appear">
                        <div class="password-reset-form">
                            <p class="md-text">
                                Bitte gib uns deine E-Mail Adresse, damit wir dir einen Link
                                zum Zurücksetzen deines Passworts senden können.
                            </p>
                            <form @submit.prevent="reset">
                                <div class="input">
                                    <span class="input__label">E-Mail Adresse</span>
                                    <input type="email" v-model="email" required :class="['input__field', { 'input__field--error': loginError }]" />
                                    <span class="input__error" v-if="loginError">{{ loginError }}</span>
                                </div>
                                <div class="password-reset-form__button">
                                    <button :disabled="loading">{{ loading ? "Lädt..." : "Passwort zurücksetzen" }}</button>
                                </div>
                            </form>
                        </div>
                    </transition-group>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        inject: ["user", "top"],
        data: () => ({
            loading: false,
            email: null,
            error: null,
        }),
        methods: {
            async reset() {
                this.loading = true;

                const result = await this.user.recover(this.email);

                if (result.success) {
                    this.top.overlay = null;
                    this.top.notification = {
                        title: "Wiederherstellungs-Link versendet",
                        content: "Soeben haben wir dir eine E-Mail mit der Anleitung wie du dein Passwort neu setzen kannst an deine E-Mail Adresse gesendet.",
                    };
                }
                else
                    this.error = result.message;

                this.loading = false;
            },
            close() {
                this.top.overlay = null;
            }
        }
    }
</script>

<style src="@/assets/scss/views/ResetPassword.scss" lang="scss"></style>