<template>
    <div class="my-vespa" v-if="!selected">
        <h1>Mine Vespas</h1>
        <div class="my-all-vespas__grid" v-if="vespas.length > 0">
            <div class="my-all-vespas__grid-item" v-for="(v, i) in vespas" :key="i">
                <div class="my-all-vespas__grid-item__image" @click="goto(v)">
                    <div class="image">
                        <div class="image-inner">
                            <img :src="v.image" :alt="v.title" />
                        </div>
                    </div>
                </div>
                <div class="name">{{ v.title }}</div>
            </div>
        </div>

        <div class="no-myVespa" v-if="vespas.length == 0">
            <p>
                Hier werden alle Daten deiner persönlichen Vespa gezeigt. Deinem Profil ist aber noch keine Vespa zugewiesen.<br/>
                Erstelle deine Vespa in unserem <router-link class="link-decor" to="/configurator">Konfigurator</router-link> oder wende dich an&nbsp;
                <a href="mailto:team@motolino.ch" class="link-decor">team@motolino.ch</a>, falls du bereits eine Vespa bei Motolino gekauft hast um diese hier zu sehen.
            </p>
            <router-link to="/configurator">
                <button>Jetzt Vespa konfigurieren</button>
            </router-link>
        </div>
    </div>

    <MyVespaDetails v-if="selected" :data="selected" @cancel="reset" />
</template>

<script>
    import MyVespaDetails from "./MyVespaDetails";

    export default {
        components: { MyVespaDetails },
        props: ["data"],
        data: () => ({
            selected: null,
        }),
        methods: {
            goto(v) {
                this.selected = v;
                window.scroll(0, 0);
            },
            reset() {
                this.selected = null;
            }
        },
        computed: {
            vespas() {
                const data = [];

                for (let o of this.data.orders) {
                    const lines = o.lineItems.filter(l => l.variant?.product.tags.includes("Bike"));

                    lines.forEach(l => {
                        const date = new Date(Date.parse(o.processedAt));

                        data.push({
                            image: l.variant.image.transformedSrc,
                            title: `${l.variant.product.title} (${l.variant.title})`,
                            date,
                            serviceDate: new Date(date.getTime()).setFullYear(date.getFullYear() + 1),
                            orderId: o.id
                        });
                    });
                }

                return data;
            },
        }
    };
</script>
