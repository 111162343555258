<template>
    <div class="checkout-form mt-30 review-form">
        <div class="checkout-success-top" v-if="status.completed">
            <img src="@/assets/img/checkout/scooter.svg" alt="" />
            <h2 class="success-title">
                Herzlichen Glückwunsch. Wir haben Ihre Bestellung erhalten
            </h2>
            <p class="above-title">
                Sie erhalten eine Bestellbestätigung per E-Mail.
            </p>
        </div>
        <h2>
            {{ status.completed ? "Deine Bestellung" : "Bestellübersicht" }}
        </h2>
        <div>
            <div class="mt-40 row-cards">
                <div class="card card-select card-review">
                    <div class="card-inner">
                        <h4>Lieferadresse</h4>
                        <ul class="card-info">
                            <li>
                                {{ address.shipping.firstName }}
                                {{ address.shipping.lastName }}
                            </li>
                            <li>{{ address.shipping.address1 }}</li>
                            <li v-if="address.shipping.address2">
                                {{ address.shipping.address2 }}
                            </li>
                            <li>
                                {{ address.shipping.zip }}
                                {{ address.shipping.city }}
                            </li>
                            <li>{{ address.shipping.country }}</li>
                        </ul>
                        <router-link
                            to="/checkout/shipping"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                    </div>
                </div>
                <div class="card card-select card-review">
                    <div class="card-inner">
                        <h4>Rechnungsadresse</h4>
                        <ul class="card-info">
                            <li>
                                {{ address.billing.firstName }}
                                {{ address.billing.lastName }}
                            </li>
                            <li>{{ address.billing.address1 }}</li>
                            <li v-if="address.billing.address2">
                                {{ address.billing.address2 }}
                            </li>
                            <li>
                                {{ address.billing.zip }}
                                {{ address.billing.city }}
                            </li>
                            <li>{{ address.billing.country }}</li>
                        </ul>
                        <router-link
                            to="/checkout/shipping"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                    </div>
                </div>
            </div>
            <div class="row-cards row-single" v-if="insurance.type">
                <div class="card card-select card-review">
                    <div class="card-inner">
                        <h4>Versicherung</h4>
                        <ul class="card-info">
                            <li>Inkl. Versicherungsantrag</li>
                            <li>
                                Sie profiteren von Schnelleinlösung und
                                Schilderabholung &amp; 24h schweiz-weitem
                                Abholdienst.
                            </li>
                            <li>
                                Verkehrsrechtsschutz Versicherung mit 60.-
                                Cashback
                            </li>
                        </ul>
                        <router-link
                            to="/checkout/insurance"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                    </div>
                </div>
            </div>
            <div class="row-cards row-single">
                <div class="card card-select card-review">
                    <div class="card-inner">
                        <h4>Versandart</h4>
                        <ul class="card-info" v-if="delivery.type == 'pickup'">
                            <li v-if="!status.completed">In-Store Pickup</li>
                        </ul>
                        <div class="status-result" v-if="status.completed">
                            Sobald Ihre Bestellung abholbereit ist, erhalten Sie
                            von uns eine E-Mail
                        </div>
                        <div class="card-columns" v-if="!status.completed">
                            <div>
                                <div>
                                    <div class="label">Standort</div>
                                    <p>{{ loc.city }}</p>
                                </div>
                                <div class="layout-column"></div>
                            </div>
                            <div>
                                <div>
                                    <div class="label">
                                        Voraussichtlich abholbereit
                                    </div>
                                    <p>Lieferzeit: 15 Arbeitstage</p>
                                </div>
                                <div class="layout-column"></div>
                            </div>
                        </div>
                        <router-link
                            to="/checkout/delivery"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                    </div>
                </div>
            </div>
            <div class="row-cards row-single">
                <div
                    class="card card-select card-review"
                    v-if="
                        payment.category == 'cash' && payment.method == 'card'
                    "
                >
                    <div class="card-inner">
                        <h4>Zahlungsart</h4>
                        <ul class="card-info">
                            <li>Kreditkarte</li>
                        </ul>
                        <div class="card-columns">
                            <div>
                                <div class="label">Kreditkartennummer</div>
                                <p>XXXX XXXX XXXX {{ payment.last4 }}</p>
                            </div>
                            <div>
                                <div class="label">Karteninhaber</div>
                                <p>{{ payment.name }}</p>
                            </div>
                            <div>
                                <div class="label">Ablaufdatum</div>
                                <p>
                                    {{ payment.expMonth }}/{{ payment.expYear }}
                                </p>
                            </div>
                        </div>
                        <router-link
                            to="/checkout/payment"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                    </div>
                </div>
                <div
                    class="card card-select card-review"
                    v-if="
                        payment.category == 'cash' && payment.method == 'store'
                    "
                >
                    <div class="card-inner">
                        <h4>Zahlungsart</h4>
                        <ul class="card-info">
                            <li>Barzahlung im Laden</li>
                        </ul>
                        <router-link
                            to="/checkout/payment"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                    </div>
                </div>
                <div
                    class="card card-select card-review"
                    v-if="
                        payment.category == 'cash' &&
                            payment.method == 'invoice'
                    "
                >
                    <div class="card-inner">
                        <h4>Zahlungsart</h4>
                        <ul class="card-info">
                            <li>Vorkasse</li>
                        </ul>
                        <div class="card-columns">
                            <div class="layout-column">
                                <div>
                                    <div class="label">Bankname</div>
                                    <p>UBS Switzerland AG</p>
                                </div>
                                <div>
                                    <div class="label">IBAN</div>
                                    <p>CH820020620619807601W</p>
                                </div>
                                <div>
                                    <div class="label">BIC (SWIFT)</div>
                                    <p>UBSWCHZH80V</p>
                                </div>
                            </div>
                            <div>
                                <div class="label">Kontoinhaber</div>
                                <p>
                                    MOTOLINO GmbH<br />Schaffhauserstr. 210<br />8057
                                    Zürich
                                </p>
                            </div>
                            <div>
                                <div class="label">Zahlungszweck</div>
                                <p>ML28163</p>
                            </div>
                        </div>
                        <router-link
                            to="/checkout/payment"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                        <div class="status-result" v-if="status.completed">
                            Sie werden von unserer Bank kontaktiert.
                        </div>
                    </div>
                </div>
                <div
                    class="card card-select card-review"
                    v-if="payment.category == 'leasing'"
                >
                    <div class="card-inner">
                        <h4>Zahlungsart</h4>
                        <ul class="card-info">
                            <li>Leasing</li>
                        </ul>
                        <div class="card-columns">
                            <div>
                                <div class="label">Laufzeit</div>
                                <p>{{ leasing.months }} Monate</p>
                            </div>
                            <div>
                                <div class="label">Anzahlung</div>
                                <p>{{ price(leasing.deposit, 2) }}</p>
                            </div>
                            <div>
                                <div class="label">Restwert</div>
                                <p>{{ price(leasing.residual, 2) }}</p>
                            </div>
                            <div>
                                <div class="label">monatl. Rate</div>
                                <p>{{ price(leasing.acceptedRate, 2) }}</p>
                            </div>
                        </div>
                        <router-link
                            to="/checkout/payment"
                            class="card-edit"
                            v-if="!status.completed"
                            ><img
                                src="@/assets/img/icons/edit.svg"
                                alt="edit-icon"
                        /></router-link>
                    </div>
                </div>
            </div>
        </div>
        <p class="mt-40" style="text-align:center" v-if="!status.completed">
            Mit der Betätigung der Bestellung erkläre ich mich mit der<br />
            Datenschutzerkärung und den AGB als einverstanden
        </p>
        <Dialog3
            title="Fehler aufgetreten"
            yes="Erneut versuchen"
            v-model="error"
            @yes="
                () => {
                    next();
                }
            "
        >
            <p>
                Leider ist ein Fehler aufgetretten. Bitte versuchen Sie es
                erneut.
            </p>
        </Dialog3>

        <div class="buttons__wrapper" @click="next" v-if="!status.completed">
            <button class="button-green" :disabled="loading">
                Jetzt Zahlungspflichtig bestellen <Spinner2 v-if="loading" />
            </button>
        </div>

        <div class="buttons__wrapper" v-if="pdf">
            <button class="button-green download-mod" @click="invoice">
                <div>
                    <img v-svg-inline src="@/assets/img/download.svg" />
                </div>
                Bestellbestätigung herunterladen
            </button>
        </div>
        <div class="buttons__wrapper" v-if="status.completed">
            <button
                class="button-green bigger"
                :disabled="loading"
                @click="$router.push('/')"
            >
                zum home
            </button>
            <button
                class="button-green bigger button-green-bordered"
                :disabled="loading"
                v-if="hasVespa"
                @click="$router.push('/account/my-vespa')"
            >
                zu "Meine Vespa"
            </button>
        </div>
        <div @click="prev" class="row-link" v-if="!status.completed">
            Zurück zum vorherigen Schritt
        </div>
    </div>
</template>

<script>
import checkout from "@/state/Checkout";
import { price } from "@/services/Formatters";
import Gtm from "@/services/GtmHelper";

export default {
    inject: [
        "payment",
        "insurance",
        "user",
        "cart",
        "address",
        "leasing",
        "delivery",
        "status",
        "order"
    ],
    data: () => ({
        loading: false,
        pdf: null,
        error: null
    }),
    mounted() {
        checkout.status.ready = true;
    },
    methods: {
        price,
        prev() {
            this.$router.push("/checkout/payment");
        },
        invoice() {
            Gtm.checkoutFinish(this.$gtm, this.order.id)
            window.open(this.pdf);
        },
        async next() {
            this.loading = true;

            const data = checkout.createOrderData();

            let result = {};
            try {
                result = await this.user.createOrder(data);
                Gtm.checkoutFinish(this.$gtm, result.id);
            } catch (err) {
                result.error = err;
            }
            this.error = result.error;
            console.log(result);

            if (!result.error) {
                this.pdf = result.pdfUrl;

                checkout.order.splice(
                    0,
                    checkout.order.length,
                    ...this.cart.items
                );
                checkout.status.completed = true;

                this.cart.empty();

                window.scrollTo(0, 0);
            }

            this.loading = false;
            Gtm.additionalStep(this.$gtm, 8, "Review");
        }
    },
    computed: {
        loc() {
            return this.delivery.locations.find(
                l => l.value == this.delivery.pickupId
            );
        },
        hasVespa() {
            return this.order.some(it => it.mode == "vespa");
        }
    }
};
</script>
