import mapper from "@/services/Mapper";
import { $q, $m } from "./Apollo";

import ProductByHandle from "@/gql/ProductByHandle.gql";
import PageByHandle from "@/gql/PageByHandle.gql";
import SetDefaultAddress from "@/gql/SetDefaultAddress.gql";
import CustomerCreate from "@/gql/CustomerCreate.gql";
import Products from "@/gql/Products.gql";
import ProductDetailsFragment from "@/gql/fragments/ProductDetails.gql";
import AddressDetailsFragment from "@/gql/fragments/AddressDetails.gql";
import ConfiguratorDetailsFragment from "@/gql/fragments/ConfiguratorDetails.gql";
import CustomerAccessTokenCreate from "@/gql/CustomerAccessTokenCreate.gql";
import CustomerUpdate from "@/gql/CustomerUpdate.gql";
import CustomerAddressCreate from "@/gql/CustomerAddressCreate.gql";
import CustomerAddressDelete from "@/gql/CustomerAddressDelete.gql";
import CustomerAddressUpdate from "@/gql/CustomerAddressUpdate.gql";
import CustomerRecover from "@/gql/CustomerRecover.gql";
import CustomerReset from "@/gql/CustomerReset.gql";
import Checkout from "@/gql/Checkout.gql";
import Account from "@/gql/Account.gql";
import CollectionByHandle from "@/gql/CollectionByHandle.gql";
import ProductsByCollection from "@/gql/ProductsByCollection.gql";
import Configurator from "@/gql/Configurator.gql";

const page = async handle => (await $q(PageByHandle, { handle })).pageByHandle;

const emailExists = async email => {
    const data = { email, password: "123" };
    const result = await $m(CustomerCreate, { data });

    return result.customerCreate.customerUserErrors.some(e => e.code == "TAKEN");
};

const setDefaultAddress = async (_, addressId) => await $m(SetDefaultAddress, { addressId });

const product = async handle => {
    const result = await $q(ProductByHandle, { handle }, [ProductDetailsFragment]);

    return mapper.product(result.productByHandle);
};

const products = async filter => {
    const result = await $q(Products, { filter }, [ProductDetailsFragment]);

    return result.products.map(mapper.product);
};

const productsByCollection = async handle => {
    const result = await $q(ProductsByCollection, { handle }, [ProductDetailsFragment]);

    if (!result.collectionByHandle)
        return null;

    return {
        ...result.collectionByHandle,
        products: result.collectionByHandle.products.map(mapper.product),
    };
};

const login = async (email, password) => {
    const data = { email, password };

    return (await $m(CustomerAccessTokenCreate, { data })).customerAccessTokenCreate;
};

const register = async (email, password) => {
    const data = { email, password };

    return (await $m(CustomerCreate, { data })).customerCreate;
};
const updateCustomer = async (_, data) => (await $m(CustomerUpdate, { data })).customerUpdate;
const account = async () => (await $q(Account, null, [AddressDetailsFragment])).customer;
const checkout = async () => $q(Checkout, null, [ProductDetailsFragment, AddressDetailsFragment]);
const recoverPassword = async email => (await $m(CustomerRecover, { email })).customerRecover;
const resetPassword = async (id, data) => (await $m(CustomerReset, { id, data })).customerReset;
const updateAddress = async (_, id, data) => (await $m(CustomerAddressUpdate, { id, data })).customerAddressUpdate;
const createAddress = async (_, data) => (await $m(CustomerAddressCreate, { data })).customerAddressCreate;
const removeAddress = async (_, id) => (await $m(CustomerAddressDelete, { id })).customerAddressDelete;



const collection = async handle => (await $q(CollectionByHandle, { handle }, [ProductDetailsFragment])).collectionByHandle;
const configurator = async () => (await $q(Configurator, null, [ConfiguratorDetailsFragment, ProductDetailsFragment]));
const createOrUpdateAddress = async (token, id, data) => {
    if (id)
        return updateAddress(token, id, data);
    else
        return createAddress(token, data);
};

const impaxis = async (endpoint, data) => {
    const baseUrl = getImpaxisBaseUrl();
    const response = await fetch(`${baseUrl}/${endpoint}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
    });

    return await response.json();
};

const activateWarranty =  data => impaxis("my-vespa/warranty", data);
const getWarrantyState =  data => impaxis("my-vespa", data);

const getImpaxisBaseUrl = () => {
    const baseUrl = process.env.VUE_APP_IMPAXIS_API_BASEURL;

    if (baseUrl)
        return baseUrl;

    if (!location?.host)
        throw "No Impaxis host found.";

    return `https://api.${location.host}`;
};

const createOrder = data => impaxis("checkout", data);

const address = {
    create: createAddress,
    update: updateAddress,
    remove: removeAddress,
    createOrUpdate: createOrUpdateAddress,
    setDefault: setDefaultAddress
};

const api = {
    page,
    product,
    products,
    login,
    register,
    updateCustomer,
    account,
    address,
    checkout,
    createOrder,
    recoverPassword,
    emailExists,
    collection,
    resetPassword,
    productsByCollection,
    configurator,
    activateWarranty,
    getWarrantyState
};

export {
    api,
    impaxis
};
