import rator from "@/state/Configurator";

const primavera = [
    {
        collection: "configurator-primavera",
        type: "requires",
        rules: [
            [
                "Primavera_Rückenlehne_biege",
                "Primavera_Rückenlehne_Braun",
                "Primavera_Rückenlehne_riot",
                "Primavera_Rückenlehne_schwarz",
                "primmlp1"
            ], // rückenlehne
            [
                "pt_topcase_grautitanium",
                "ptc_orange_impulsivo",
                "ptc_blau_vivace",
                "ptc_grau_delicato_2",
                "ptc_grau_delicato",
                "ptp_schwarz_matt",
                "CM272927",
                "CM272929",
                "CM272928",
                "cm272920",
                "1B00001600DE",
                "1b00001600r7",
                "1B0000160xn2",
                "1b00001600br",
                "CM272936",
                "GIO273337"
            ], // topcase
            ["1B000815", "606002M"] // gepäckträger hinten
        ]
    },
    {
        collection: "configurator-primavera",
        type: "requires",
        rules: [
            ["korb_classic"], // gepäckkorb
            ["1B000815", "606002M"] // gepäckträger hinten
        ]
    },
    {
        collection: "configurator-primavera",
        type: "incompatible",
        a: ["1B000928"], // tasche hinten
        b: ["1B000929"] // topcase
    },
    {
        collection: "configurator-primavera",
        type: "incompatible",
        a: ["1B000927"], // tasche hinten
        b: ["1B000926"] // topcase
    },

    {
        collection: "configurator-primavera",
        type: "requires",
        rules: [
            ["bbbps", "bbbnps", "bbgps", "bbgnps"], // primavera tasche, hinten
            ["1B000789", "1B000788"] // klappegepäckträger, hinten
        ]
    },
    {
        collection: "configurator-primavera",
        type: "requires",
        rules: [
            ["sbbps", "sbbnps", "sbgps", "sbgnps"], // primavera tasche, vorne
            ["1B000832", "1B000832XYZ"] // klappegepäckträger, vorne
        ]
    },
    {
        collection: "configurator-primavera",
        type: "incompatible",
        a: ["korb_classic"], // gepäckkorb
        b: [
            "pt_topcase_grautitanium",
            "ptc_orange_impulsivo",
            "ptc_blau_vivace",
            "ptc_grau_delicato_2",
            "ptc_grau_delicato",
            "ptp_schwarz_matt",
            "CM272927",
            "CM272929",
            "CM272928",
            "cm272920",
            "1B00001600DE",
            "1b00001600r7",
            "1B0000160xn2",
            "1b00001600br",
            "CM272936", // topcase
            "bbbps",
            "bbbnps",
            "bbgps",
            "bbgnps", // primavera tasche, hinten
            "Primavera_Rückenlehne_biege",
            "Primavera_Rückenlehne_Braun",
            "Primavera_Rückenlehne_riot",
            "Primavera_Rückenlehne_schwarz",
            "primmlp",
            "GIO273337" // rückenlehne
        ]
    },
    {
        collection: "configurator-primavera",
        type: "incompatible",
        a: ["bbbps", "bbbnps", "bbgps", "bbgnps"], // primavera tasche, hinten
        b: [
            "pt_topcase_grautitanium",
            "ptc_orange_impulsivo",
            "ptc_blau_vivace",
            "ptc_grau_delicato_2",
            "ptc_grau_delicato",
            "ptp_schwarz_matt",
            "CM272927",
            "CM272929",
            "CM272928",
            "cm272920",
            "1B00001600DE",
            "1b00001600r7",
            "1B0000160xn2",
            "1b00001600br",
            "CM272936", // topcase
            "Primavera_Rückenlehne_biege",
            "Primavera_Rückenlehne_Braun",
            "Primavera_Rückenlehne_riot",
            "Primavera_Rückenlehne_schwarz",
            "primmlp",
            "GIO273337" // rückenlehne
        ]
    },
    {
        type: "exclude",
        bikes: [
            "vespa-primavera-touring-50",
            "vespa-primavera-touring-125-abs"
        ],
        products: ["korb_classic"]
    }
];

const sprint = [
    {
        collection: "configurator-sprint",
        type: "requires",
        rules: [
            [
                "Sprint_Rückenlehne_Beige",
                "Sprint_Rückenlehne_Braun",
                "Sprint_Rückenlehne_Riot",
                "Sprint_Rückenlehne_Schwarz",
                "primmlp1"
            ], // rückenlehne
            [
                "sprint_topcase_graumatt",
                "sprint_topcase_blaumatt",
                "sprint_topcase_schwarzmatt",
                "sprint_topcase_weiss",
                "sprint_topcase_schwarz",
                "sprint_topcase_gelb",
                "sprint_topcase_riot",
                "GIO273337"
            ], // topcase
            ["1B000815", "606002M"] // gepäckträger
        ]
    },
    {
        collection: "configurator-sprint",
        type: "requires",
        rules: [
            ["korb_classic"], // gepäckkorb
            ["1B000815", "606002M"] // gepäckträger hinten
        ]
    },
    {
        collection: "configurator-sprint",
        type: "requires",
        rules: [
            ["bbbps", "bbbnps", "bbgps", "bbgnps"], // tasche hinten
            ["1B000789", "1B000788"] // klappegepäckträger, hinten
        ]
    },
    {
        collection: "configurator-sprint",
        type: "requires",
        rules: [
            ["sbbps", "sbbnps", "sbgps", "sbgnps"], // tasche vorne
            ["1B000832", "1B000832XYZ"] // klappegepäckträger, vorne
        ]
    },
    {
        collection: "configurator-sprint",
        type: "incompatible",
        a: ["1B000928"], // tasche hinten
        b: ["1B000929"] // topcase
    },
    {
        collection: "configurator-sprint",
        type: "incompatible",
        a: ["1B000927"], // tasche hinten
        b: ["1B000926"] // topcase
    },
    {
        collection: "configurator-sprint",
        type: "incompatible",
        a: ["korb_classic"], // gepäckkorb
        b: [
            "sprint_topcase_graumatt",
            "sprint_topcase_blaumatt",
            "sprint_topcase_schwarzmatt",
            "sprint_topcase_weiss",
            "sprint_topcase_schwarz",
            "sprint_topcase_gelb",
            "sprint_topcase_riot", // topcase
            "bbbps",
            "bbbnps",
            "bbgps",
            "bbgnps", // tasche hinten
            "Sprint_Rückenlehne_Beige",
            "Sprint_Rückenlehne_Braun",
            "Sprint_Rückenlehne_Riot",
            "Sprint_Rückenlehne_Schwarz",
            "GIO273337" // rückenlehne
        ]
    },
    {
        collection: "configurator-sprint",
        type: "incompatible",
        a: ["bbbps", "bbbnps", "bbgps", "bbgnps"], // tasche hinten
        b: [
            "sprint_topcase_graumatt",
            "sprint_topcase_blaumatt",
            "sprint_topcase_schwarzmatt",
            "sprint_topcase_weiss",
            "sprint_topcase_schwarz",
            "sprint_topcase_gelb",
            "sprint_topcase_riot", // topcase
            "Sprint_Rückenlehne_Beige",
            "Sprint_Rückenlehne_Braun",
            "Sprint_Rückenlehne_Riot",
            "Sprint_Rückenlehne_Schwarz",
            "GIO273337" // rückenlehne
        ]
    }
];

const elettrica = [
    {
        collection: "configurator-elettrica",
        type: "requires",
        rules: [
            ["6574"], // topcase
            ["1B000815", "606002M"] // gepäckträger hinten
        ]
    },
    {
        collection: "configurator-elettrica",
        type: "requires",
        rules: [
            ["korb_classic"], // gepäckkorb
            ["1B000815", "606002M"] // gepäckträger hinten
        ]
    },
    {
        collection: "configurator-elettrica",
        type: "requires",
        rules: [
            ["bbbps", "bbbnps", "bbgps", "bbgnps"], // primavera tasche, hinten
            ["1B000789", "1B000788"] // klappegepäckträger, hinten
        ]
    },
    {
        collection: "configurator-elettrica",
        type: "incompatible",
        a: ["1B000928"], // tasche hinten
        b: ["1B000929"] // topcase
    },
    {
        collection: "configurator-elettrica",
        type: "incompatible",
        a: ["1B000927"], // tasche hinten
        b: ["1B000926"] // topcase
    },
    {
        collection: "configurator-elettrica",
        type: "requires",
        rules: [
            ["sbbps", "sbbnps", "sbgps", "sbgnps"], // primavera tasche, vorne
            ["1B000832", "1B000832XYZ"] // klappegepäckträger, vorne
        ]
    },
    {
        collection: "configurator-elettrica",
        type: "incompatible",
        a: ["korb_classic"], // gepäckkorb
        b: [
            "6574", // topcase
            "bbbps",
            "bbbnps",
            "bbgps",
            "bbgnps" // primavera tasche, hinten
        ]
    },
    {
        collection: "configurator-elettrica",
        type: "incompatible",
        a: ["bbbps", "bbbnps", "bbgps", "bbgnps"], // primavera tasche, hinten
        b: ["6574"] // topcase
    }
];

const gts = [
    {
        collection: "configurator-gts",
        type: "requires",
        rules: [
            [
                "CM273355",
                "CM273354",
                "CM273351",
                "CM273358",
                "CM273348",
                "CM273356",
                "CM273352",
                "CM273347",
                "CM273346",
                "CM273353",
                "CM273337",
                "CM273342",
                "gts_ss_topcase_grau_titanium",
                "gts_topcase_grau_titanio",
                "gts_topcase_grau_delicato_matt",
                "gts_topcase_grau_delicato",
                "gts_topcase_schwarz_convintomatt",
                "gts_topcase_orange_impulsivo",
                "gts_ss_topcase_blau_vivace",
                "gts_ss_topcase_weiss_innocenza",
                "GIO273337"
            ], // topcase
            ["605665M", "FA009N"] // klappegepäckträger hinten
        ]
    },
    {
        collection: "configurator-gts",
        type: "requires",
        rules: [
            ["bbbg", "bbbng", "bbgg", "bbgng"], // tasche hinten
            ["605665M", "FA009N"] // klappegepäckträger hinten
        ]
    },
    {
        collection: "configurator-gts",
        type: "requires",
        rules: [
            ["sbbg", "sbbng", "sbgg", "sbgng"], // tasche vorne
            ["1B001482", "1B001484"] // klappegepäckträger vorne
        ]
    },
    {
        collection: "configurator-gts",
        type: "incompatible",
        a: ["bbbg", "bbbng", "bbgg", "bbgng"], // tasche hinten
        b: [
            "CM273355",
            "CM273354",
            "CM273351",
            "CM273358",
            "CM273348",
            "CM273356",
            "CM273352",
            "CM273347",
            "CM273346",
            "CM273353",
            "CM273337",
            "CM273342",
            "gts_ss_topcase_grau_titanium",
            "gts_topcase_grau_titanio",
            "gts_topcase_grau_delicato_matt",
            "gts_topcase_grau_delicato",
            "gts_topcase_schwarz_convintomatt",
            "gts_topcase_orange_impulsivo",
            "gts_ss_topcase_blau_vivace",
            "gts_ss_topcase_weiss_innocenza",
            "GIO273337"
        ] // topcase
    },
    {
        collection: "configurator-gts",
        type: "incompatible",
        a: ["1B000928"], // tasche hinten
        b: ["1B000929"] // topcase
    },
    {
        collection: "configurator-gts",
        type: "incompatible",
        a: ["1B000927"], // tasche hinten
        b: ["1B000926"] // topcase
    },
    {
        type: "exclude",
        bikes: ["vespa-gts-racing-sixties-300"],
        products: ["blackpack", "tuning-spiegel"]
    },
    {
        type: "exclude",
        bikes: ["vespa-gts-touring-300-hpe-abs-asr"],
        products: ["windschild-1"]
    },
    {
        type: "exclude",
        bikes: ["vespa-sei-giorni-ii-300-hpe-abs-asr"],
        products: ["windschild-1", "blackpack", "tuning-spiegel"]
    }
];

const gts125 = [
    {
        collection: "configurator-gts-125",
        type: "requires",
        rules: [
            [
                "CM273355",
                "CM273354",
                "CM273351",
                "CM273358",
                "CM273348",
                "CM273356",
                "CM273352",
                "CM273347",
                "CM273346",
                "CM273353",
                "CM273337",
                "CM273342",
                "gts_ss_topcase_grau_titanium",
                "gts_topcase_grau_titanio",
                "gts_topcase_grau_delicato_matt",
                "gts_topcase_grau_delicato",
                "gts_topcase_schwarz_convintomatt",
                "gts_topcase_orange_impulsivo",
                "gts_ss_topcase_blau_vivace",
                "gts_ss_topcase_weiss_innocenza",
                "GIO273337"
            ], // topcase
            ["605665M", "FA009N"] // klappegepäckträger hinten
        ]
    },
    {
        collection: "configurator-gts-125",
        type: "requires",
        rules: [
            ["bbbg", "bbbng", "bbgg", "bbgng"], // tasche hinten
            ["605665M", "FA009N"] // klappegepäckträger hinten
        ]
    },
    {
        collection: "configurator-gts-125",
        type: "requires",
        rules: [
            ["sbbg", "sbbng", "sbgg", "sbgng"], // tasche vorne
            ["1B001482", "1B001484"] // klappegepäckträger vorne
        ]
    },
    {
        collection: "configurator-gts-125",
        type: "incompatible",
        a: ["1B000928"], // tasche hinten
        b: ["1B000929"] // topcase
    },
    {
        collection: "configurator-gts-125",
        type: "incompatible",
        a: ["1B000927"], // tasche hinten
        b: ["1B000926"] // topcase
    },
    {
        collection: "configurator-gts-125",
        type: "incompatible",
        a: ["bbbg", "bbbng", "bbgg", "bbgng"], // tasche hinten
        b: [
            "CM273355",
            "CM273354",
            "CM273351",
            "CM273358",
            "CM273348",
            "CM273356",
            "CM273352",
            "CM273347",
            "CM273346",
            "CM273353",
            "CM273337",
            "CM273342",
            "gts_ss_topcase_grau_titanium",
            "gts_topcase_grau_titanio",
            "gts_topcase_grau_delicato_matt",
            "gts_topcase_grau_delicato",
            "gts_topcase_schwarz_convintomatt",
            "gts_topcase_orange_impulsivo",
            "gts_ss_topcase_blau_vivace",
            "gts_ss_topcase_weiss_innocenza",
            "GIO273337"
        ] // topcase
    },
    {
        type: "exclude",
        bikes: ["vespa-gts-racing-sixties-125"],
        products: ["blackpack"]
    }
];

const config = [
    ...primavera,
    ...sprint,
    ...elettrica,
    ...gts,
    ...gts125,
    {
        type: "colormatch",
        products: ["gts-topcase", "primavera-topcase", "sprint-topcase"]
    },
    {
        type: "multiple",
        products: [
            "sturzbugel",
            "sturzbugel-1",
            "leistungsssteigerung",
            "sturzbugel-3",
            "outdoor-fahrzeugdecke-vespa-gts",
            "wetterschutz-1",
            "motolino-luxury-pack"
        ]
    },
    {
        collection: "all",
        type: "incompatible",
        a: ["blackpack"],
        b: [
            "CM273355",
            "CM273354",
            "CM273351",
            "CM273358",
            "CM273348",
            "CM273356",
            "CM273352",
            "CM273347",
            "CM273346",
            "CM273353",
            "CM273337",
            "CM273342",
            "gts_ss_topcase_grau_titanium",
            "gts_topcase_grau_titanio",
            "gts_topcase_grau_delicato_matt",
            "gts_topcase_grau_delicato",
            "gts_topcase_schwarz_convintomatt",
            "gts_topcase_orange_impulsivo",
            "gts_ss_topcase_blau_vivace",
            "gts_ss_topcase_weiss_innocenza",
            "GIO273337"
        ] // gepäckkorb, // topcase
    }
];
/* eslint no-unused-vars: off */

const getIssues = value => {
    const change = getChangeSet(value);
    const incompatible = checkIncompatible(change);

    if (incompatible.length > 0) {
        return {
            type: "incompatible",
            values: incompatible,
        }
    }

    const requires = checkRequires(change);

    if (requires.length > 0) {
        return {
            type: "requires",
            values: requires,
        }
    }

    return null;
};

const getIssues2 = value => {
    const change = getChangeSet(value);
    const incompatible = checkIncompatible(change);
    const requires = checkRequires(change);

    if (incompatible.length > 0 || requires.length > 0)
        return { incompatible, requires }

    return null;
};

const getChangeSet = value => {
    const rules = config.filter(r => r.collection == rator.state.collection?.handle || r.collection === 'all');
    const removing = value.selected ? [value.sku] : value.product.variants.map(v => v.sku).filter(sku => sku !== value.sku);
    const adding = value.selected ? null : value.sku;

    return { rules, adding, removing };
};


const checkRequires = change => {
    if (!change.adding)
        return [];

    const matches = [];
    const values = rator.state.addons.map(a => a.values).flat();
    const selected = values.filter(v => v.selected);

    for (let rule of change.rules.filter(r => r.type == "requires")) {
        let index = rule.rules.findIndex(a => a.includes(change.adding)) + 1;

        if (index == 0)
            continue;

        while (index < rule.rules.length) {
            const skus = rule.rules[index];
            const value = selected.find(v => skus.includes(v.sku));

            if (!value) {
                const addValue = values.find(v => skus.includes(v.sku));

                if (addValue)
                    matches.push(addValue);
            }

            index++;
        }
    }

    return matches;
};

const checkIncompatible = change => {
    const matches = [];
    const selected = rator.state.addons.map(a => a.values.filter(v => v.selected)).flat();

    for (let sku of change.removing) {
        for (let rule of change.rules.filter(r => r.type == "requires")) {
            let index = rule.rules.findIndex(a => a.includes(sku) && !a.includes(change.adding)) - 1;

            while (index > -1) {
                const skus = rule.rules[index];
                const values = selected.filter(v => skus.includes(v.sku));

                matches.splice(0, 0, ...values);

                index--;
            }
        }
    }
    for (let rule of change.rules.filter(r => r.type == "incompatible")) {
        let skus = [];

        if (rule.a.includes(change.adding))
            skus = rule.b;
        else if (rule.b.includes(change.adding))
            skus = rule.a;

        const values = selected.filter(v => skus.includes(v.sku))

        matches.splice(0, 0, ...values);
    }

    return [...new Set(matches)];
};

const matchColor = handle => config.some(r => r.type == "colormatch" && r.products.includes(handle));
const isMultiple = handle => config.some(r => r.type == "multiple" && r.products.includes(handle));

const getAddons = () => {
    if (!rator.state.bike)
        return [];

    const rules = config.filter(r => r.type == "exclude" && r.bikes.includes(rator.state.bike.product.handle));
    const notAvail = rules.map(r => r.products).flat();

    return combineAddons(rator.state.addons.filter(p => !notAvail.includes(p.handle)));
};

const combineAddons = (addons) =>  {
    return combineBags2(combineBags(combineRacks(combineBufferBar(addons))));
}

const combineBags = (addons) =>  {
    const backRack = addons.find(a => a.title === 'GTS Taschen Vorne');
    const frontRack = addons.find(a => a.title === 'GTS Taschen Hinten');

    if (!backRack || !frontRack) return addons;

    const sturzIndex = addons.findIndex(a => a.title === 'GTS Taschen Vorne');
    addons = [...addons.slice(0, sturzIndex), ...addons.slice(sturzIndex + 1)];

    frontRack.isMulti = true;
    frontRack.others = backRack;
    frontRack.newOptionTitle = 'GTS Taschen';

    return addons;
}

const combineBags2 = (addons) =>  {
    const backRack = addons.find(a => a.title === 'Taschen & Gepäckkörbe vorne');
    const frontRack = addons.find(a => a.title === 'Taschen & Gepäckkörbe hinten');

    if (!backRack || !frontRack) return addons;

    const sturzIndex = addons.findIndex(a => a.title === 'Taschen & Gepäckkörbe vorne');
    addons = [...addons.slice(0, sturzIndex), ...addons.slice(sturzIndex + 1)];

    frontRack.isMulti = true;
    frontRack.others = backRack;
    frontRack.newOptionTitle = 'Taschen & Gepäckkörbe';

    return addons;
}

const combineBufferBar = (addons) =>  {

    const crashBar = addons.find(a => a.title === 'Sturzbügel');
    const bufferBar = addons.find(a => a.title === 'Stossstange');

    if (!crashBar || !bufferBar) return addons;

    const sturzIndex = addons.findIndex(a => a.title === 'Sturzbügel');
    addons = [...addons.slice(0, sturzIndex), ...addons.slice(sturzIndex + 1)];

    bufferBar.isMulti = true;
    bufferBar.others = crashBar;
    bufferBar.newOptionTitle = bufferBar.title + ' & ' + crashBar.title;

    return addons;
}

const combineRacks = (addons) =>  {
    const backRack = addons.find(a => a.title === 'Gepäckträger hinten');
    const frontRack = addons.find(a => a.title === 'Gepäckträger vorne');

    if (!backRack || !frontRack) return addons;

    const sturzIndex = addons.findIndex(a => a.title === 'Gepäckträger hinten');
    addons = [...addons.slice(0, sturzIndex), ...addons.slice(sturzIndex + 1)];

    frontRack.isMulti = true;
    frontRack.others = backRack;
    frontRack.newOptionTitle = 'Gepäckträger';

    return addons;
}

export default { getIssues, getAddons, matchColor, isMultiple, getIssues2 };
