<template>
    <footer class="tw-w-full tw-flex tw-flex-col tw-py-5 tw-max-w-screen-lg xl:tw-max-w-screen-xl 2xl:tw-max-w-full 2xl:tw-px-16 xl:tw-px-10 tw-px-5 tw-mx-auto tw-space-y-5 md:tw-space-y-0" id="footer">
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-10 tw-space-y-10 md:tw-space-y-0 tw-justify-between tw-border-t-2 tw-border-gray tw-pt-10">
            <div class="tw-flex tw-flex-col tw-space-y-5">
                <div class="tw-font-bold tw-text-xl">
                    Motolino
                </div>
                <ul class="footer__links__content__list tw-space-y-2">
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/unterschiede">Modell unterschiede</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/uber-uns">Über uns</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/franchising">Motolino-Partner werden</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/jobs">Jobs</router-link></li>
                </ul>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-5">
                <div class="tw-font-bold tw-text-xl">
                    Hilfe &amp; Support
                </div>
                <ul class="footer__links__content__list tw-space-y-2">
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/garantie">Service &amp; Garantie</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/video-tutorial">Video-Tutorials</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/vespa-mit-16">Vespa fahren mit 16 Jahren</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/faq">FAQ</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/franchising">Motolino-Partner werden</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/versandbedingungen">Versandbedingungen</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/agb">AGB</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/datenschutz">Datenschutz</router-link></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><router-link to="/pages/impressum">Impressum</router-link></li>
                </ul>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-5">
                <div class="tw-font-bold tw-text-xl">
                    Soziale Medien
                </div>
                <ul class="footer__social__content__list tw-space-y-2">
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><a href="https://www.facebook.com/motolino/" target="_blank">Facebook</a></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><a href="https://twitter.com/MotolinoZH" target="_blank">Twitter</a></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><a href="https://www.youtube.com/channel/UCZCvAnkP6S4RSDrB_79UEbg" target="_blank">YouTube</a></li>
                    <li class="tw-text-gray-dark hover:tw-text-primary tw-transition tw-text-sm"><a href="https://www.instagram.com/motolino_store" target="_blank">Instagram</a></li>
                </ul>
            </div>
            <form action="https://motolino.us1.list-manage.com/subscribe/post?u=71750e49faf0ea07a21e12ffd&amp;id=c151bad176" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate tw-flex tw-flex-col tw-space-y-5" target="_blank">
                <div class="">
                    <div class="tw-font-bold tw-text-xl">
                        Für den Newsletter anmelden
                    </div>
                    <p class="tw-text-sm tw-text-gray-dark">Abonniere den Newsletter und verpasse keine Neuigkeiten mehr!</p>
                </div>
                <div class="tw-flex tw-items-center">
                    <input placeholder="Deine email" type="email" name="EMAIL" id="mce-EMAIL" class="tw-border tw-flex-1 tw-border-gray-darker tw-px-5 tw-py-3" required />
                    <div class="hiddenInput" aria-hidden="true">
                        <input type="text" name="b_b2531c25d1ff1a06ac25ac413_d298a51a17" />
                    </div>
                    <button class="tw-flex tw-items-center tw-space-x-2 tw-border tw-border-primary" id="mc-embedded-subscribe">
                        <img v-svg-inline src="@/assets/img/arrow_right_w.svg" />
                        <span>Abonnieren</span>
                    </button>
                </div>
            </form>
        </div>
        <div class="tw-flex tw-text-gray-dark tw-justify-center md:tw-justify-end">
            Made with ♥ in Zürich
        </div>
    </footer>
</template>

<style src="@/assets/scss/views/Footer.scss" lang="scss"></style>
