<template>
    <div>
        <h1>Meine Daten</h1>
        <div class="account-section">
            <div class="account-block">
                <h4>Persönliche Daten</h4>
                <ul class="account-info">
                    <li v-if="!editProfile">
                        <span>Vorname</span>
                        <p>{{ firstName || "-" }}</p>
                    </li>
                    <li v-if="!editProfile">
                        <span>Nachname</span>
                        <p>{{ lastName || "-" }}</p>
                    </li>
                    <li v-if="!editProfile">
                        <span>Mobil (Tel)</span>
                        <p>{{ phone || "-" }}</p>
                    </li>
                    <li v-if="editProfile">
                        <div class="input">
                            <span class="input__label">Vorname</span>
                            <input type="text" v-model="firstName" class="input__field" />
                        </div>
                    </li>
                    <li v-if="editProfile">
                        <div class="input">
                            <span class="input__label">Nachname</span>
                            <input type="text" v-model="lastName" class="input__field" />
                        </div>
                    </li>
                    <li v-if="editProfile">
                        <div class="input">
                            <span class="input__label">Mobil (Tel)</span>
                            <input type="text" v-model="phone" class="input__field" />
                        </div>
                    </li>
                </ul>

                <button class="account-info__edit save-mod" @click="saveProfile" v-if="editProfile">
                    <div>
                        <img v-svg-inline src="@/assets/img/icons/save-green.svg" />
                    </div>
                </button>
                <button class="account-info__edit" @click="editProfile = true" v-if="!editProfile">
                    <div>
                        <img v-svg-inline src="@/assets/img/icons/edit.svg" />
                    </div>
                </button>
            </div>

            <div class="account-block">
                <h4>PASSWORT</h4>
                <ul class="account-info">
                    <li v-if="!editPassword">
                        <span>Passwort</span>
                        <p>*********</p>
                    </li>
                    <li v-if="editPassword">
                        <div class="input">
                            <span class="input__label">Altes Passwort</span>
                            <input type="password" v-model="oldPassword" class="input__field" />
                        </div>
                    </li>
                    <li v-if="editPassword">
                        <div class="input">
                            <span class="input__label">Neues Passwort</span>
                            <input type="password" v-model="newPassword" class="input__field" />
                        </div>
                    </li>
                    <li v-if="editPassword">
                        <div class="input">
                            <span class="input__label">Passwort Wiederhole</span>
                            <input type="password" v-model="repeatPassword" class="input__field" />
                        </div>
                    </li>
                </ul>

                <button class="account-info__edit save-mod" @click="savePassword" v-if="editPassword">
                    <div>
                        <img v-svg-inline src="@/assets/img/icons/save-green.svg" />
                    </div>
                </button>
                <button class="account-info__edit" @click="editPassword = true" v-if="!editPassword">
                    <div>
                        <img v-svg-inline src="@/assets/img/icons/edit.svg" />
                    </div>
                </button>
            </div>

            <div class="account-block address-section" v-for="a in addresses" :key="a.id">
                <div class="address-section-mod">
                    <ul class="account-info">
                        <li>
                            <strong>
                                {{ a.firstName }} {{ a.lastName }}
                            </strong>
                        </li>
                        <li v-if="a.company">{{ a.company }}</li>
                        <li v-if="a.address1">{{ a.address1 }}</li>
                        <li v-if="a.address2">{{ a.address2 }}</li>
                        <li v-if="a.zip || a.city">{{ a.zip }} {{ a.city }}</li>
                        <li v-if="a.province">{{ a.province }}</li>
                        <li v-if="a.country">{{ a.country }}</li>
                    </ul>
                    <div :class="['toggle-address', { active: a.default }]" @click="setDefault(a)">
                        Standard Adresse
                    </div>
                    <div :class="['show-more-button', { active: a.more }]">
                        <button class="account-info__edit trash-mod" @click="removeAddress(a)">
                            <img v-svg-inline src="@/assets/img/icons/trash.svg" />
                        </button>
                        <button class="account-info__edit" @click="changeAddress(a)">
                            <img v-svg-inline src="@/assets/img/icons/edit.svg" />
                        </button>
                        <div class="more-dots" @click="a.more = !a.more">
                            <span></span>
                            <span></span>
                            <span></span>
                            <img v-svg-inline src="@/assets/img/x.svg" class="close-icon"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="account-block account-info__add">
                <button @click="addAddress">
                    + Neue Adresse hinzufügen
                </button>

                <Address v-if="editAddress" :data="addressData" @change="updateAddress" />
            </div>
        </div>
    </div>
</template>

<script>
    import Address from "../Common/Address";

    export default {
        props: ["data"],
        inject: ["user", "top"],
        components: { Address },
        data: () => ({
            firstName: null,
            lastName: null,
            phone: null,
            oldPassword: null,
            newPassword: null,
            repeatPassword: null,
            editAddress: false,
            editProfile: false,
            editPassword: false,
            addressData: null,
            addresses: [],
        }),
        mounted() {
            this.load();
        },
        methods: {
            updateAddress(data) {
                this.editAddress = false;
                this.top.overlay = null;

                if (data) {
                    const prevIndex = this.addresses.findIndex(a => a.id == data.id);

                    if (prevIndex > -1) {
                        this.addresses[prevIndex] = {
                            ...data,
                            default: this.addresses[prevIndex].default,
                        };
                    }
                    else
                        this.addresses.push(data);
                }
            },
            addAddress() {
                this.top.overlay = "modal";
                this.editAddress = true;
                this.addressData = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                };
            },
            async removeAddress(data) {
                data.more = false;

                const prevIndex = this.addresses.findIndex(a => a.id == data.id);

                this.addresses.splice(prevIndex, 1);
                this.user.removeAddress(data.id);
            },
            setDefault(data) {
                this.user.setDefaultAddress(data.id);
                this.addresses.forEach(a => a.default = (a.id == data.id));
            },
            changeAddress(data) {
                data.more = false;

                this.top.overlay = "modal";
                this.editAddress = true;
                this.addressData = {
                    ...data,
                };
            },
            load() {
                const defaultId = (this.data.defaultAddress || { }).id;
                this.firstName = this.data.firstName;
                this.lastName = this.data.lastName;
                this.phone = this.data.phone;
                this.addresses = this.data.addresses;

                this.addresses.forEach(a => a.default = (a.id == defaultId));
            },
            saveProfile() {
                this.user.updateProfile(this.firstName, this.lastName, this.phone);
                this.editProfile = false;
            },
            async savePassword() {
                if (this.newPassword)
                    await this.user.changePassword(this.newPassword);
                
                this.editPassword = false;
            }
        }
    };
</script>
