import Checkout from "@/views/Checkout.vue";
import Account from "@/views/Account.vue";
import Cart from "@/views/Cart.vue";

export default [
    {
        path: "/cart",
        name: "Cart",
        component: Cart,
    },
    {
        path: "/account/:tab/:id?",
        name: "Account",
        component: Account,
        meta: {
            hideTopMenu: true,
            hideFooter: true
        }
    },
    {
        path: "/checkout/:step?",
        name: "Checkout",
        component: Checkout,
        meta: {
            hideTopMenu: true,
            hideFooter: true
        }
    }
];