import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Article from "@/views/Article.vue";
import Product from "@/views/Product.vue";
import Category from "@/views/Category.vue";
import NotFound from "@/views/NotFound.vue";
import ConfiguratorRoutes from "./Configurator";
import UserRoutes from "./User";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            isHome: true
        }
    },
    {
        path: "/s/:token",
        name: "Share",
        component: Home,
        meta: {
            isHome: true
        }
    },
    {
        path: "/pages/:handle",
        name: "Article",
        component: Article,
    },
    {
        path: "/page/:handle",
        name: "ArticleOld",
        component: Article,
    },
    {
        path: "/products/:handle/:id?",
        name: "Product",
        component: Product,
    },
    {
        path: "/category/:handle/:id?",
        name: "Category",
        component: Category,
    },
    ...ConfiguratorRoutes,
    ...UserRoutes,
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    }
];

export default createRouter({
    history: createWebHistory(),
    routes,
});