<template>
    <div :class="['dropdown', { open: open }]" @click="toggle">
        <div class="dropdown-title">
            {{ data.name }}
        </div>
        <div class="dropdown-content" v-if="open" @click.stop="select">
            <div :class="['dropdown-item layout-row', { selected }]">
                <div class="dropdown-dot">
                    <div>
                        <div>
                            <img
                                v-svg-inline
                                src="@/assets/img/icons/check-white.svg"
                            />
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <span>{{ data.name }}</span>
                </div>
                <!-- <div class="Tooltip">
                    <div class="information"><span class="question-mark">?</span></div>
                </div> -->
                <div class="dropdown-price" v-if="value?.price">
                    + CHF {{ value.price }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["data", "variant"],
    emits: ["pick"],
    data: () => ({
        open: false,
        value: null
    }),
    mounted() {
        this.update();
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        async select() {
            this.$emit("pick", this.value);
        },
        update() {
            const color = this.variant?.selectedOptionsMap["Vespa Farbe"];

            this.value = this.data?.values.find(opt => opt.subtitle?.toLowerCase() == color?.toLowerCase());

            if (!this.value)
                this.data?.values[0] ?? null;
        }
    },
    computed: {
        selected() {
            return this.data?.values.some(v => v.selected);
        }
    },
    watch: {
        selected() {
            const otherValue = this.data.values.find(v => v.selected);

            if (otherValue && this.value.subtitle != otherValue.subtitle)
                this.$emit("pick", this.value);
        },
        variant() {
            this.update();

            if (this.selected) this.$emit("pick", this.value);
        }
    }
};
</script>
