<template>
    <div>
        <Hero />
        <Offers />

        <Dialog2 v-model="replace"
                title="Aktuelle Konfiguration leeren?"
                yes="Ja, neue Konfiguration laden"
                no="Nein, alte Konfiguration behalten"
                @yes="loadShared"
                @no="$router.replace('/')"
                @cancel="$router.replace('/')"

        >
            <p>
                Du öffnest eine neue Konfiguration, hast aber bereits eine laufende Vespa-Konfiguration. Möchtest du die neue Konfiguration laden?
            </p>
        </Dialog2>
    </div>
</template>

<script>
    import Hero from "@/components/Home/Hero.vue";
    import Offers from "@/components/Home/Offers.vue";
    import { impaxis } from "@/services/Query";

    export default {
        data: () => ({
            shared: null,
            replace: false,
        }),
        inject: ["rator"],
        components: { Hero, Offers },
        created() {
            this.checkShare();
        },
        methods: {
            async checkShare() {
                if (this.hasShare) {
                    this.shared = await impaxis("share/load", this.$route.params.token);

                    if (!this.rator.hasBike())
                        this.loadShared();
                    else
                        this.replace = true;
                }   
            },
            async loadShared() {
                this.rator.loadShare(this.shared);

                this.$router.replace("/configurator/builder");
            },
        },
        computed: {
            hasShare() {
                return this.$route.params.token;
            }
        }
    }
</script>
