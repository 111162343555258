<template>
    <div class="datepicker">
        <Vue3Datepicker v-model="value" inputFormat="dd.MM.yyyy" :upperLimit="new Date()" placeholder="DD.MM.YYYY" />
        <input type="text" required class="hidden" v-if="required" v-model="value" />
    </div>
</template>

<script>
    import Vue3Datepicker from 'vue3-datepicker'

    export default {
        components: { Vue3Datepicker },
        props: ["modelValue", "required"],
        emits: ["update:modelValue", "change"],
        data: () => ({
            value: null,
        }),
        mounted() {
            this.value = this.modelValue;
        },
        watch: {
            value(newValue) {
                this.$emit("update:modelValue", newValue);
                this.$emit("change", newValue);
            }
        }
    }
</script>

<style lang="scss">
    .datepicker {
        position: relative;

        input.hidden {
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;
        }
    }
</style>