<template>
    <div class="category tw-w-full tw-flex tw-flex-col tw-max-w-screen-lg xl:tw-max-w-screen-xl tw-px-5 lg:tw-px-0 md:tw-mx-auto tw-mb-10">
        <div class="category__header">
            <span class="category__header__title" v-if="!loading">
                <h1>{{ collection?.title }}</h1>
            </span>

            <span class="category__header__title" v-if="!loading && !collection">
                <h1>Not found</h1>
            </span>

            <Spinner v-if="loading" />
        </div>

      <div class="category__wrapper layout-row">
        <div :class="['product-filters', { 'mobile-open': filterOpen }]">
            <div class="product-filters__inner" v-if="!loading && found">
                <div class="close-category-modal" @click="filterOpen = false">
                    <img class="icon" src="@/assets/img/icons/arrow_left.svg" v-cloak/>
                </div>
                <div class="product-filters__header">Filter</div>
                <div>
                    <div class="product-filters__grid__filter" v-for="(f, i) in view.filter.options" :key="i">
                        <Listdown v-model="view.options[i]" :options="f.values" :title="f.name" @change="update" />
                    </div>
                </div>
                <p class="react-slider-wrapper__title">Preis</p>
                <div class="react-slider-wrapper tw-pt-5 tw-px-5" v-if="view.filter.price.min < view.filter.price.max">
                    <Slider v-model="view.price" :min="view.filter.price.min" :max="view.filter.price.max" @change="update" />
                </div>
            </div>
            <button class="save-category__mob" @click="filterOpen = false">
                <img src="@/assets/img/icons/check-white.svg" />
                <span>Anwenden</span>
            </button>
        </div>
        <button class="category-button__mob" @click="filterOpen = true">
            <img src="@/assets/img/icons/filter-white.svg" alt=""/>
            Filter
        </button>
        <div class="products-list flex">
            <div class="products-list__products" v-if="!loading">
                <div class="products-list__products__subheader" v-if="found">
                    <div class="applied-filters">
                        <span class="applied-filters__filter" v-for="(t, i) in tags" :key="i">
                            {{ t.text }}
                            <img src="@/assets/img/x.svg" @click="clear(t)" />
                        </span>
                    </div>
                    <span class="products-list__products__subheader__sort" style="display:block">
                        <Dropdown :options="sort" v-model="sortKey" :required="true" @change="update" />
                    </span>
                </div>
                <div class="products-list__products__grid" v-if="products.length > 0">
                    <div class="product-list-item" v-for="(p, i) in products" :key="i">
                        <router-link :to="p.url">
                            <div class="product-list-item__image">
                                <img :src="p.image" />
                                <div class="product-list-item__image__search">
                                    <div class="product-list-item__image__search__image tw-flex tw-items-center tw-justify-center">
                                        <img v-svg-inline src="@/assets/img/cart.svg" />
                                    </div>
                                </div>
                            </div>
                            <h4 class="product-list-item__title">{{ p.title }}</h4>
                            <p class="product-list-item__price">{{ price(p.price) }}</p>
                        </router-link>
                    </div>
                </div>
                <div class="products-list__products-not-found" v-if="products.length == 0">
                    Wir konnten kein Produkt finden, das diesen Bedingungen entspricht.
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
    import { api } from "@/services/Query";
    import { price } from "@/services/Formatters";
    import ProductView from "@/state/ProductView";

    export default {
        data: () => ({
            loading: false,
            filterOpen: false,
            found: true,
            products: [],
            collection: null,
            sort: [
                { value: "price-asc", label: "Tiefster Preis zu erst" },
                { value: "price-desc", label: "Höchster Preis zu erst" },
                { value: "title-asc", label: "A-Z" },
                { value: "title-desc", label: "Z-A" }
            ],
            sortKey: null,
            tags: [],
            view: null,
        }),
        created() {
            this.load();
        },
        methods: {
            price,
            async load() {
                this.loading = true;
                this.found = true;

                this.collection = await api.productsByCollection(this.$route.params.handle);

                if (!this.collection) {
                    this.found = false;
                    this.loading = false;

                    return;
                }

                this.view = new ProductView(this.collection.products);
                this.sortKey = this.sort[0].value;

                this.view.price = [this.view.filter.price.min, this.view.filter.price.max];
                this.view.options.forEach((_, i) => this.view.options[i] = null);

                this.update();

                this.loading = false;
            },
            clear(tag) {
                if (tag.type == "price")
                    this.view.price = [this.view.filter.price.min, this.view.filter.price.max];
                else if (tag.type == "option")
                    this.view.options[tag.index] = null;

                this.update();
            },
            update() {
                this.tags = [];

                if (this.view.price[0] > this.view.filter.price.min || this.view.price[1] < this.view.filter.price.max) {
                    const text = "CHF " + this.view.price[0] + "-" + this.view.price[1];

                    this.tags.push({ type: "price", text });
                }

                this.view.options.forEach((opt, i) => {
                    if (opt) {
                        this.tags.push({
                            text: opt,
                            type: "option",
                            index: i
                        });
                    }
                });

                this.products = this.view.search();
                this.products.forEach(p => p.url += "?c=" + this.$route.params.handle);
                this.products.sort(this.sortFn());
            },
            sortFn() {
                switch (this.sortKey) {
                    case "price-asc":
                        return (p1, p2) => p1.price <= p2.price ? -1 : 1;
                    case "price-desc":
                        return (p1, p2) => p1.price >= p2.price ? -1 : 1;
                    case "title-desc":
                        return (p1, p2) => p2.title.localeCompare(p1.title);
                    default:
                        return (p1, p2) => p1.title.localeCompare(p2.title);
                }
            }
        },
        watch: {
            "$route.params.handle"() {
                if (this.$route.name == "Category")
                    this.load();
            },
        }
    };
</script>


<style src="@/assets/scss/views/Category.scss" lang="scss"></style>
