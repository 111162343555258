<template>
    <div class="product-page tw-w-full tw-flex tw-flex-col tw-max-w-screen-lg xl:tw-max-w-screen-xl tw-px-5 lg:tw-px-0 md:tw-mx-auto tw-mb-10">
        <div>
            <Spinner v-if="loading" />

            <ul class="breadcrumbs" v-if="!loading">
                <li>
                    <router-link to="/">
                        Shop
                    </router-link>
                </li>

                <li v-if="variant && $route.query.c">
                    <router-link :to="`/category/${$route.query.c}`">
                        {{ variant?.product.productType || "Not found" }}
                    </router-link>
                </li>
                <li v-if="variant">
                    <router-link :to="variant.url">
                        {{ variant.product.title || "Not found" }}
                    </router-link>
                </li>
            </ul>
        </div>
        <div v-if="variant">

            <div class="product-page__product layout-row">
                <div class="product-page__product__gallery">
                    <img src="@/assets/img/no_vespa.png" v-if="isAddon" />
                    <Carousel :items="variant.product.images.map(i => i.transformedSrc)" v-if="!isAddon" />
                </div>
                <div class="product-page__product__info">
                    <div>
                        <div class="product-description tw-flex tw-flex-col">
                            <h1 class="product-page__title">{{ variant?.product.title || "Not found" }}</h1>

                            <div class="product-description__desc">
                                <div class="product-description__desc__text" v-html="truncateDescription(variant.product.descriptionHtml)"></div>
                            </div>
                            <p class="product-description__price">{{ price(variant.price, 2) }}</p>

                            <div class="product-description__variant-picker">
                                <div class="react-select-wrapper" v-for="(o, i) in view.filter.options" :key="i">
                                    <span class="input__label">{{ o.name }}</span>
                                    <Dropdown v-model="view.options[i]" :options="o.values" @change="change" />
                                </div>
                                <div class="quantity__wrap" v-if="!isVespa">
                                    <div class="quantity-label">Menge</div>
                                    <div class="quantity">
                                        <div :class="['quantity-btn btn-min', { disabled: qty <= 1 }]" @click="minus"></div>
                                        <div class="quantity-inner">
                                            {{ qty }}
                                        </div>
                                        <div :class="['quantity-btn btn-max', { disabled: qty >= 99 }]" @click="plus"></div>
                                    </div>
                                </div>
                            </div>

                            <button @click="configure" v-if="isVespa">
                                <img src="@/assets/img/icons/filter-white.svg" />
                                Konfigurieren
                            </button>

                            <button @click="addToCart()" class="product-description__action secondary tw-w-auto tw-place-self-start" :disabled="isConfigured">
                                <span class="button-animate">
                                    <div>
                                        <img v-svg-inline src="@/assets/img/cart.svg" />
                                    </div>
                                    {{ isConfigured ? 'Ohne Konfiguration bestellen' : 'In den Warenkorb hinzufügen' }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="description" v-if="variant">
            <div class="product-page__choices">
                <ul>
                    <li class="choice selected">Beschreibung</li>
                </ul>
            </div>
            <div class="product-page__desc">
                <div class="product-page__desc__text" v-html="variant.product.descriptionHtml"></div>
            </div>
        </div>


        <Dialog v-if="configuring"
                title="Konfiguration fortsetzen"
                yes="Alte Konfiguration verwerfen und neue starten"
                no="Alte Konfiguration behalten"
                @cancel="configuring = false"
                @yes="configureYes"
                @no="configuring = false"

        >
            <p>Du bist dran, eine neue Konfiguration zu starten und die Alte zu verwerfen.</p>
        </Dialog>
    </div>
</template>

<script>
    import { api } from "@/services/Query";
    import { price } from "@/services/Formatters";
    import ProductView from "@/state/ProductView"
    import Gtm from "@/services/GtmHelper";

    export default {
        inject: ["cart", "top", "rator"],
        data: () => ({
            loading: false,
            qty: 1,
            view: null,
            variant: null,
            configuring: false,
        }),
        created() {
            this.load();
        },
        methods: {
            price,
            change() {
                this.variant = this.view.select();
            },
            configure() {
                if (this.rator.state.step == 2)
                    this.configuring = true;

                this.$router.push("/configurator");
            },
            configureYes() {
                this.rator.reset();
                this.$router.push("/configurator");
            },
            async load() {
                this.loading = true;
                this.variant = null;

                const handle = this.$route.params.handle;
                const id = this.$route.params.id;
                const data = await api.product(handle);

                if (data) {
                    this.view = new ProductView(data);
                    this.variant = this.view.select(id || data.variant.id);
                    Gtm.productDetailView(this.$gtm, data);
                } else {
                    this.title = "Not Found";
                    this.body = "<p>The product you have requested could not be found.</p>";
                }

                this.loading = false;
            },
            addToCart() {
                if (this.isConfigured)
                    return;

                this.cart.add(this.variant, this.qty);
                this.qty = 1;
                this.top.notification = "In den Warenkorb gelegt";
                Gtm.addToCart(this.$gtm, this.variant, this.qty);
            },
            plus() {
                this.qty = Math.min(99, this.qty + 1);
            },
            minus() {
                this.qty = Math.max(1, this.qty - 1);
            },
            truncateDescription(value) {
                if(value.length > 350) {
                    return `${value.substr(0, 350)}... <a class="tw-text-primary" href='#description'>Mehr lesen</a>`
                } else {
                    return value;
                }
            }
        },
        watch: {
            "$route.params.handle"() {
                if (this.$route.name === "Product")
                    this.load();
            }
        },
        computed: {
            isConfigured() {
                const item = this.cart.items.find(it => it.id == this.variant?.id);

                return (item?.mode == "vespa");
            },
            isAddon() {
                const tags = this.variant?.product.tags || [];

                return tags.includes("Addon");
            },
            isVespa() {
                return (this.isConfigured && this.variant.product.tags.includes("Bike"));
            }
        }
    };
</script>

<style lang="scss" src="@/assets/scss/views/Product.scss"></style>
