<template>
    <div class="colors">
        <div class="layout-row center">
            <div class="title flex">{{ data.name }}</div>
            <div class="color-name">{{ data.values.find(v => v.selected)?.text }}</div>
        </div>
        <div class="layout-row palette">
            <div class="color-circle" v-for="(v, i) in data.values" :key="i" @click.stop="pick(v)" :style="style(v)">
                <div v-if="v.selected">
                    <img v-svg-inline src="@/assets/img/icons/check-white.svg" class="icon-check" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from "@/services/Config";

    export default {
        props: ["data"],
        emits: ["pick"],
        methods: {
            pick(value) {
                this.$emit("pick", value);
            },
            style(value) {
                return config.colors[value?.text];
            }
        },
    };
</script>
