<template>
    <div>
        <h2 class="mt-40 mb-40">Bitte fülle das nachfolgende Formular für deinen Leasingantrag aus</h2>
        <form class="leasingForm leasing-form-wrapper" @submit.prevent="next">
            <div class="input-wrapper large">
                <span>Geburtsdatum</span>
                <Datepicker v-model="leasing.birthday" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper large">
                <span>Wohnhaft an der angegeben Adresse seit</span>
                <Datepicker v-model="leasing.residentAtAddressSince" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper">
                <span>Telefonnummer</span>
                <Phone v-model="leasing.phone" required />
            </div>
            <div class="input-wrapper">
                <span>Zivilstand</span>
                <Dropdown v-model="leasing.maritalStatus" :options="maritalStatusOptions" placeholder="Zivilstand" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper">
                <span>Nationalität</span>
                <Dropdown v-model="leasing.nationality" :options="nationalityOptions" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper" v-if="leasing.nationality != 'Schweiz'">
                <span>Aufenthaltsgenehmigung</span>
                <Dropdown v-model="leasing.residencePermit" :options="residencePermitOptions" placeholder="Aufenthaltsgenehmigung" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper" v-if="leasing.nationality != 'Schweiz'">
                <span>Aufenthaltsgenehmigung gültig bis</span>
                <Datepicker v-model="leasing.residencePermitUntil" />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper">
                <span>Wohnverhältnis</span>
                <Dropdown v-model="leasing.housingSituation" :options="housingSituationOptions" placeholder="Wohnverhältnis" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper">
                <span>Beruf</span>
                <input placeholder="z.B. Maurer" v-model="leasing.job" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper">
                <span>Arbeitgeber</span>
                <input placeholder="z.B. Implenia" v-model="leasing.employer" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper">
                <span>Angestellter seit</span>
                <Datepicker v-model="leasing.employedSince" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper">
                <span>monatliches Salär (brutto)</span>
                <input v-model="leasing.monthlySalary" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="wrapper-row__input">
                <div class="input-wrapper">
                    <span>monatliches Wohnkosten (inkl. NK)</span>
                    <input v-model="leasing.monthlyHousingCost" required />
                    <span class="input-invalid">Field is required</span>
                </div>
                <div class="input-wrapper">
                    <span>Anzahl unterstützungsbedürftige Personen im Haushalt</span>
                    <input v-model="leasing.householdPeopleSupportNumber" required />
                    <span class="input-invalid">Field is required</span>
                </div>
            </div>
            <div class="input-wrapper input-half">
                <span>Feste Auslagen/Monat für Arbeitsweg (mind. CHF 250)</span>
                <input v-model="leasing.monthlyForCommuting" required />
                <span class="input-invalid">Field is required</span>
            </div>
            <div class="input-wrapper photos-row">
                <p>Foto ihres Ausweises oder Pass</p>
                <div class="images-row">
                    <div :class="['image-upload', { 'has-img': leasing.images[0] }]">
                        <label>
                            <span class="add-icon">
                                <img src="@/assets/img/icons/plus-white.svg" alt="add image" />
                            </span>
                            <input ref="image1" type="file" accept=".jpg, .jpeg" @change="uploadImage($event, 0)" />
                        </label>
                        <div class="loaded-img">
                            <span class="delete-icon" @click="removeImage(0)">
                                <img src="@/assets/img/icons/plus-white.svg" alt="add image"/>
                            </span>
                            <img class="uploaded" :src="leasing.images[0]" v-if="leasing.images[0]" />
                        </div>
                    </div>

                    <div :class="['image-upload', { 'has-img': leasing.images[1] }]" v-if="leasing.images[0]">
                        <label>
                            <span class="add-icon">
                                <img src="@/assets/img/icons/plus-white.svg" alt="add image" />
                            </span>
                            <input ref="image2" type="file" accept=".jpg, .jpeg" @change="uploadImage($event, 1)" />
                        </label>
                        <div class="loaded-img">
                            <span class="delete-icon" @click="removeImage(1)">
                                <img src="@/assets/img/icons/plus-white.svg" alt="add image"/>
                            </span>
                            <img class="uploaded" :src="leasing.images[1]" v-if="leasing.images[1]" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons__wrapper">
                <button class="button-green no-fix-w">Zum Bestellabschluss</button>
            </div>
        </form>
    </div>
</template>

<script>
    import config from "@/services/Config";
    import Gtm from "@/services/GtmHelper";

    export default {
        inject: ["leasing"],
        data: () => ({
            housingSituationOptions: config.housingSituationOptions,
            residencePermitOptions: config.residencePermitOptions,
            maritalStatusOptions: config.maritalStatusOptions,
            nationalityOptions: config.countries,
        }),
        methods: {
            next() {
                Gtm.additionalStep(this.$gtm, 7, 'Payment')
                this.$router.push("/checkout/review");
            },
            async uploadImage(e, index) {
                const file = (e.target.files || e.dataTransfer.files || [])[0];

                if (file) {
                    const reader = new FileReader();

                    reader.onload = () => {
                        this.leasing.images[index] = reader.result;
                    };

                    reader.readAsDataURL(file);
                }

                this.$refs.image1.value = "";

                if (this.$refs.image2)
                    this.$refs.image2.value = "";
            },
            removeImage(index) {
                this.leasing.images[index] = null;

                if (this.leasing.images[0] == null && this.leasing.images[1] != null) {
                    this.leasing.images[0] = this.leasing.images[1];
                    this.leasing.images[1] = null;
                }
            }
        }
    }
</script>
