import { reactive, watch } from "vue";

const state = reactive({
    cart: false,
    modal: false,
    overlay: null,
    menu: null,
    notification: null,
    mobileMenu: false,
    userMenu: false
});

const notifyId = null;

watch(state, function(newState) {
    if (newState.notification) {
        clearTimeout(notifyId);
        
        setTimeout(function() {
            state.notification = null;
        }, 2000);
    }
});

export default state;