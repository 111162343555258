import ga from "universal-ga";

const trackingId = process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID;

const init = () => {
    if (trackingId)
        ga.initialize(trackingId);
};

const page = url => {
    if (!trackingId)
        return;

    ga.pageview(url);
};

export default {
    init,
    page,
};