<template>
    <div class="step-counter">
        <span>3</span>
        Schutz &amp; Vorsorge
    </div>
    <div class="checkout-form">
        <h2 class="mw-500">Beugen Sie Schäden vor und sorgen Sie vor mit unserem Servicepaket.</h2>
        <div class="row-cards guarantee-mod">
            <div class="card card-center card-with-btn" v-for="(p, i) in warranty" :key="i">
                <div class="card-inner lines-bg">
                    <div class="card-logo">
                        <img :src="p.image" alt=""/>
                    </div>
                    <div class="card-title">{{ p.title }}</div>
                    <div class="vertical-content-btw">
                        <div v-html="p.description"></div>
                        <button :class="['button-green button-green-bordered', { choosen: active(p) }]" @click="toggle(p)">
                            <span v-if="active(p)"></span>
                            {{ active(p) ? 'Entfernen' : p.action }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons__wrapper" v-if="!status.ready">
            <button class="button-green" @click="next">{{ empty ? "Ohne Schutz fortfahren" : "Weiter" }}</button>
        </div>
        <div class="buttons__wrapper" v-if="status.ready">
            <button class="button-green" @click="$router.push('/checkout/review')">
                <div class="hover-arr">
                    <img src="@/assets/img/icons/save-white.svg" alt=""/>
                    <img src="@/assets/img/icons/save-green.svg" class="hovered" alt=""/>
                </div>
                Zum Bestellabschluss
            </button>
        </div>
        <div @click="prev" class="row-link">Zurück zum vorherigen Schritt</div>
    </div>
</template>

<script>
    import Gtm from "@/services/GtmHelper";

    export default {
        inject: ["warranty", "cart", "status"],
        methods: {
            toggle(p) {
                if (this.active(p))
                    this.cart.remove(p)
                else
                    this.cart.add(p)
            },
            prev() {
                this.$router.push("/checkout/shipping");
            },
            next() {
                Gtm.additionalStep(this.$gtm, 3, 'Warranty')
                this.$router.push("/checkout/insurance");
            },
            active(p) {
                return this.cart.items.some(it => it.id == p.id);
            }
        },
        computed: {
            empty() {
                return this.warranty.every(p => !this.active(p));
            },
        }
    }
</script>
