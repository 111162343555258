<template>
    <div class="account">
        <div class="account__menu">
            <div class="account__menu-logo">
                <router-link class="back mobile" to="/">
                    <div class="header-arrow__left">
                        <div>
                            <img v-svg-inline src="@/assets/img/icons/arrow_left.svg" />
                        </div>
                    </div>
                    <span>Zum Shop</span>
                </router-link>
                <router-link to="/">
                    <img src="@/assets/img/motolino-logo-stroke.svg" />
                </router-link>
            </div>
            <div class="account__menu-inner">
                <div class="account__menu-scroll">
                    <div class="account__menu-list">
                        <ul>
                            <li>
                                <router-link to="/account/my-vespa" :class="{ active: tab == 'my-vespa' }">Meine Vespa</router-link>
                            </li>
                            <li>
                                <router-link to="/account/profile" :class="{ active: tab == 'profile' }">Meine Daten</router-link>
                            </li>
                            <li>
                                <router-link to="/account/orders" :class="{ active: tab == 'orders' }">Meine Bestellungen</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <router-link to="/" class="back">
                    <div>
                        <img v-svg-inline src="@/assets/img/icons/arrow_left.svg" />
                    </div>
                    <span>Zum Shop</span>
                </router-link>
            </div>
        </div>

        

        <div class="account__content">
            <Spinner v-if="loading" />

            <div v-if="!loading">
                <profile v-if="tab == 'profile'" :data="data" />
                <my-vespa v-if="tab == 'my-vespa'" :data="data" />
                <orders v-if="tab == 'orders' && !id" :data="data" />
                <order v-if="tab == 'orders' && id" :id="id" :data="data" />
            </div>
        </div>
      </div>
</template>

<script>
    import Profile from "@/components/Account/Profile";
    import MyVespa from "@/components/Account/MyVespa";
    import Orders from "@/components/Account/Orders";
    import Order from "@/components/Account/Order";

    export default {
        components: { Profile, MyVespa, Orders, Order },
        inject: ["user"],
        data: () => ({
            data: null,
            loading: true,
        }),
        mounted() {
            this.load();
        },
        methods: {
            async load() {
                this.loading = true;

                if (this.user.authed.value) {
                    this.data = await this.user.account();

                    this.loading = false;
                } else
                    this.$router.replace("/");                
            },
            logout() {
                this.user.logout();
                this.$router.push("/");
            }
        },
        computed: {
            tab() {
                return this.$route.params.tab;
            },
            id() {
                return this.$route.params.id;
            },
        }
    };
</script>

<style lang="scss" src="@/assets/scss/views/Account.scss"></style>