<template>
    <div :class="['dropdown', { open: open }]">
        <div class="dropdown-title" @click="toggle">
            {{ title }}
        </div>
        <div class="dropdown-content" v-if="open">
            <div :class="['dropdown-item layout-row', { selected: i == index }]" v-for="(opt, i) in values" :key="i" @click="pick(i)">
                <div class="dropdown-dot">
                    <div>
                        <div>
                            <img v-svg-inline src="@/assets/img/icons/check-white.svg"/>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <span>{{ opt.label }}</span>
                </div>
                <div class="dropdown-price" v-if="opt.sublabel">{{ opt.sublabel }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["options", "modelValue", "title"],
        emits: ["change", "update:modelValue"],
        data: () => ({
            open: true,
            index: -1,
        }),
        methods: {
            pick(index) {
                this.index = index == this.index ? -1 : index;

                const value = this.index > -1 ? this.options[index] : null;
                
                this.$emit("update:modelValue", value);
                this.$emit("change", value);
            },
            toggle() {
                this.open = !this.open;
            }
        },
        watch: {
            modelValue(newValue) {
                this.index = this.options.indexOf(newValue);
            }
        },
        computed: {
            values() {
                if (!this.options)
                    return [];

                return this.options.map(opt => {
                    if (opt instanceof Object)
                        return opt;
                    else
                        return { label: opt };
                });
            }
        }
    };
</script>