<template>
    <div class="tw-flex tw-flex-col tw-w-full tw-max-w-screen-lg xl:tw-max-w-screen-xl tw-px-5 lg:tw-px-0 md:tw-mx-auto tw-mb-10 cart-page">
        <h1 class="checkout__header cart-page__header">Warenkorb</h1>

        <div v-if="!empty">
            <div class="cart__item" v-for="(it, i) in cart.items" :key="i">
                <div class="cart__item__thumbnail">
                    <img :src="it.image"/>
                </div>
                <div class="cart__item__details">
                    <router-link :to="it.url" class="cart__item-title">
                        {{ it.title }}
                        <div class="cart__item__details-variantx" style="opacity:.5">{{ it.subtitle }}</div>
                    </router-link>
                    <div class="cart__item__details__price">{{ price(it.price, 2) }}</div>

                    <div className="mob-view">
                        <div class="cart__item__quantity">
                            <div :class="['action', { disabled: it.qty <= 1 }]" @click="cart.remove(it)" style="max-height: 30px; height: 100%;">
                                <img src="@/assets/img/icons/minus-dark.svg" />
                            </div>
                            <div class="quantity-number">
                                {{ it.qty }}
                            </div>
                            <div class="action" @click="cart.add(it)" style="max-height: 30px; height: 100%;">
                                <img src="@/assets/img/icons/plus-dark.svg" />
                            </div>
                        </div>
                        <div class="cart__item__total">
                            <div>Summe</div>
                            <div class="price">{{ price(it.price * it.qty, 2) }}</div>
                        </div>
                    </div>
                </div>

                <div class="cart__item__quantity desktop-view">
                    <div :class="['action', { disabled: it.qty <= 1 }]" @click="cart.remove(it)" style="max-height: 30px; height: 100%;">
                        <img src="@/assets/img/icons/minus-dark.svg" />
                    </div>
                    <div class="quantity-number">
                        {{ it.qty }}
                    </div>
                    <div class="action" @click="cart.add(it)" style="max-height: 30px; height: 100%;">
                        <img src="@/assets/img/icons/plus-dark.svg" />
                    </div>
                </div>

                <div class="cart__item__total desktop-view">
                    <div>Summe</div>
                    <div class="price">{{ price(it.price * it.qty, 2) }}</div>
                </div>

                <div class="cart__item__remove" @click="clear(it);">
                    <img v-svg-inline src="@/assets/img/x.svg" class="cart__item__remove-icon" />
                </div>
            </div>

            <div class="cart__total" v-if="!empty">
                <div>Zwischensumme</div>
                <div class="cart__total__price">{{ price(subtotal, 2) }}</div>
            </div>

            <div class="cart-page__checkout-action wrap-buttons" v-if="!empty">
                <router-link to="/">
                    <button class="secondary">Weiter einkaufen</button>
                </router-link>
                <router-link to="/checkout">
                    <button>Zur Kasse</button>
                </router-link>
            </div>
        </div>
        <div class="cart-page__empty" v-if="empty">
            <h4>Dein Warenkorb ist leer</h4>
            <p>
                Du hast noch keine Produkte in deinen Warenkorb gelegt.
                Bei uns findest du bestimmt etwas passendes.
            </p>
            <div class="cart-page__empty__action">
                <router-link to="/">
                    <button class="secondary">Weiter einkaufen</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { price } from "@/services/Formatters";
    import Gtm from "@/services/GtmHelper";

    export default {
        inject: ["cart"],
        methods: {
            price,
            clear(variant)  {
                this.cart.clear(variant);
                Gtm.removeFromCart(this.$gtm, variant)
            }
        },
        computed: {
            subtotal() {
                return this.cart.items.reduce((a, b) => a + b.price * b.qty, 0);
            },
            empty() {
                return (this.cart.items.length == 0);
            }
        }
    };
</script>


<style src="@/assets/scss/views/Cart.scss" lang="scss"></style>
