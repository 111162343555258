<template>
    <div class="tw-flex tw-flex-col tw-w-full tw-max-w-screen-lg xl:tw-max-w-screen-xl tw-px-5 lg:tw-px-0 md:tw-mx-auto tw-mb-10">
        <ul class="breadcrumbs">
            <li>
                <router-link to="/">Pages</router-link>
            </li>
            <li v-if="!loading">
                <router-link to="/">{{ title }}</router-link>
            </li>
        </ul>

        <Spinner v-if="loading"/>

        <div class="article-page__container" v-if="!loading">
            <h1>{{ title }}</h1>
            <div class="article-page__content" v-html="body"></div>
        </div>
    </div>
</template>

<script>
import { api } from '@/services/Query';

export default {
        data: () => ({
            loading: true,
            title: null,
            body: null
        }),
        created() {
            this.load();
        },
        methods: {
            async load() {
                this.loading = true;

                const handle = this.$route.params.handle;
                const data = await api.page(handle);

                if (data) {
                    this.title = data.title;
                    this.body = data.body;
                } else {
                    this.title = "Not Found";
                    this.body = "<p>The page you have requested could not be found.</p>";
                }

                this.loading = false;
            }
        },
        watch: {
            $route() {
                this.load();

                window.scrollTo(0, 0);
            }
        }
    };
</script>

<style src="@/assets/scss/views/Article.scss" lang="scss"></style>
