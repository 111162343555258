<template>
    <teleport to="#overlay">
        <div class="overlay__right">
            <div class="login">
                <div class="overlay__header">
                    <div class="overlay__header-text">Dein motolino Account</div>
                    <span @click="close" class="overlay__header__close-icon" >
                        <img v-svg-inline src="@/assets/img/x.svg"/>
                    </span>
                </div>
                <div class="login__tabs">
                    <span :class="{ 'active-tab': tab == 'login' }" @click="tab = 'login'">Einloggen</span>
                    <span :class="{ 'active-tab': tab == 'register' }" @click="tab = 'register'">Registrieren</span>
                    <hr />
                </div>
                <div class="login__content">
                    <transition-group name="appear">
                        <form v-if="tab == 'login'" @submit.prevent="login">
                            <div class="login-section">
                                <div class="input">
                                    <span class="input__label">Ihre E-Mail Adresse</span>
                                    <input type="email" v-model="email" required :class="['input__field', { 'input__field--error': loginError }]" />
                                    <span class="input__error" v-if="loginError">{{ loginError }}</span>
                                </div>
                                <div class="input">
                                    <span class="input__label">Ihr Passwort</span>
                                    <input type="password" v-model="password" class="input__field" />
                                </div>
                                <div class="login-form__button" @click="login">
                                    <button class="b-line" :disabled="loading">{{ loading ? "Lädt..." : "Einloggen" }}</button>
                                </div>
                                <div class="login__content__password-reminder">
                                    <p>
                                        <span @click="$emit('reset')">Passwort vergessen</span>
                                    </p>
                                </div>
                            </div>
                        </form>
                        <form v-if="tab == 'register'" @submit.prevent="register">
                            <div class="register-section">
                                <div class="input">
                                    <span class="input__label">Ihre E-Mail Adresse</span>
                                    <input type="email" v-model="email" required :class="['input__field', { 'input__field--error': registerError }]" />
                                    <span class="input__error" v-if="registerError">{{ registerError }}</span>
                                </div>
                                <div class="input">
                                    <span class="input__label">Ihr Passwort</span>
                                    <input type="password" v-model="password" minlength="6" required class="input__field" />
                                </div>
                                <div class="input">
                                    <span class="input__label">Passwort wierderholen</span>
                                    <input type="password" v-model="passwordRepeat" minlength="6" required class="input__field" />
                                </div>
                                <div class="login-form__button">
                                    <button class="b-line" :disabled="loading">{{ loading ? "Lädt..." : "Registrierung" }}</button>
                                </div>
                            </div>
                        </form>
                    </transition-group>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        inject: ["user", "top"],
        emits: ["reset"],
        data: () => ({
            loading: false,
            email: null,
            password: null,
            passwordRepeat: null,
            tab: "login",
            loginError: null,
            registerError: null,
        }),
        methods: {
            async login() {
                this.loading = true;

                const result = await this.user.login(this.email, this.password);

                if (result.success) {
                    this.top.overlay = null;
                    this.top.notification = "Du bist jetzt eingeloggt"
                }
                else
                    this.loginError = result.message;

                this.loading = false;
            },
            async register() {
                this.loading = true;

                const result = await this.user.register(this.email, this.password);

                if (result.success) {
                    this.top.overlay = null;
                    this.top.notification = "Du bist jetzt eingeloggt"
                }
                else
                    this.registerError = result.message;

                this.loading = false;
            },
            close() {
                this.top.overlay = null;
            }
        }
    }
</script>

<style src="@/assets/scss/views/Login.scss" lang="scss"></style>