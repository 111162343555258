<template>
    <div class="my-vespa">
        <div class="back-btn" @click="$emit('cancel')">
            <div>
                <img v-svg-inline src="@/assets/img/icons/arrow_left.svg"/>
            </div>
            Zurück
        </div>

        <h1>{{ data.title }}</h1>
        <!-- <div class="vespa-id">{vespaById.vehicleNumber && `Rahmennummer: ${vespaById.vehicleNumber}`}</div> -->
        <div class="my-vespa__grid">
            <div class="my-vespa__slider">
                <Carousel :items="[data.image]" />
            </div>
            <div class="my-vespa__info-block">
                <div>
                    <img v-svg-inline src="@/assets/img/icons/alarm.svg" />
                </div>
                <h3>Motolino Notfall Service</h3>
                <p><a href="tel:+41 44 558 57 58">+41 44 558 57 58</a></p>
            </div>
            <div class="my-vespa__info-block">
                <h3>Service Daten</h3>
                <div class="info-row">
                    <span>Vespa gekauft am:</span>
                    <div>{{ date(data.date) }}</div>
                </div>
                <div class="info-row">
                    <span>Nächster empfohlener Service:</span>
                    <div class="loader-x" v-if="warrantyLoading"></div>
                    <div v-if="!warrantyState && !warrantyLoading">{{ date(data.serviceDate) }}</div>
                    <div v-for="(s, i) in service" :key="i" class="service-status">
                        <div>{{ s.title }} - Service</div>
                        <div class="pill-x" v-if="s.status == 'pending'">OFFEN</div>
                        <div class="pill-x pill-green" v-if="s.status != 'pending'">ERLEDIGT</div>
                    </div>
                    <span>oder nach den ersten 1000 km</span>
                </div>
                <div class="info-row">
                    <span>Nach dem ersten Service empfehlen wir Services im Jahrestakt oder nach jeweils 5000 km</span>
                </div>
            </div>
            <div class="my-vespa__info-block">
                <h3>Die mobile Werkstatt</h3>
                <ul class="dotted">
                    <li>Service an jedem beliebigen Ort zur gewünschten Zeit</li>
                    <li>Preisgünstigste Reparatur- &amp; Servicearbeiten</li>
                    <li>Meisterwerkstatt - Ausgebildete Mechaniker mit modernsten Diagnosegeräten</li>
                    <li>Frühbucherrabat</li>
                </ul>
            </div>
            <div class="my-vespa__info-block">
                <h3>Deine Service-Kontakte</h3>
                <div class="location-input">
                    <Dropdown v-model="location" :options="locations" @change="changeLocation" />
                </div>
                <p v-if="address.city">
                    Motolino {{ address.city }}<br/>
                    {{ address.street }}<br />
                    {{ address.zip }} {{ address.city }}
                </p>
                <div class="my-vespa__icon">
                    <img v-svg-inline src="@/assets/img/icons/support.svg" />
                </div>
                <a class="bold" :href="`tel:${address.phone}`">{{ address.phone }}</a>
            </div>
            <div class="my-vespa__info-block service-block">
                <div>
                    <h3>Service buchen</h3>
                    <p>Online Termin Buchung</p>
                    <a href="https://connect.shore.com/bookings/motolino-store-zurich-417567e6-7c54-41a7-9c37-62061c331e5f/services?locale=de&amp;origin=standalone" target="_blank">
                        <button class="uppercase">Terminwahl</button>
                    </a>
                </div>
                <div >
                    <h3>Garantie Aktivierung</h3>
                    <div class="loader-x" v-if="warrantyLoading"></div>
                    <div v-if="!warrantyLoading">
                        <button @click="toggleWarranty" :disabled="warrantyState !== false">GARANTIE AKTIVIEREN<p v-if="warrantyState">(Bereits aktiviert am: {{ warrantyActivated }})</p></button>
                    </div>
                </div>
                <Dialog3 title="Fehler aufgetreten"
                         yes="Erneut versuchen"
                         v-model="error"
                         @yes="() => { toggleWarranty() }"
                >
                    <p>
                        Leider ist ein Fehler aufgetretten. Bitte versuchen Sie es erneut.
                    </p>
                </Dialog3>
            </div>
        </div>
        <h2 class="h1">Dein Vespa Ratgeber</h2>
        <div class="my-vespa-blog">
            <Spinner v-if="loading" />
            <div class="blog-info" :style="{ 'background-image': `url(&quot;${p.feature_image}&quot)` }" v-for="(p, i) in news" :key="i">
                <a target="_blank" :href="p.url" class="description" @mouseenter="p.active = true" @mouseleave="p.active = false">
                    <h3>{{ p.title }}</h3>
                    <transition name="appear" :duration="{enter:200,leave:0}">
                        <p v-if="p.active">
                            {{ p.custom_excerpt ? p.custom_excerpt : p.excerpt?.split(" ").slice(0, 8).join(" ") }}
                        </p>
                    </transition>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { date } from "@/services/Formatters";
    import config from "@/services/Config";

    export default {
        inject: ["user"],
        props: ["data"],
        data: () => ({
            location: null,
            address: {},
            loading: false,
            news: [],
            error: null,
            warrantyState: null,
            warrantyActivated: null,
            service: [],
            warrantyLoading: false
        }),
        mounted() {
            this.address = config.stores[0];
            this.location = this.address.value;
            this.loadNews();
        },
        async created() {
            this.warrantyLoading = true;
            try {
                const state = await this.user.getWarrantyState({orderId: this.data.orderId});

                this.updateWarranty(state);
            } catch(err) {
                console.log(err);
                this.warrantyLoading = false;
            }
        },
        methods: {
            date,
            changeLocation(a) {
                this.address = this.locations.find(l => l.value == a);
            },
            updateWarranty(state) {
                this.warrantyState = state?.activatedDate != null;
                this.warrantyActivated = state?.activatedDate;
                this.service = state?.service ?? [];
                this.warrantyLoading = false;
            },
            async loadNews() {
                this.loading = true;

                try {
                    const response = await fetch(`https://news.motolino.ch/ghost/api/v3/content/posts/?key=a077a064e3742714ce7631d396`);
                    this.news = (await response.json()).posts.slice(0, 3).map(p => ({
                        ...p,
                        active: false,
                    }))
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading = false;
                }
            },
            async toggleWarranty() {
                let result = {}
                try {
                    this.warrantyLoading = true;
                    result = (await this.user.activateWarranty({orderId: this.data.orderId}));
                    this.updateWarranty(result);
                } catch(error) {
                    result.error = error;
                }

                this.error = result.error;
            }
        },
        computed: {
            locations() {
                return [...config.stores,
                {
                    value: "mobile",
                    label: "Mobile Werkstatt",
                    phone: "+41615152323",
                }];
            }
        }
    }
</script>

<style>
    .loader-x {
        border: 4px solid #f3f3f3; /* Light grey */
        border-top: 4px solid #72ad77; /* Blue */
        border-radius: 50%;
        width: 48px;
        height: 48px;
        animation: spin 1s linear infinite;
    }

    .pill-x {
        background-color: #DEEBFF;
        color: #0747a6;
        display: inline-block;
        box-sizing: border-box;
        max-width: 100%;
        padding: 2px 5px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        vertical-align: baseline;
    }
    .pill-green {
        background-color: #E3FCEF;
        color:#006644;
    }

    .service-status {
        display: flex;
        align-items: center;
        margin: 4px 0;

    }
    .service-status > :first-child {
        margin-right: 10px;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>