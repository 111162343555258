<template>
    <div>
        <div v-if="!leasing.acceptedRate">
            <p class="above-title mt-40">
                Profitieren Sie vom motolino Super Leasing. Eine Zusammenarbeit mit der ALPHERA Financial Services (BMW Group) exklusiv für motolino Kunden.
                Keine Sorge: Sollte Ihr Leasing abgelehnt werden werden keinerlei Gebühren oder Stornokosten fällig.
            </p>
            <h2 class="mt-40 mb-40">Wählen Sie Ihr gewünschtes Leasing</h2>
            <p>Effektiver Jahreszins: {{ rate }}%</p>
            <div class="slider-section">
                <h4>Laufzeit</h4>
                <Slider v-model="leasing.months" :options="monthsOptions"  />
            </div>
            <div class="slider-section">
                <h4>Anzahlung</h4>
                <Slider v-model="leasing.deposit" :options="depositOptions"  />
            </div>
            <div class="slider-section">
                <h4>Restwert</h4>
                <Slider v-model="leasing.residual" :options="residualOptions"  />
            </div>

            <div class="result-count">
                <p>Ihre monatliche Rate</p>
                <div class="price">{{ price(monthly, 2) }}</div>
            </div>
            <div class="buttons__wrapper">
                <button class="button-green" @click="accept">Mit dieser Leasingrate fortfahren</button>
            </div>
            <div @click="prev" class="row-link">Zurück zum vorherigen Schritt</div>
        </div>
        <LeasingForm v-if="leasing.acceptedRate" />
    </div>
</template>

<script>
    import Slider from "./_Slider";
    import LeasingForm from "./_LeasingForm";
    import { price } from "@/services/Formatters";

    export default {
        components: { Slider, LeasingForm },
        inject: ["leasing", "cart"],
        created() {
            if (this.cart.items.length === 0 || Number.isNaN(this.cart.items.reduce((a, b) => a + b.price * b.qty, 0) ))
                this.$router.push("/cart");
        },
        data: () => ({
            rate: 5.95,
            monthsOptions: [
                { name: "60 Monate", value: 60 },
                { name: "48 Monate", value: 48 },
                { name: "36 Monate", value: 36 },
                { name: "24 Monate", value: 24 },
                { name: "12 Monate", value: 12 },
            ],
            depositOptions: [
                { name: "CHF 0", value: 0 },
                { name: "CHF 500", value: 500 },
                { name: "CHF 1000", value: 1000 },
                { name: "CHF 1500", value: 1500 },
                { name: "CHF 2000", value: 2000 },
                { name: "CHF 2500", value: 2500 },
                { name: "CHF 3000", value: 3000 },
            ],
            residualOptions: [
                { name: "CHF 1000", value: 1000 },
                { name: "CHF 1500", value: 1500 },
                { name: "CHF 2000", value: 2000 },
            ]
        }),
        methods: {
            price,
            prev() {
                this.$router.push("/checkout/delivery");
            },
            accept() {
                this.leasing.acceptedRate = this.monthly;
            }
        },
        computed: {
            monthly() {
                const totalPrice = this.cart.items.reduce((a, b) => a + b.price * b.qty, 0);
                const leasingPrice = totalPrice - this.leasing.residual - this.leasing.deposit;
                const years = this.leasing.months / 12;
                const interests = leasingPrice * (this.rate / 100) * years;

                return (leasingPrice + interests) / this.leasing.months;
            }
        }
    }
</script>
