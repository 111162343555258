import Cart from "@/state/Cart";

const addToCart = (gtm, variant, qty) => {
    if (gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "addToCart",
            ecommerce: {
                currencyCode: "CHF",
                add: {
                    // 'add' actionFieldObject measures.
                    products: [
                        {
                            //  adding a product to a shopping cart.
                            name: variant.handle,
                            id: variant.id,
                            price: variant.price,
                            brand: variant.title,
                            category: variant.productType,
                            variant: variant.selectedOptionsMap?.Farbe ?? null,
                            quantity: qty
                        }
                    ]
                }
            }
        });
    }
};

const removeFromCart = (gtm, variant) => {
    if (gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "removeFromCart",
            ecommerce: {
                add: {
                    // 'add' actionFieldObject measures.
                    products: [
                        {
                            //  adding a product to a shopping cart.
                            name: variant.handle,
                            id: variant.id,
                            price: variant.price,
                            brand: variant.title,
                            category: variant.productType,
                            variant: variant.selectedOptionsMap?.Farbe ?? null,
                            quantity: variant.qty
                        }
                    ]
                }
            }
        });
    }
};

const productDetailView = (gtm, data) => {
    if (gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            ecommerce: {
                detail: {
                    actionField: {},
                    products: [
                        {
                            name: data.handle,
                            id: data.id,
                            price: data.variant.price,
                            brand: data.title,
                            category: data.productType
                        }
                    ]
                }
            }
        });
    }
};

const checkoutStart = (gtm, products) => {
    if (gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "checkout",
            ecommerce: {
                checkout: {
                    actionField: { step: 1, option: "Start" },
                    products: products.map(p => {
                        return {
                            name: p.title,
                            id: p.id,
                            price: p.price,
                            brand: p.title,
                            category: p.productType,
                            quantity: p.qty
                        };
                    })
                }
            }
        });
    }
};

const additionalStep = (gtm, step, option) => {
    if (gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "checkoutOption",
            ecommerce: {
                checkout_option: {
                    actionField: { step: step, option: option }
                }
            }
        });
    }
};

const checkoutFinish = (gtm, orderId) => {
    const orderValue = Cart.items.reduce((item, total) => total + item.price, 0);
    console.log("orderValue", orderValue);
    console.log(Cart.items);
    console.log("enabled: " + gtm.enabled());
    if (gtm.enabled()) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            ecommerce: {
                currencyCode: "CHF",
                purchase: {
                    actionField: {
                        id: orderId,
                        revenue: orderValue
                    },
                    products: Cart.items.map(p => {
                        return {
                            name: p.title,
                            id: p.id,
                            price: p.price,
                            brand: p.title,
                            category: p.productType,
                            quantity: p.qty
                        };
                    })
                }
            }
        });
    }
};

export default {
    addToCart,
    removeFromCart,
    productDetailView,
    checkoutStart,
    additionalStep,
    checkoutFinish
};
