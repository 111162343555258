import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, gql } from 'apollo-boost';
import config from "@/services/Config";
import user from "@/state/User";
import CustomerAccessTokenRenew from "@/gql/CustomerAccessTokenRenew.gql";

const uri = config.shopify.graphql;
const token = config.shopify.token;
const http = new HttpLink({ uri });
const auth = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            "X-Shopify-Storefront-Access-Token": token
        }
    });

    return forward(operation);
});

const client = new ApolloClient({
    link: auth.concat(http),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
    }
});

const reduce = (result, p) => {
    p = p || "";

    if (result instanceof Array || !(result instanceof Object))
        return result;

    for (let key of Object.keys(result)) {
        const value = result[key];

        if (value && value.edges) {
            result[key] = value.edges.map(e => {
                reduce(e.node, p + "." + key);
                
                return e.node;
            });
        } else {
            reduce(value, p + "." + key);

            result[key] = value;
        }
    }

    return result;
};

const getToken = async () => {
    if (!user.authed.value)
        return null;
    else if (user.auth.expiresAt > new Date())
        return user.auth.token;
    else {
        const newToken = await renewToken();

        if (newToken)
            user.changeAccessToken(newToken);
        else
            user.logout();

        return user.auth.token;
    }
};

const renewToken = async () => {
    if (!user.auth.token)
        return null;

    const result = await $m(CustomerAccessTokenRenew, { token: user.auth.token });

    return result.customerAccessTokenRenew;
};

const $q = async (q, data, fragments) => {
    const query = gql([q, ...(fragments || [])].join("\n"));
    const variables = {
        ...data,
        token: data?.token || (await getToken())
    };

    const options = {
        query,
        variables,
    };

    const result = (await client.query(options));

    return reduce(result.data);
};

const $m = async (q, data, fragments) => {
    const mutation = gql([q, ...(fragments || [])].join("\n"));
    const variables = {
        ...data,
        token: data?.token || (await getToken())
    };

    const options = {
        mutation,
        variables,
    };

    const result = (await client.mutate(options));

    return reduce(result.data);
};

export { $q, $m };