import { ref, readonly } from "vue";
import { stored } from "@/state/Local";
import configurator from "./Configurator";

const state = {
    items: stored("cart", []),
    open: ref(false),
    qty: ref(0)
};

state.qty.value = state.items.reduce((a, b) => a + b.qty, 0);

const add = (variant, qty, mode) => {
    let item = state.items.find(it => it.id == variant.id);

    if (item && item.mode !== mode) {
        clear(variant);

        item = null;
    }

    if (!item) {
        item = {
            id: variant.id,
            title: variant.title,
            subtitle: variant.subtitle,
            image: variant.image,
            price: variant.price,
            tags: variant.product?.tags || [],
            url: variant.url,
            qty: 0,
            mode
        };

        state.items.push(item);
    }

    item.qty += qty || 1;
    state.qty.value += qty || 1;
};

const remove = variant => {
    const item = state.items.find(it => it.id == variant.id);

    configurator.remove(variant.id);

    if (item && item.qty > 1) {
        item.qty--;
        state.qty.value--;
    } else clear(variant);
};

const empty = () => {
    [...state.items].forEach(clear);
};

const toggle = value => {
    if (value === undefined) state.flyout.value = !state.flyout.value;
    else state.flyout.value = value;
};

const clearMode = mode => {
    const newItems = [];

    for (let it of state.items) {
        if (it.mode == mode) state.qty.value -= it.qty;
        else newItems.push(it);
    }

    state.items.splice(0, state.items.length, ...newItems);
};

const findVespa = mode => {
    return state.items.find(it => it.mode == mode);
};

const clear = variant => {
    const index = state.items.findIndex(it => it.id == variant.id);

    configurator.remove(variant.id);

    state.qty.value -= state.items[index].qty;
    state.items.splice(index, 1);
};

export default {
    items: readonly(state.items),
    open: state.open,
    qty: state.qty,
    add,
    remove,
    toggle,
    clear,
    empty,
    clearMode,
    findVespa
};
