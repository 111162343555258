import Builder from "@/views/Builder.vue";
import Configurator from "@/views/Configurator.vue";

export default [
    {
        path: "/configurator",
        name: "Configurator",
        component: Configurator,
        meta: {
            hideTopMenu: true,
            hideFooter: true
        }
    },
    {
        path: "/configurator/builder",
        name: "Builder",
        component: Builder,
        meta: {
            hideTopMenu: true,
            hideFooter: true
        }
    },
];