<template>
    <teleport to="#overlay">
        <div class="overlay__modal">
            <div class="motolino-modal">
              <div class="modal-close" @click="$emit('cancel')">
                  <img v-svg-inline src="@/assets/img/x.svg" />
              </div>
              <div class="modal-title">
                  <p>{{ title }}</p>
              </div>
              <div class="modal-text">
                <slot></slot>
              </div>
              <div class="layout-row">
                <button class="modal-button green" @click="$emit('yes')">
                    {{ yes || "Ja" }}
                </button>
                <button class="modal-button" @click="$emit('no')">
                    {{ no || "Nein" }}
                </button>
              </div>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        props: ["required", "title", "yes", "no", "close"],
        inject: ["top"],
        emits: ["yes", "no", "cancel"],
        mounted() {
            this.top.overlay = "modal";
        },
        unmounted() {
            this.top.overlay = null;
        }
    }
</script>

<style src="@/assets/scss/views/Dialog.scss" lang="scss"></style>