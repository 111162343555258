<template>
    <div>
        <div class="step-counter">
            <span>4</span>
            Versicherung
        </div>
        <div class="checkout-form" v-if="!insurance.type">
            <h2>Für unsere motolino Kunden, haben wir eine ganz
                besondere Versicherung mit unserem Partner
                Helvetia Versicherung zusammengestellt.
            </h2>
            <div class="sub-title">Vorteile der motolino Versicherung</div>
            <div class="row-cards between no-wrap animated-cards">
                <div class="card-img">
                    <div class="card-img__top">
                        <img src="@/assets/img/checkout/number.svg" alt=""/>
                    </div>
                    <p>
                        Versicherungsnachweis, <br/>
                        Fahrzeugeinlösung und Abholung der <br/>
                        Schilder schweizweit
                    </p>
                </div>
                <div class="card-img">
                    <div class="card-img__top">
                        <img src="@/assets/img/checkout/money-1.svg" alt=""/>
                    </div>
                    <p>
                        Mit dem Online-Versicherungsantrag <br/>
                        erhalten Sie bis zu CHF 80.- Rabatt auf <br/>
                        Ihre Vespa
                    </p>
                </div>
                <div class="card-img">
                    <div class="card-img__top">
                        <img src="@/assets/img/checkout/speed.svg" alt=""/>
                    </div>
                    <p>
                        Schweizweiter 24/7 Pannenservice inkl. <br/>
                        Verkehrsrechtsschutz
                    </p>
                </div>
            </div>
            <div class="buttons__wrapper">
                <button class="button-green" @click="next">Mit Versicherung fortfahren</button>
                <button class="button-green button-green-bordered" @click="skip">Ohne Versicherung fortfahren</button>
            </div>
            <div @click="prev" class="row-link">Zurück zum vorherigen Schritt</div>
        </div>
        <Kasko v-if="insurance.type" />
    </div>
</template>

<script>
    import Kasko from "./_Kasko";
    import Gtm from "@/services/GtmHelper";

    export default {
        inject: ["insurance"],
        components: { Kasko },
        methods: {
            skip() {
                this.insurance.type = null;
                this.$router.push("/checkout/delivery");
            },
            next() {
                Gtm.additionalStep(this.$gtm, 4, 'Insurance')
                this.insurance.type = "full";
            },
            prev() {
                this.$router.push("/checkout/warranty");
            }
        }
    }
</script>
