import { reactive, watch } from "vue";
import { set, get } from "./Local";
import prefetchImages from "prefetch-image";
import { api, impaxis } from "@/services/Query";
import mapper from "@/services/Mapper";
import config from "@/services/Config";

const state = reactive({
    data: null,
    collection: null,
    product: null,
    bike: null,
    addons: [],
    upsell: [],
    step: 0,
});

const remove = (id, qty) => {
    if (state.bike && state.bike.id == id)
        reset();
    else {
        state.addons.forEach(p => {
            const addon = p.values.find(v => v.id == id);

            if (addon)
                addon.selected = false;
        });

        state.upsell.forEach(p => {
            const upsell = p.values.find(v => v.id == id);

            if (upsell)
                upsell.qty = Math.max(0, upsell.qty - (qty || 1));
        });
    }
};

const reset = () => {
    state.collection = null;
    state.product = null;
    state.bike = null;
    state.step = 0;
};

watch(state, () => {
    let data = null;

    if (state.bike) {
        data = {
            collectionId: state.collection ? state.collection.id : null,
            productId: state.product ? state.product.id : null,
            bikeId: state.bike ? state.bike.id : null,
            addonIds: state.addons.map(a => a.values).flat().filter(v => v.selected).map(v => v.id),
            upsells: state.upsell.map(u => u.values).flat().filter(v => v.qty > 0).map(v => ({ id: v.id, qty: v.qty })),
        };
    }

    set("configurator", data);
});

const share = async () => {
    const data = {
        collectionId: state.collection?.id,
        productId: state.product?.id,
        bikeId: state.bike?.id,
        addonIds: state.addons.map(a => a.values).flat().filter(v => v.selected).map(v => v.id),
    };

    return await impaxis("share", data);
};

const hasBike = () => {
    const config = get("configurator");

    return config?.bikeId;
};

const next = item => {
    if (!state.collection) {
        state.collection = item;
        state.step = 1;

        state.addons = item.addons.map(p => ({
            ...p,
            name: p.title,
            handle: p.handle,
            values: p.variants.map(v => ({
                ...v,
                text: v.title || p.title,
                selected: false,
            }))
        }));
        state.upsell = item.upsell.map(p => ({
            ...p,
            name: p.title,
            handle: p.handle,
            values: p.variants.map(v => ({
                ...v,
                text: v.title || p.title,
                qty: 0,
            }))
        }));
    }
    else if (!state.product) {
        state.product = item;
        state.bike = item.variants[0];
        state.step = 2;
    }
};

const prev = () => {
    if (state.bike) {
        state.bike = state.product = null;
        state.step = 1;
    } else if (state.collection) {
        state.collection = null;
        state.step = 0;
    }
};

const pick = (variant, multiple) => {
    const group = state.addons.find(a => a.id == variant.product.id);

    group.values.forEach(v => {
        if (multiple)
            v.selected = (v.id == variant.id ? !v.selected : v.selected);
        else
            v.selected = (v.id == variant.id ? !v.selected : false);
    });
};

const pickOne = (variant, selected) => {
    const group = state.addons.find(a => a.id == variant.product.id);

    group.values.forEach(v => {
        v.selected = (v.id == variant.id ? selected : false);
    });
}

const fetchImages = async () => {
    const images = [];

    state.data.forEach(c => {
        images.push(c.image);

        c.bikes.forEach(b => {
            b.variants.forEach(v => {
                images.push(v.image);
            });
        });
    });

    await prefetchImages(images);
};

const loadShare = config => {
    const data = {
        ...config,
        upsells: []
    };

    set("configurator", data);
};

const load = async () => {
    if (!state.data) {
        const ids = get("configurator");

        state.data = await fetch();
        reset();

        await fetchImages();

        if (ids) {
            if (ids.collectionId) {
                const collection = state.data.find(c => c.id == ids.collectionId);

                if (collection)
                    next(collection);
            }

            if (state.collection && ids.productId) {
                const product = state.collection.bikes.find(c => c.id == ids.productId);

                if (product)
                    next(product);
            }

            if (state.product) {
                const bike = state.product.variants.find(c => c.id == ids.bikeId);

                state.bike = bike || state.bike;
            }

            if (ids.addonIds) {
                state.addons.forEach(a => {
                    a.values.forEach(v => {
                        v.selected = ids.addonIds.includes(v.id);
                    });
                });
            }

            if (ids.upsells) {
                state.upsell.forEach(u => {
                    u.values.forEach(v => {
                        v.qty = ids.upsells.find(u2 => u2.id == v.id)?.qty;
                    });
                });
            }
        }
    }

    return true;
};


const has = (array, value) => array ? array.map(it => it.toLowerCase()).includes(value.toLowerCase()) : false;

const fetch = async () => {
    const data = (await Promise.all(config.configurator.ids.map(api.collection))).filter(c => c);

    return data.map(c => {
        const bikes = [];
        const addons = [];
        const upsell = [];

        c.products.forEach(p => {
            const mapped = mapper.product(p);

            if (has(mapped.tags, "bike")) {
                bikes.push(mapped);

                mapped.image = mapped.variant.image;
            }
            else if (has(mapped.tags, "addon"))
                addons.push(mapped);
            else if (has(mapped.tags, "upsell"))
                upsell.push(mapped);
        });

        const mapTitle = s => s.startsWith("Configurator: ") ? s.slice(14) : s;

        return {
            id: c.id,
            image: mapper.image(c.image),
            title: mapTitle(c.title),
            handle: c.handle,
            teaser: c.descriptionHtml,
            bikes, addons, upsell
        }
    });
};

export default {
    state,
    pick,
    load,
    next,
    prev,
    reset,
    remove,
    share,
    hasBike,
    loadShare,
    pickOne,
};
