<template>
    <div>
        <div class="inputs-row mw-405">
            <div class="input-wrapper">
                <span>Kartennummer</span>
                <div class="custom-input__payment">
                    <div ref="cardNum"></div>
                </div>
                <span class="input-invalid padding-mod">{{ errors.num }}</span>
            </div>
        </div>
        <div class="inputs-row mw-405">
            <div class="input-wrapper">
                <span>Name auf der Karte</span>
                <input placeholder="Hans Muster" v-model="cardName" type="text" />
            </div>
        </div>
        <div class="inputs-row mw-405">
            <div class="input-wrapper">
                <span>Ablaufdatum</span>
                <div class="custom-input__payment">
                    <div ref="cardExp"></div>
                </div>
                <span class="input-invalid padding-mod">{{ errors.exp }}</span>
            </div>
            <div class="input-wrapper small-85">
                <span>CVC</span>
                <div class="custom-input__payment">
                    <div ref="cardCvc"></div>
                </div>
                <span class="input-invalid padding-mod">{{ errors.cvc }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import config from "@/services/Config";
    import { loadStripe } from "@stripe/stripe-js";

    export default {
        emits: ["change"],
        data: () => ({
            host: null,
            elements: null,
            cardNum: null,
            cardExp: null,
            cardCvc: null,
            cardName: null,
            valid: {
                numOk: false,
                cvcOk: false,
                expOk: false
            },
            errors: {
                num: null,
                exp: null,
                cvc: null,
            }
        }),
        created() {
            this.init();
        },
        methods: {
            async init() {
                this.host = await loadStripe(config.stripe.apiKey);
                this.elements = this.host.elements();
                this.cardNum = this.elements.create("cardNumber");
                this.cardExp = this.elements.create("cardExpiry");
                this.cardCvc = this.elements.create("cardCvc");

                this.cardNum.mount(this.$refs.cardNum);
                this.cardExp.mount(this.$refs.cardExp);
                this.cardCvc.mount(this.$refs.cardCvc);

                this.cardNum.on("change", this.cardChange);
                this.cardExp.on("change", this.cardChange);
                this.cardCvc.on("change", this.cardChange);
            },
            cardChange(e) {
                if (e.elementType === "cardNumber") {
                    this.errors.num = e.error?.message;
                    this.valid.num = e.complete;
                }

                if (e.elementType === "cardExpiry") {
                    this.errors.exp = e.error?.message;
                    this.valid.exp = e.complete;
                }

                if (e.elementType === "cardCvc") {
                    this.errors.cvc = e.error?.message;
                    this.valid.cvc = e.complete;
                }

                const state = {
                    complete: (this.valid.exp && this.valid.num && this.valid.cvc && this.cardName)
                };
                this.$emit("change", state);
            },
            async create() {
                if (!this.cardNum)
                    throw "Card not initialized.";

                const result = await this.host.createPaymentMethod({
                    type: "card",
                    card: this.cardNum,
                    billing_details: {
                        name: this.cardName,
                    }
                });

                result.paymentMethod.card.name = this.cardName;

                return result.paymentMethod;
            },
        }
    }
</script>
