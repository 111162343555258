<template>
    <div>
        <div class="step-counter">
            <span>5</span>
            Lieferung
        </div>
        <div class="checkout-form">
            <h2>
                Jeden Samstag liefern wir Vespas bis vor Ihre Haustüre - oder
                Sie holen Ihre Vespa einfach in unserem Store in Basel, Zürich
                und Sirnach ab!
            </h2>
            <div class="row-cards">
                <div class="card card-center card-with-btn">
                    <div class="card-inner">
                        <div class="card-logo">
                            <img
                                src="@/assets/img/checkout/delivery-1.png"
                                alt=""
                            />
                        </div>
                        <h4 class="card-title">Lieferung vor die Haustüre</h4>
                        <div class="vertical-content-btw">
                            <p style="opacity: 0.3" class="mw-225">
                                Motolino liefert Ihnen Ihre Vespa unkompliziert
                                bis vor Ihre Haustüre.
                            </p>
                            <p class="mw-225">Lieferzeit: 15 Arbeitstage</p>
                            <button
                                :class="[
                                    'button-green button-green-bordered',
                                    { choosen: delivery.type == 'ship' },
                                ]"
                                @click="delivery.type = 'ship'"
                            >
                                Ich möchte eine Lieferung
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card card-center card-with-btn">
                    <div class="card-inner">
                        <div class="card-logo">
                            <img src="@/assets/img/checkout/shop.png" alt="" />
                        </div>
                        <h4 class="card-title">In Store Pickup</h4>
                        <div class="vertical-content-btw">
                            <p style="opacity: 0.3" class="mw-225">
                                Holen Sie Ihre Vespa in unserem Store direkt in
                                Basel, Zürich und Sirnach ab.
                            </p>
                            <p class="mw-225">Lieferzeit: 15 Arbeitstage</p>
                            <button
                                :class="[
                                    'button-green button-green-bordered',
                                    { choosen: delivery.type == 'pickup' },
                                ]"
                                @click="delivery.type = 'pickup'"
                            >
                                Ich möchte die Bestellung abholen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-sub-option" v-if="delivery.type == 'ship'">
                <div class="card-inner">
                    <div class="title">
                        Sie wünschen eine Lieferung mit Motolino Spedition
                    </div>
                    <p>Lieferzeit: 15 Arbeitstage</p>
                    <div class="price">Lieferkosten: CHF 99.00</div>
                </div>
            </div>
            <div class="card-sub-option" v-if="delivery.type == 'pickup'">
                <div class="map">
                    <GoogleMap
                        api-key="AIzaSyDsFi2mLjRe9w_RSfueEFuJvMO3oEIaOWE"
                        :center="{ lat: loc.lat, lng: loc.lng }"
                        :zoom="15"
                        style="width: 100%; height: 100%"
                    >
                        <Marker
                            :options="{
                                position: { lat: loc.lat, lng: loc.lng },
                                icon: icon,
                            }"
                        />
                    </GoogleMap>
                </div>
                <div class="card-inner">
                    <div class="title">Wählen Sie Ihren Abholstandort</div>
                    <div class="inputs-row">
                        <div class="input-wrapper location-input">
                            <Dropdown
                                v-model="delivery.pickupId"
                                :options="delivery.locations"
                            />
                        </div>
                    </div>
                    <div class="title">Motolino {{ loc.city }}</div>
                    <div class="address">
                        {{ loc.street }}<br />
                        {{ loc.zip }} {{ loc.city }}
                    </div>
                    <div v-for="(n, i) in loc.notes" :key="i">
                        {{ n }}
                    </div>
                </div>
            </div>
            <div class="buttons__wrapper" v-if="!status.ready">
                <button
                    class="button-green"
                    @click="next"
                    :disabled="!delivery.type"
                >
                    Zur Zahlung
                </button>
            </div>
            <div class="buttons__wrapper" v-if="status.ready">
                <button
                    class="button-green"
                    @click="$router.push('/checkout/review')"
                >
                    <div class="hover-arr">
                        <img src="@/assets/img/icons/save-white.svg" alt="" />
                        <img
                            src="@/assets/img/icons/save-green.svg"
                            class="hovered"
                            alt=""
                        />
                    </div>
                    Zum Bestellabschluss
                </button>
            </div>
            <div @click="prev" class="row-link">
                Zurück zum vorherigen Schritt
            </div>
        </div>
    </div>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
import Icon from "@/assets/img/checkout/marker.svg";
import Gtm from "@/services/GtmHelper";

export default {
    inject: ["delivery", "status"],
    components: { GoogleMap, Marker },
    data: () => ({
        icon: Icon,
    }),
    methods: {
        next() {
            Gtm.additionalStep(this.$gtm, 6, "Delivery");
            this.$router.push("/checkout/payment");
        },
        prev() {
            if (this.status.mode == "simple")
                this.$router.push("/checkout/shipping");
            else this.$router.push("/checkout/insurance");
        },
    },
    computed: {
        loc() {
            return this.delivery.locations.find(
                (l) => l.value == this.delivery.pickupId
            );
        },
    },
};
</script>
