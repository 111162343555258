<template>
    <div class="dropdown-component">
        <Multiselect v-model="value" :options="options" :canDeselect="false" @change="change" :placeholder="placeholder">
            <template v-slot:clear>
                <span></span>
            </template>
        </Multiselect>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect';

    export default {
        components: { Multiselect },
        props: ["modelValue", "options", "placeholder"],
        emits: ["change", "update:modelValue"],
        data: () => ({
            index: 0,
            value: null,
        }),
        mounted() {
            this.value = this.modelValue;
        },
        methods: {
            change(newValue) {
                this.$emit("update:modelValue", newValue);
                this.$emit("change", newValue);
            }
        },
        watch: {
            modelValue(newValue) {
                this.value = newValue;
            }
        }
    }
</script>

<style src="@/assets/scss/views/Dropdown.scss" lang="scss"></style>