<template>
  <nav :class="{ 'main-menu': true, 'main-menu__active': active, 'main-menu__transparent': $route.meta.isHome }">
      <div class="main-menu__wrapper tw-py-5 tw-max-w-screen-lg xl:tw-max-w-screen-xl 2xl:tw-max-w-full  2xl:tw-px-16 xl:tw-px-10 tw-px-5 tw-mx-auto layout-row">
        <div :class="{ 'mobile-hamburger': true, 'to-close': top.mobileMenu }" @click="openMobileMenu()">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="main-menu__left flex">
          <div class="main-menu__left__image">
              <router-link to="/">
                  <img src="@/assets/img/motolino-logo-stroke.svg" alt="logo"/>
              </router-link>
          </div>
          <div :class="{ 'menu-transform': true, 'opened': top.mobileMenu }">
              <div class="main-menu__left__links" :class="{'menu-mobile-menu': top.mobileMenu}">
                  <div v-for="(it, i) in menu" :key="i">
                      <router-link class="menu-item hover:tw-text-primary" :to="it.url" v-if="!it.subMenu" @click="clearMenu()">
                          <span>{{ it.text }}</span>
                      </router-link>

                      <div class="menu-item__with-children hover:tw-text-primary" v-if="it.subMenu">
                          <span @click="toggle(it)">{{ it.text }}</span>

                          <div class="submenu-section appear-done enter-done" v-if="it.open">
                              <div class="submenu">
                                  <div class="submenu__wrapper layout-row">
                                      <div :class="['submenu__link underline_from_left', { 'submenu__link--active sub-opened': it2.open }]"
                                            v-for="(it2, i) in it.subMenu" :key="i" @click="toggle2(it2)">
                                          <span v-if="!it2.url && !it2.extUrl">{{ it2.text }}</span>
                                          <router-link :to="it2.url" v-if="it2.url" @click="clearMenu()">{{ it2.text }}</router-link>
                                            <a :href="it2.extUrl" v-if="it2.extUrl" target="_blank">
                                                <span>{{ it2.text }}</span>
                                            </a>

                                          <div class="subsubmenu" v-if="it2.open && it2.subMenu">
                                              <div class="subsubmenu__wrapper layout-row">
                                                <div class="subsubmenu__link underline_from_left" v-for="(it3, i) in it2.subMenu" :key="i">
                                                    <router-link :to="it3.url" v-if="it3.url" @click="clearMenu()">
                                                        <span>{{ it3.text }}</span>
                                                    </router-link>
                                                    <a :href="it3.extUrl" v-if="it3.extUrl" target="_blank">
                                                        <span>{{ it3.text }}</span>
                                                    </a>
                                                </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                <div class="main-menu__right__links__conf">
                    <router-link to="/configurator">
                        <div class="main-menu__right__links__conf__button">
                            <span>Vespa Konfigurieren</span>
                        </div>
                    </router-link>
                </div>
          </div>
        </div>
            <div class="main-menu__right">
                <div class="main-menu__right__links">
                    <div class="main-menu__right__links__wrapper layout-row">
                        <span class="main-menu__right__links__search hover:tw-text-primary" @click="openSearch">
                            <img v-svg-inline src="@/assets/img/search.svg" />
                        </span>
                        <span class="main-menu__right__links__cart hover:tw-text-primary" @click="openCart">
                            <img v-svg-inline src="@/assets/img/cart_neutral.svg"/>
                            <span class="main-menu__right__links__cart__quantity" v-if="cart.qty.value > 0">
                                {{ cart.qty.value }}
                            </span>
                            <Cart v-if="sidebar == 'cart'" />
                            <Login v-if="sidebar == 'login'" @reset="sidebar = 'reset'" />
                            <ResetPassword v-if="sidebar == 'reset'" />
                            <Search v-on:search-closed="closeSearch()" v-if="sidebar == 'search'" />
                        </span>
                        <span v-if="!user.authed.value" @click="openLogin" class="hover:tw-text-primary">
                            <div>
                                <img v-svg-inline src="@/assets/img/user.svg" />
                            </div>
                        </span>
                        <div class="menu-dropdown" v-if="user.authed.value" @mouseenter="top.userMenu = true" @mouseleave="top.userMenu = false" @click="top.userMenu = true">
                            <span class="main-menu__right__links__user hover:tw-text-primary layout-row">
                                <div>
                                    <img v-svg-inline src="@/assets/img/user-aut.svg" class="main-menu__right__links__user-img"/>
                                </div>
                                <div class="user-email">{{ user.auth.email }}</div>
                            </span>
                            <div :class="['menu-dropdown__body', { 'menu-dropdown__body--visible': top.userMenu }]">
                                <ul class="main-menu__right__links__dropdown">
                                    <li><router-link to="/account/my-vespa">Meine Vespa</router-link></li>
                                    <li><router-link to="/account/profile">Meine Daten</router-link></li>
                                    <li><router-link to="/account/orders">Meine Bestellungen</router-link></li>
                                    <li @click="logout">Ausloggen</li>
                                </ul>
                            </div>
                        </div>
                        <div class="main-menu__right__links__conf">
                            <router-link to="/configurator">
                                <div class="main-menu__right__links__conf__button">
                                    <span>Vespa Konfigurieren</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <div class="menu__divider" v-if="!$route.meta.isHome"></div>
      <div class="backdrop" v-if="active" @click="reset"></div>
  </nav>
</template>

<script>
import Menu from "@/services/Menu";
import Cart from "@/components/Overlays/Cart";
import Login from "@/components/Overlays/Login";
import Search from "@/components/Overlays/Search";
import ResetPassword from "@/components/Overlays/ResetPassword";

export default {
    components: { Cart, Login, Search, ResetPassword },
    inject: ["cart", "user", "top"],
    data: () => ({
        active: null,
        active2: null,
        menu: Menu,
        sidebar: null,
    }),
    methods: {
        toggle(it) {
            const active = (it.open = !it.open) ? it : null;

            this.reset();
            this.active = active;
        },
        toggle2(it) {
            if (this.active2)
                this.active2.open = false;

            this.active2 = (it.open = !it.open) ? it : null;
        },
        openCart() {
            this.top.overlay = "cart";
            this.sidebar = "cart";
        },
        openSearch() {
            this.top.overlay = "search";
            this.sidebar = "search";
        },
        closeSearch() {
            setTimeout(() => {
                this.sidebar = "";
            }, 1000)
        },
        openLogin() {
            this.top.overlay = "login";
            this.sidebar = "login";
            this.top.userMenu = false;
        },
        async logout() {
            await this.user.logout();

            this.top.notification = "Du bist jetzt ausgeloggt";
            this.top.userMenu = false;
        },
        reset() {
            if (this.active)
                this.active.open = false;

            if (this.active2)
                this.active2.open = false;

            this.active = this.active2 = null;
            this.top.userMenu = false;

        },
        openMobileMenu() {
            this.top.mobileMenu = !this.top.mobileMenu;
            const style = this.top.mobileMenu ? "position:fixed;overflow:hidden" : "";
            document.body.setAttribute("style", style);
        },
        clearMenu() {
            this.reset();
            document.body.setAttribute("style", '');
        }
    },
    watch: {
        $route() {
            this.reset();
        }
    },
}
</script>

<style lang="scss" src="@/assets/scss/views/TopMenu.scss"></style>
