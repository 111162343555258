<template>
    <div class="slider-body__outher">
        <div class="slider-body__scroller">
            <div class="slider-body__inner">
                <div class="slider-body">
                    <div v-for="o in options" :key="o" :class="['slider-item', { active: modelValue == o.value }]" @click="select(o)">
                        {{ o.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["title", "options", "modelValue"],
        emits: ["update:modelValue", "change"],
        methods: {
            select(o) {
                this.$emit("update:modelValue", o.value);
                this.$emit("change", o.value);
            }
        }
    };
</script>
