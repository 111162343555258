<template>
    <teleport to="#overlay">
        <div class="overlay__right">
            <form class="search" @submit.prevent="search">
                <div class="search__input">
                    <div class="input">
                        <span class="input__icon-left">
                            <div><img v-svg-inline src="@/assets/img/search.svg" /></div>
                        </span>
                        <span class="input__icon-right" @click="close">
                            <div><img v-svg-inline src="@/assets/img/x.svg" /></div>
                        </span>
                        <input placeholder="Was suchst du?" class="input__field input__field--left-icon" v-model="term" @input="search" ref="search" />
                    </div>
                </div>
                <div :class="['search__products', { 'search__products--expanded': term }]">
                    <Spinner v-if="loading" />
                    <ul>
                        <li :class="['search__products__item', { ready: !loading }]" v-for="p in result" :key="p.id">
                            <a :href="p.url" @click.prevent="goto(p)">
                                <img :src="p.variant.image" />
                                <span>
                                    <h4>{{ p.title }}</h4>
                                    <p>{{ p.productType }}</p>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    </teleport>
</template>

<script>
    import { api } from "@/services/Query";
    import { debounce } from "@/services/Formatters";

    export default {
        inject: ["top"],
        emits: ["searchClosed"],
        data: () => ({
            term: null,
            loading: false,
            result: [],
            searchFn: null,
        }),
        mounted() {
            const search = this.$refs.search;
            search.focus();

            setTimeout(() => search.focus(), 0);
        },
        methods: {
            goto(p) {
                this.term = null;
                this.$router.push(p.url);
            },
            search() {
                const $this = this;

                this.loading = true;

                this.searchFn = this.searchFn || debounce(async () => {
                    $this.result = await api.products(`${$this.term}*`);

                    $this.loading = false;
                }, 250);

                this.searchFn();
            },
            close() {
                this.term = null;
                this.top.overlay = null;
                this.$emit('searchClosed');
            }
        }
    };
</script>
