<template>
    <div class="carousel__fecker">
        <div class="carousel__control carousel__control--left" @click="prev()" v-if="indexValue > 0">
            <img v-svg-inline src="@/assets/img/carousel-arrow.svg" />
        </div>

        <div class="carousel__control carousel__control--right" @click="next()" v-if="indexValue < items.length - 1">
            <img v-svg-inline src="@/assets/img/carousel-arrow.svg" />
        </div>

        <div class="carousel__items">
            <div v-for="(img, i) in items" :key="i" :style="style">
                <img :src="img" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["items", "index"],
        data: () => ({
            indexValue: 0,
        }),
        mounted() {
            this.indexValue = this.index || 0;
        },
        methods: {
            next() {
                this.indexValue = Math.min(this.indexValue + 1, this.items.length - 1);
            },
            prev() {
                this.indexValue = Math.max(this.indexValue - 1, 0);
            }
        },
        computed: {
            style() {
                return { left: (-this.indexValue * 100) + "%" };
            }
        }
    }
</script>

<style src="@/assets/scss/views/Carousel.scss" lang="scss"></style>