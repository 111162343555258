const price = (value, decimals) => {
    const intl = new Intl.NumberFormat("de-CH", {
        style: "currency",
        currency: "CHF",
        maximumFractionDigits: decimals || 0,
        minimumFractionDigits: decimals || 0
    });

    return intl.format(value);
};
const amount = (value, decimals) => {
    const intl = new Intl.NumberFormat("de-CH", {
        maximumFractionDigits: decimals || 0,
        minimumFractionDigits: decimals || 0
    });

    return intl.format(value);
};
const date = d => (d ? new Date(d).toLocaleDateString("de-CH") : null);

const debounce = (func, wait, immediate) => {
    var timeout;

    return function() {
        var context = this,
            args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const validPhone = input => {
    return input;
};

const fulfillment = code => {
    switch (code) {
        case "FULFILLED":
            return "Fulfilled";
        case "IN_PROGRESS":
            return "In progress";
        case "OPEN":
            return "Open";
        case "PARTIALLY_FULFILLED":
            return "Partially fulfilled";
        case "PENDING_FULFILLMENT":
            return "Pending fulfillment";
        case "RESTOCKED":
            return "Restocked";
        case "SCHEDULED":
            return "Scheduled";
        case "UNFULFILLED":
            return "Unfulfilled";
        default:
            return code;
    }
};

const financial = code => {
    switch (code) {
        case "AUTHORIZED":
            return "Authorized";
        case "PAID":
            return "Paid";
        case "PARTIALLY_PAID":
            return "Partially paid";
        case "PARTIALLY_REFUNDED":
            return "Partially refunded";
        case "PENDING":
            return "Pending";
        case "REFUNDED":
            return "Refunded";
        case "VOIDED":
            return "Voided";
        default:
            return code;
    }
};

export { price, date, debounce, amount, validPhone, fulfillment, financial };
