const image = i => {
    if (!i)
        return null;
    else if (Array.isArray(i))
        return i[0].transformedSrc;
    else
        return i.transformedSrc;
};

const variant = (v, p) => ({
    id: v.id,
    title: p.title,
    subtitle: v.title == "Default Title" ? null : v.title,
    price: Number(v.priceV2.amount),
    sku: v.sku,
    image: image(v.image),
    descriptionHtml: v.descriptionHtml,
    selectedOptions: v.selectedOptions,
    selectedOptionsMap: Object.assign({}, ...v.selectedOptions.map(opt => ({ [opt.name]: opt.value }))),
    product: p,
    url: `/products/${p.handle}/${v.id}`
});

const option = opt => ({
    name: opt.name,
    values: opt.values.map(v => ({
        color: color(v),
        value: v,
    }))
});

const color = value => {
    switch (value) {
        case "Schwarz":
            return "rgb(21, 21, 20)"
        case "Gelb":
            return "rgb(221, 201, 73)"
        case "Weiss":
            return "rgb(236, 238, 235)"
        case "Rot":
            return "rgb(203, 17, 21)"
        case "Graumatt":
            return "rgb(103, 106, 109)"
        case "Blaumatt":
            return "rgb(35, 97, 152)"
        case "Schwarzmatt":
            return "rgb(77, 77, 78)"
        case "Grün":
            return "rgb(85, 104, 91)"
        case "Grau":
            return "rgb(210, 210, 210)"
    }
}

const product = p => {
    if (!p)
        return null;

    const product = {
        id: p.id,
        handle: p.handle,
        title: p.title,
        tags: p.tags,
        options: p.options,
        image: image(p.images),
        images: p.images,
        descriptionHtml: p.descriptionHtml,
        productType: p.productType,
        url: `/products/${p.handle}`
    };

    product.variants = p.variants.map(v => variant(v, product));
    product.variant = product.variants[0];

    return product;
};

export default {
    variant, image, product, option
};