<template>
    <teleport to="#overlay">            
        <div class="overlay__modal">
            <div class="modal">
                <div class="modal__title">
                    <p>Change password</p>
                    <div class="modal__close" @click="close">
                        <img v-svg-inline src="@/assets/img/x.svg" />
                    </div>
                </div>
                <form @submit.prevent="submit">
                    <div class="address-form">
                        <div class="input">
                            <span class="input__label">Ihr Passwort</span>
                            <input type="password" v-model="password" required minlength="6" :class="['input__field', { 'input__field--error': error }]" />
                            <span class="input__error" v-if="error">{{ error }}</span>
                        </div>
                        <div class="input">
                            <span class="input__label">Passwort wierderholen</span>
                            <input type="password" v-model="passwordRepeat" required minlength="6" class="input__field" />
                        </div>

                        <button type="submit" :disabled="loading || invalid">
                            {{ loading ? "Lädt..." : "Speichern" }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        inject: ["user", "top"],
        data: () => ({
            loading: false,
            error: null,
            password: null,
            passwordRepeat: null,
            invalid: false,
        }),
        mounted() {
            this.top.overlay = "modal";
        },
        methods: {
            async submit() {
                if (this.password !== this.passwordRepeat) {
                    this.error = "Passwords do not match.";

                    return;
                }

                this.loading = true;
                this.error = null;

                const id = btoa(`gid://shopify/Customer/${this.$route.query.reset}`);
                const data = {
                    password: this.password,
                    resetToken: this.$route.query.token,
                };

                let result = null;

                try {
                    result = await this.user.resetPassword(id, data);
                } catch {
                    result = { message: "Invalid token." };
                }

                if (result.success) {
                    this.top.overlay = null;
                    this.top.notification = {
                        title: "Password changed!",
                        content: "Du bist jetzt eingeloggt"
                    };
                    this.$router.replace("/");
                }
                else
                    this.error = result.message;

                this.loading = false;
            },
            close() {
                this.top.overlay = null;
                this.$router.replace("/");
            }
        },
        watch: {
            "top.overlay"(value) {
                if (!value)
                    this.$router.replace("/");
            }
        }
    }
</script>

<style src="@/assets/scss/views/ResetPassword.scss" lang="scss"></style>