<template>
  <div class="container-wide">
    <div class="offers">
        <div class="offer tw-transition hover:tw-shadow-md tw-pb-5 tw-px-5">
            <router-link to="/pages/mobiler-service-bus">
                <div class="offer__image">
                    <div class="tw-flex tw-justify-center">
                        <div><img v-svg-inline src="@/assets/img/icons/service.svg" /></div>
                    </div>
                    <div class="offer__shadow"></div>
                </div>
                <h2>Mobiler Service</h2>
                <p class="tw-text-gray-dark tw-text-sm">Motolino erledigt den Service an deinem Wunschtermin und -ort</p>
            </router-link>
        </div>
        <div class="offer tw-transition hover:tw-shadow-md tw-pb-5 tw-px-5">
            <router-link to="/pages/leasing">
                <div class="offer__image">
                    <div class="tw-flex tw-justify-center">
                        <div><img v-svg-inline src="@/assets/img/icons/online-leasing.svg" /></div>
                    </div>
                    <div class="offer__shadow"></div>
                </div>
                <h2>Online Leasing</h2>
                <p class="tw-text-gray-dark tw-text-sm">Leasing-Antrag bequem über unseren Shop einreichen</p>
            </router-link>
        </div>
        <div class="offer tw-transition hover:tw-shadow-md tw-pb-5 tw-px-5">
            <router-link to="/category/motolino-starterpaket">
                <div class="offer__image">
                    <div class="tw-flex tw-justify-center">
                        <div><img v-svg-inline src="@/assets/img/icons/scooter.svg" /></div>
                    </div>
                    <div class="offer__shadow"></div>
                </div>
                <h2>Vespa Starterkit</h2>
                <p class="tw-text-gray-dark tw-text-sm">Spezial-Paket für Neulenker: stark vergünstigter Grundkurs A1, Vespa Helm und gratis Nierengurt dazu!</p>
            </router-link>
        </div>
        <div class="offer tw-transition hover:tw-shadow-md tw-pb-5 tw-px-5">
            <a href="https://news.motolino.ch/motolino-filialen">
                <div class="offer__image">
                    <div class="tw-flex tw-justify-center">
                        <div><img v-svg-inline src="@/assets/img/icons/address.svg" /></div>
                    </div>
                    <div class="offer__shadow"></div>
                </div>
                <h2>Überall vor Ort</h2>
                <p class="tw-text-gray-dark tw-text-sm">Drei Standorte in der Schweiz: <br/> Zürich, Basel und Sirnach</p>
            </a>
        </div>
        <div class="offer tw-transition hover:tw-shadow-md tw-pb-5 tw-px-5">
            <router-link to="/pages/motolino-garantie">
                <div class="offer__image">
                    <div class="tw-flex tw-justify-center">
                        <div><img v-svg-inline src="@/assets/img/guarantee4.svg" /></div>
                    </div>
                    <div class="offer__shadow"></div>
                </div>
                <h2>4 statt 2 Jahre Garantie</h2>
                <p class="tw-text-gray-dark tw-text-sm">Wir sind überzeugt von der Qualität und Langlebigkeit der Vespa! Nur bei Motolino Kostenlose 4 Jahre statt 2 Jahre Garantie.</p>
            </router-link>
        </div>
    </div>
  </div>
</template>

<script>
  import service from '@/assets/img/icons/service.svg';
  import leasing from '@/assets/img/icons/online-leasing.svg';
  import scooter from '@/assets/img/icons/scooter.svg';
  import location from '@/assets/img/icons/address.svg';
  export default {
      data: () => ({
        service, leasing, scooter, location
      })
  }
</script>
<style lang="scss" src="@/assets/scss/views/Offers.scss"></style>
