<template>
    <div class="order-details">
        <div v-if="order">
            <router-link to="/account/orders" class="order-details__link">
                <div>
                    <img v-svg-inline src="@/assets/img/icons/arrow_left.svg" />
                    Zurück zur Bestellübersicht
                </div>
            </router-link>
            <h1>Ihre Bestellung #{{ id }}</h1>
            <p class="order-details__status">
                <span>{{ financial(order.financialStatus) }} / {{ fulfillment(order.fulfillmentStatus) }}</span>
                <span>Bestelldatum: {{ date(order.processedAt) }}</span>
            </p>
            <h3 v-if="tracking">
                Verfolgen Sie Ihre Bestellung: <a :href="tracking.url" target="_blank" class="button-text-decor">{{ tracking.number }}</a>
            </h3>
            <table class="cart-table">
                <thead>
                    <tr>
                        <th>Produkte {{ viewport }}</th>
                        <th class="cart-table__price-header" v-if="app.vp.width >= 992">Preis</th>
                        <th class="cart-table__quantity-header">Menge</th>
                        <th colspan="2">Total</th>
                    </tr>
                </thead>
                <tbody class="cart-table-tbody-desktop" v-if="app.vp.width >= 540">
                    <tr class="cart-table-row--processing" v-for="(it, i) in orderItems" :key="i">
                        <td class="cart-table__thumbnail">
                            <div>
                                <span v-if="!it.variant">
                                    <img :src="it.variant.image.transformedSrc" style="max-width:50px;max-height:50px" />
                                </span>
                                <router-link :to="`/products/${it.variant?.product.handle}/${it.variant?.id}`" v-if="it.variant">
                                    <img :src="it.variant.image.transformedSrc" style="max-width:50px;max-height:50px" />
                                </router-link>
                                <span v-if="!it.variant">
                                    {{ it.title }}
                                    <span v-if="it.variant && it.variant.title != 'Default Title'">({{ it.variant.title }})</span>
                                </span>
                                <router-link :to="`/products/${it.variant?.product.handle}/${it.variant?.id}`" v-if="it.variant">
                                    {{ it.title }}
                                    <span v-if="it.variant && it.variant.title != 'Default Title'">({{ it.variant.title }})</span>
                                </router-link>
                            </div>
                        </td>
                        <td class="cart-table__price-header" v-if="app.vp.width >= 992">
                            {{ price(it.originalTotalPrice.amount / it.quantity, 2) }}
                        </td>
                        <td class="cart-table__quantity-cell">
                            <p>{{ it.quantity }}</p>
                        </td>
                        <td colspan="2">
                            {{ price(it.originalTotalPrice.amount, 2) }}
                        </td>
                    </tr>
                </tbody>
                <tbody class="cart-table-tbody-mobile" v-if="app.vp.width < 540">
                   <tr class="cart-table-row--processing" v-for="(it, i) in orderItems" :key="i">
                     <td class="cart-table__thumbnail">
                       <div>
                            <span v-if="!it.variant">
                                <img :src="it.variant.image.transformedSrc" style="max-width:50px;max-height:50px" />
                            </span>
                            <router-link :to="`/products/${it.variant?.product.handle}/${it.variant?.id}`" v-if="it.variant">
                                <img :src="it.variant.image.transformedSrc" style="max-width:50px;max-height:50px" />
                            </router-link>
                       </div>
                     </td>
                     <td>
                       <div class="name-row">
                            <span v-if="!it.variant">{{ it.title }}</span>
                            <router-link :to="`/products/${it.variant?.product.handle}/${it.variant?.id}`" v-if="it.variant">
                                {{ it.title }}
                            </router-link>
                       </div>
                       <div v-if="it.variant && it.variant.title != 'Default Title'">
                         <span>Variant</span>
                         <div>{{ it.variant.title }}</div>
                       </div>
                       <div>
                         <span>Menge</span>
                         <div>{{ it.quantity }}</div>
                       </div>
                       <div>
                         <span>Preis</span>
                         <div clas="price">{{ price(it.originalTotalPrice.amount, 2) }}</div>
                       </div>
                     </td>
                   </tr>
                </tbody>
            </table>
            <div class="footer-review-row">
                <div>
                    Lieferkosten:
                    <span>{{ price(order.totalShippingPriceV2.amount, 2) }}</span>
                </div>
                <!-- <div>
                    Credit Card 2.9% Fee:
                    <span>CHF 0.00</span>
                </div> -->
                <div>
                    Summe:
                    <span class="price-rev">{{ price(order.totalPriceV2.amount, 2) }}</span>
                </div>
            </div>
            <div class="order-details__summary">
                <div v-if="address">
                    <h4>Lieferadresse</h4>
                    <p class="address-summary">
                        <span><strong>{{ address.firstName }} {{ address.lastName }}</strong><br /></span>
                        <span v-if="address.company">{{ address.company }}<br /></span>
                        <span>{{ address.address1 }}<br /></span>
                        <span v-if="address.address2">{{ address.address2 }}<br /></span>
                        <span>{{ address.zip }} {{ address.city }}<br /></span>
                        <span v-if="address.province">{{ address.province }}<br /></span>
                        <span>{{ address.country }}<br /></span>
                        <span v-if="address.phone">{{ address.phone }}<br /></span>
                        <span>{{ order.email }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="no-orders" v-if="!loading && !order" style="background:#ffffff;padding:50px">
            <p>Order not found.</p>
            <router-link to="/account/orders">
                <button>Zur Bestellungsübersicht</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { price, date, fulfillment, financial } from "@/services/Formatters";

    export default {
        props: ["data", "id"],
        inject: ["app"],
        data: () => ({
            order: null,
            orderItems: [],
            address: null,
            tracking: null,
            loading: true,
        }),
        mounted() {
            this.load();
        },
        methods: {
            price,
            date,
            fulfillment,
            financial,
            load() {
                this.order = this.data.orders.find(o => o.orderNumber == this.id);
                this.orderItems = this.order?.lineItems || [];
                this.address = this.order.shippingAddress;
                this.tracking = this.order.successfulFulfillments[0]?.tracking;
                this.loading = false;
            },
        }
    };
</script>

<style src="@/assets/scss/views/Order.scss" lang="scss"></style>