<template>
    <div>
        <div class="step-counter">
            <span>6</span>
            Zahlung
        </div>
        <div class="tabs">
            <div class="tabs-header" v-if="status.mode == 'wizard'">
                <a href="" :class="{ active: payment.category == 'cash' }" @click.prevent="payment.category = 'cash'">Barzahlung</a>
                <a href="" :class="{ active: payment.category == 'leasing' }" @click.prevent="payment.category = 'leasing'">Leasing</a>
                <hr/>
            </div>
        </div>
        <div class="checkout-form">
            <div class="tabs-body">
                <Cash v-if="payment.category == 'cash'" />
                <Leasing v-if="payment.category == 'leasing' && status.mode == 'wizard'" />
            </div>
        </div>
    </div>
</template>

<script>
    import Cash from "./_Cash";
    import Leasing from "./_Leasing";

    export default {
        components: { Cash, Leasing },
        inject: ["payment", "status"],
        watch: {
            style() {
                if (this.status.mode != "wizard")
                    this.payment.category = "cash";
            }
        }
    };
</script>
