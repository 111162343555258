<template>
    <div class="checkout-form">
        <h2>
            Eine sehr gute Wahl! Bitte ergänzen Sie Ihre Daten zu Ihrer
            Versicherungsanfrage nachfolgend.
        </h2>
        <h4 class="mt-40">
            Für welche Versicherung wünschen Sie eine Offerte?
        </h4>
        <div class="mt-40 row-cards">
            <div
                :class="[
                    'card card-select',
                    { active: insurance.type == 'full' }
                ]"
                @click="insurance.type = 'full'"
            >
                <div class="card-inner">
                    <div class="card-title">Vollkasko</div>
                    <ul class="card-info">
                        <li>Inkl. 24h Pannendienst</li>
                        <li>
                            Einlösung und Abholung der Schilder durch motolino.
                        </li>
                        <li>
                            Verkehrsrechtsschutz Versicherung mit 80.- Cashback
                        </li>
                    </ul>
                    <span class="button-state">
                        <img src="@/assets/img/icons/check-white.svg" alt="" />
                    </span>
                </div>
            </div>
            <div
                :class="[
                    'card card-select',
                    { active: insurance.type == 'partial' }
                ]"
                @click="insurance.type = 'partial'"
            >
                <div class="card-inner">
                    <div class="card-title">Teilkasko</div>
                    <ul class="card-info">
                        <li>inkl. 24h Pannendienst</li>
                        <li>
                            Einlösung und Abholung der Schilder durch motolino.
                        </li>
                        <li>
                            Verkehrsrechtsschutz Versicherung mit 60.- Cashback
                        </li>
                    </ul>
                    <span class="button-state">
                        <img src="@/assets/img/icons/check-white.svg" alt="" />
                    </span>
                </div>
            </div>
        </div>
        <form class="insurance-form" @submit.prevent="next">
            <div class="inputs-row">
                <div class="input-wrapper w-190">
                    <span>Ihr Geburtsdatum (DD.MM.YYYY)</span>
                    <input
                        type="text"
                        v-model="insurance.birthday"
                        placeholder="DD.MM.YYYY" maxlength="10" pattern="^\d\d\.\d\d\.\d{4}$"
                        :required="true"
                    />
                </div>
            </div>
            <div class="inputs-row">
                <div class="input-wrapper">
                    <span>Nationalität</span>
                    <Dropdown
                        v-model="insurance.nationality"
                        :options="countries"
                    />
                </div>
            </div>
            <div class="inputs-row">
                <div class="input-wrapper">
                    <span>Telefonnummer</span>
                    <Phone v-model="insurance.phone" required />
                </div>
            </div>
            <div class="toggle-section">
                <div class="toggle-item">
                    <div class="toggle-title">
                        Hatten Sie bereits eine Motorfahrzeugversicherung auf
                        Ihren Namen?
                        <label>
                            <input
                                type="checkbox"
                                v-model="insurance.hasProvider"
                            />
                            <div></div>
                        </label>
                    </div>
                    <div class="toggle-body" v-if="insurance.hasProvider">
                        <div class="inputs-row">
                            <div class="input-wrapper">
                                <span>Bei welcher Gesellschaft?</span>
                                <Dropdown
                                    v-model="insurance.provider"
                                    :options="companies"
                                    required
                                    placeholder="Auswählen"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="toggle-item">
                    <div class="toggle-title">
                        Haben Sie in den letzten 5 Jahren Schadensfälle
                        verursacht?
                        <label>
                            <input
                                type="checkbox"
                                v-model="insurance.hasAccidents"
                            />
                            <div></div>
                        </label>
                    </div>
                    <div class="toggle-body" v-if="insurance.hasAccidents">
                        <div
                            class="inputs-row row-kasko"
                            v-for="(a, i) in insurance.accidents"
                            :key="i"
                        >
                            <div class="input-wrapper kasko-data">
                                <span>Datum?</span>
                                <Datepicker v-model="a.date" :required="true" />
                            </div>
                            <div class="input-wrapper kasko-descr">
                                <span>Welcher Art?</span>
                                <input
                                    type="text"
                                    v-model="a.reason"
                                    placeholder="Kollision, Kratzer etc."
                                    required
                                />
                            </div>
                            <div
                                class="close-button"
                                @click="removeAccident(i)"
                                v-if="i > 0"
                            >
                                <img src="@/assets/img/x.svg" alt="" />
                            </div>
                        </div>
                        <div
                            class="kasko-add"
                            @click="addAccident"
                            v-if="accidents.length < 5"
                        >
                            + Weiteren Schadenfall hinzufügen
                        </div>
                    </div>
                </div>

                <div class="toggle-item">
                    <div class="toggle-title">
                        Mussten Sie in den letzten 5 Jahren Ihren Führerschein
                        abgeben?
                        <label>
                            <input
                                type="checkbox"
                                v-model="insurance.hasPenalties"
                            />
                            <div></div>
                        </label>
                    </div>
                    <div class="toggle-body" v-if="insurance.hasPenalties">
                        <div
                            class="inputs-row row-kasko"
                            v-for="(p, i) in insurance.penalties"
                            :key="i"
                        >
                            <div class="input-wrapper kasko-data">
                                <span>Datum?</span>
                                <Datepicker v-model="p.date" :required="true" />
                            </div>
                            <div class="input-wrapper kasko-descr">
                                <span>Welcher Art?</span>
                                <input
                                    type="text"
                                    v-model="p.reason"
                                    placeholder="Kollision, Kratzer etc."
                                    required
                                />
                            </div>
                            <div
                                class="close-button"
                                @click="removePenalty(i)"
                                v-if="i > 0"
                            >
                                <img src="@/assets/img/x.svg" alt="" />
                            </div>
                        </div>
                        <div
                            class="kasko-add"
                            @click="addPenalty"
                            v-if="penalties.length < 5"
                        >
                            + Weiteren Fall hinzufügen
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons__wrapper">
                <button class="button-green">Weiter</button>
            </div>
            <div @click="prev" class="row-link">
                Zurück zum vorherigen Schritt
            </div>
        </form>
    </div>
</template>

<script>
import config from "@/services/Config";
import Gtm from "@/services/GtmHelper";

export default {
    inject: ["insurance"],
    data: () => ({
        countries: config.countries,
        companies: config.kaskos,
        accidents: [],
        penalties: []
    }),
    created() {
        this.addAccident();
        this.addPenalty();
    },
    methods: {
        prev() {
            this.insurance.type = null;
        },
        addPenalty() {
            this.insurance.penalties.push({ date: null, reason: null });
        },
        addAccident() {
            this.insurance.accidents.push({ date: null, reason: null });
        },
        removePenalty(i) {
            this.insurance.penalties.splice(i, 1);
        },
        removeAccident(i) {
            this.insurance.accidents.splice(i, 1);
        },
        next() {
            Gtm.additionalStep(this.$gtm, 5, "Kasko");
            this.$router.push("/checkout/delivery");
        }
    }
};
</script>
