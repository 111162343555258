<template>
    <section class="flex vespa-builder layout-column">
        <div class="flex layout-row">
            <div class="flex builder-info layout-column">
                <div class="header-wrapper">
                    <div class="header-left">
                        <div class="layout-row">
                            <div
                                class="tw-flex tw-items-center back"
                                @click="prev"
                            >
                                <img
                                    v-svg-inline
                                    src="@/assets/img/icons/arrow_left.svg"
                                    class="header-arrow__left"
                                />
                            </div>
                            <div class="konf-logo">
                                <router-link to="/">
                                    <div>
                                        <img
                                            src="@/assets/img/motolino-logo-stroke.svg"
                                            alt="Motolino konfigurator"
                                        />
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <section class="builder-header layout-row" v-if="vtor">
                            <div>{{ vtor.state.variant.product.title }}</div>
                            <div class="light" @click="prev">Ändern</div>
                        </section>
                    </div>
                    <div class="header-right">
                        <div class="share-btn" @click="share">
                            <span>SHARE</span>
                            <div>
                                <div>
                                    <img
                                        v-svg-inline
                                        src="@/assets/img/icons/share.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Spinner v-if="loading" />
                <div class="flex layout-row vespa-row" v-if="vtor">
                    <div class="mobile-header">
                        <div>{{ vtor.state.variant.product.title }}</div>
                        <div class="light">Ändern</div>
                    </div>
                    <div class="flex layout-row vespa-wrapper">
                        <transition name="appear" :duration="800" appear>
                            <section class="vespa-preview">
                                <img :src="vtor.state.variant.image" />

                                <div
                                    v-for="(a, i) in rator.state.addons"
                                    :key="i"
                                >
                                    <transition-group
                                        name="flyin"
                                        appear
                                        :duration="500"
                                    >
                                        <img
                                            class="vespa-accessory"
                                            :class="{
                                                'upper-index':
                                                    a.handle ===
                                                        'wetterschutz' ||
                                                    a.handle ===
                                                        'wetterschutz-1' || a.handle == 'tuning-spiegel',
                                                'higher-index':
                                                    a.handle === 'auspuff'
                                            }"
                                            :src="v.image"
                                            v-for="(v, i2) in a.values"
                                            v-show="v.selected"
                                            :key="i + ',' + i2"
                                        />
                                    </transition-group>
                                </div>
                            </section>
                        </transition>
                    </div>
                    <div class="lists">
                        <section class="options-list layout-column">
                            <div
                                class="option-item"
                                v-for="(t, i) in tags.filter(
                                    t => t.type == 'addon'
                                )"
                                :key="i"
                            >
                                {{ t.text }}
                                <img
                                    src="@/assets/img/x.svg"
                                    @click="clearTag(t)"
                                />
                            </div>
                        </section>
                        <section class="upsells-list layout-column">
                            <div
                                class="upsells-item layout-row"
                                v-for="(t, i) in tags.filter(
                                    t => t.type == 'upsell'
                                )"
                                :key="i"
                            >
                                <div class="quantity-number" v-if="t.qty > 1">
                                    {{ t.qty }} x
                                </div>
                                <div class="flex">
                                    {{ t.text }}
                                </div>
                                <img
                                    class="close-icon"
                                    src="@/assets/img/x.svg"
                                    @click="clearTag(t)"
                                />
                                <div class="thumbnail" v-if="t.image">
                                    <img :src="t.image" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="mobile-section__configurator">
                    <div
                        :class="['fab-button-mob', { toggled: configuring }]"
                        @click="configuring = !configuring"
                    >
                        <img src="@/assets/img/icons/filter-white.svg" />
                        <img src="@/assets/img/icons/check-white.svg" />
                        <span>KONFIGURIEREN</span>
                    </div>
                    <div class="vespa-price__section">
                        <section class="price-section">
                            <div class="underline">
                                <div class="price-value layout-row">
                                    <div>{{ price(total) }}</div>
                                    <span>oder</span>
                                    <div>{{ price(monthly) }}</div>
                                </div>
                                <div class="price-description layout-row">
                                    <div>einmalig inkl. MwSt.</div>
                                    <div>über 60 Monate inkl. MwSt.</div>
                                </div>
                            </div>
                            <div class="delivery layout-row center between">
                                <div class="title">Lieferzeit</div>
                                <div>15 Arbeitstage</div>
                            </div>
                        </section>
                        <button class="checkout-button" @click="checkout()">
                            <img src="@/assets/img/cart.svg" />
                            Zum checkout
                        </button>
                    </div>
                </div>
                <section
                    class="builder-upsells"
                    v-if="rator.state.upsell.length > 0"
                >
                    <transition-group name="appear" :duration="800" appear>
                        <div class="upsells-title" key="upsell-title">
                            Perfekt passend dazu
                        </div>
                        <div class="scroll-inner" key="upsell-items">
                            <div class="layout-row">
                                <div
                                    class="upsell-item"
                                    v-for="(p, i) in rator.state.upsell"
                                    :key="i"
                                >
                                    <div class="upsell-item-image-wrapper">
                                        <img
                                            class="upsell-item-image"
                                            :src="p.variant.image"
                                        />
                                        <div
                                            :class="[
                                                'upsell-item-cart-button',
                                                { variants: upsell == i }
                                            ]"
                                            @click="pickUpsell(i, p)"
                                        >
                                            <div
                                                class="variations"
                                                v-if="upsell == i"
                                            >
                                                Bitte wähle deine Grösse
                                                <div class="layout-row sizes">
                                                    <div
                                                        class="varr"
                                                        v-for="(v,
                                                        i2) in p.values"
                                                        :key="i2"
                                                        @click.stop="
                                                            pickUpsellVariant(v)
                                                        "
                                                    >
                                                        {{ v.subtitle }}
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                src="@/assets/img/cart.svg"
                                                v-if="upsell != i"
                                                class="upsell-item-cart-icon"
                                            />
                                        </div>
                                    </div>
                                    <div>{{ p.title }}</div>
                                    <div class="upsell-item-price">
                                        {{ price(p.variant.price) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </section>
            </div>
            <transition name="appear" appear :duration="1200">
                <div
                    :class="['builder-options', { open: configuring }]"
                    v-if="vtor"
                >
                    <div>
                        <div v-for="(o, i) in vtor.state.options" :key="i">
                            <colors
                                :data="o"
                                required
                                v-if="o.name == 'Vespa Farbe'"
                                @pick="pickOption"
                            />
                            <options
                                :data="o"
                                required
                                v-if="o.name != 'Vespa Farbe'"
                                @pick="pickOption"
                            />
                        </div>
                    </div>

                    <div v-for="(o, i) in addons" :key="i">
                        <Options
                            :data="o"
                            :closed="true"
                            @pick="pickAddon"
                            v-if="!matchColor(o) && !o.isMulti"
                            :multi="isMultiple(o.handle)"
                        />
                        <MultiOptions
                            :data="o"
                            :closed="true"
                            @pick="pickAddon"
                            v-if="!matchColor(o) && o.isMulti"
                            :multi="isMultiple(o.handle)"
                        />
                        <VariantOption
                            :data="o"
                            :closed="true"
                            @pick="pickAddon"
                            :variant="vtor.state.variant"
                            v-if="matchColor(o)"
                        />
                    </div>

                    <section class="price-section">
                        <div class="underline">
                            <div class="price-value layout-row">
                                <div>{{ price(total) }}</div>
                                <span>oder</span>
                                <div>{{ price(monthly) }}</div>
                            </div>
                            <div class="price-description layout-row">
                                <div>einmalig inkl. MwSt.</div>
                                <div>über 60 Monate inkl. MwSt.</div>
                            </div>
                        </div>
                        <div class="delivery layout-row center between">
                            <div class="title">Lieferzeit</div>
                            <div>15 Arbeitstage</div>
                        </div>
                    </section>
                    <button class="checkout-button" @click="checkout()">
                        <img src="@/assets/img/cart.svg" />
                        Zum checkout
                    </button>
                    <button
                        class="save-configurator__mob"
                        @click="configuring = false"
                    >
                        <span>Ansehen</span>
                    </button>
                </div>
            </transition>
        </div>
    </section>
    <div :class="['fab-button', { toggled: configuring }]">
        <img
            src="@/assets/img/icons/filter-white.svg"
            class="fab-button-filter"
            v-if="!configuring"
            @click="configuring = true"
        />
        <img
            src="@/assets/img/icons/check-white.svg"
            class="fab-button-check"
            v-if="configuring"
            @click="configuring = false"
        />
    </div>
    <div class="overlay-black" v-if="configuring"></div>

    <Dialog2
        title="Neue Konfiguration starten"
        yes="Ja, neustarten"
        no="Nein, zurück zum Konfigurator"
        v-model="askReset"
        @yes="prevYes"
    >
        <p>
            Möchtest du wirklich eine neue Konfiguration mit einem anderen
            Basismodell starten?
        </p>
    </Dialog2>

    <Dialog2
        title="Unpassende Komponenten"
        v-model="pending.value"
        yes="Konfiguration anpassen"
        no="Abbrechen"
        @yes="confirmAddon"
    >
        <p>
            Um <strong>{{ pending.value.title }}: {{ pending.value.subtitle }}</strong> hinzuzufügen, müssen
            folgende Anpassungen vorgenommen werden:
        </p>
        <p v-for="(v, i) in pending.incompatible" :key="i">
            <strong class="remove-sign">- </strong>
            <strong>{{ v.title }}: {{ v.subtitle }}</strong> muss entfernt werden
        </p>
        <p v-for="(v, i) in pending.requires" :key="i">
            <strong class="add-sign">+ </strong>
            <strong>{{ v.title }}: {{ v.subtitle }}</strong> muss hinzugefügt werden
        </p>
    </Dialog2>

    <Dialog2
        title="Teile deine Konfiguration"
        v-model="shared"
        yes=""
        no="Schliessen"
    >
        <div :class="['share-section', { 'copy-done': share.copied }]">
            <div class="url-wrapper">
                <p>{{ shared.url }}</p>
            </div>

            <transition-group name="appear" :duration="400">
                <div class="copy-btn" @click="shareCopy" v-show="shared.copied">
                    <img src="@/assets/img/icons/check-white.svg" />
                </div>
                <div
                    class="copy-btn"
                    @click="shareCopy"
                    v-show="!shared.copied"
                >
                    <img src="@/assets/img/icons/copy.svg" />
                </div>
            </transition-group>
        </div>
    </Dialog2>
</template>

<script>
import Colors from "@/components/Builder/Colors";
import Options from "@/components/Builder/Options";
import VariantOption from "@/components/Builder/VariantOption";
import MultiOptions from "@/components/Builder/MultiOptions";
import { price } from "@/services/Formatters";
import variantor from "@/state/Variantor";
import compat from "@/services/Compatibility";
//import ProductView from "@/state/ProductView";
import Gtm from "@/services/GtmHelper";

export default {
    components: { Colors, Options, VariantOption, MultiOptions },
    inject: ["rator", "cart", "top"],
    data: () => ({
        upsell: -1,
        vtor: null,
        view: null,
        variant: null,
        hasCase: false,
        lastCase: null,
        caseSwapped: false,
        configuring: false,
        openFilter: false,
        askReset: false,
        pending: {
            incompatible: [],
            requires: [],
            value: null
        },
        loading: false,
        shared: null
    }),
    mounted() {
        this.load();
    },
    methods: {
        price,
        matchColor: p => compat.matchColor(p.handle),
        async load() {
            this.loading = true;

            await this.rator.load();

            if (!this.rator.state.bike) this.$router.replace("/configurator");
            else this.init();

            this.loading = false;
        },
        async share() {
            const token = await this.rator.share();

            this.shared = {
                url: `${location.protocol}//${location.host}/s/${token}`,
                copied: false
            };
        },
        async shareCopy() {
            if (!navigator.clipboard) return;

            await navigator.clipboard.writeText(this.shared.url);

            this.shared.copied = true;
        },
        prev() {
            this.askReset = true;
        },
        prevYes() {
            this.rator.prev();
            this.$router.push("/configurator");
        },
        init() {
            this.vtor = variantor(this.rator.state.bike);
        },
        pickUpsell(i, p) {
            if (p.values.length > 1) this.upsell = i;
            else this.pickUpsellVariant(p.values[0]);
        },
        pickUpsellVariant(variant) {
            variant.qty++;
            this.top.notification = {
                title: "In den Warenkorb gelegt",
                content: `Du hast ${variant.title} in den Warenkorb gelegt. Um das Bestelltotal zu sehen, fahre mit deiner Bestellung zur Kasse fort.`,
                status: "success"
            };
            this.upsell = -1;
        },
        clearTag(tag) {
            if (tag.type == "addon") {
                this.pickAddon(tag.variant);
                if (tag.variant === "gepäckträger") {
                    this.pickAddon("gepäckträger");
                }
            } else if (tag.type == "upsell") tag.variant.qty = 0;
        },
        confirmAddon() {
            this.pending.incompatible.forEach(v => this.rator.pickOne(v, false));
            this.pending.requires.forEach(v => this.rator.pickOne(v, true));
            this.rator.pick(this.pending.value);
            this.pending.value = null;
        },
        pickOption(value) {
            this.vtor.select(value);
            this.rator.state.bike = this.vtor.state.variant;
        },
        pickAddon(value) {
            const check = compat.getIssues2(value);

            if (check) {
                this.pending.incompatible = check.incompatible;
                this.pending.requires = check.requires;
                this.pending.value = value;
            } else
                this.rator.pick(value, compat.isMultiple(value.product.handle));
        },
        checkout() {
            const oldVespa = this.cart.findVespa("vespa");
            if (oldVespa) Gtm.removeFromCart(this.$gtm, oldVespa);

            this.cart.clearMode("vespa");
            this.cart.add(this.vtor.state.variant, 1, "vespa");
            Gtm.addToCart(this.$gtm, this.vtor.state.variant, 1);

            this.rator.state.addons.forEach(g => {
                g.values
                    .filter(v => v.selected)
                    .forEach(v => {
                        this.cart.add(v, 1, "vespa");
                    });
            });

            this.rator.state.upsell.forEach(g => {
                g.values
                    .filter(v => v.qty > 0)
                    .forEach(v => {
                        this.cart.add(v, v.qty, "vespa");
                    });
            });

            Gtm.checkoutStart(this.$gtm, this.cart.items);
            this.$router.push("/checkout");
        },
        isMultiple(handle) {
            return compat.isMultiple(handle);
        }
    },
    computed: {
        addons() {
            return compat.getAddons();
            //return this.rator.state.addons;
            /*const bike = this.vtor.state.variant.product.handle;
                const rules = compat.filter(r => r.type == "exclude" && r.bikes.includes(bike));
                const exclude = rules.map(r => r.products).flat();

                return this.rator.state.addons.filter(a => !exclude.includes(a.handle));*/
        },
        tags() {
            const tags = [];

            this.rator.state.addons.forEach((p, i) => {
                p.values
                    .filter(v => v.selected)
                    .forEach(v => {
                        const tag = {
                            type: "addon",
                            index: i,
                            text:
                                p.values.length > 1
                                    ? `${p.title}: ${v?.subtitle}`
                                    : p.title,
                            variant: v,
                            qty: 1
                        };

                        tags.push(tag);
                    });
            });

            this.rator.state.upsell.forEach(p => {
                p.values.forEach(v => {
                    const tag = {
                        type: "upsell",
                        variant: v,
                        qty: v.qty,
                        text:
                            p.values.length > 1
                                ? `${v.title} (${v.subtitle})`
                                : v.title,
                        image: v.image
                    };

                    if (v.qty > 0) tags.push(tag);
                });
            });

            return tags;
        },
        total() {
            if (!this.vtor) return 0;

            const basePrice = parseFloat(this.vtor.state.variant.price);
            const addonPrice = this.rator.state.addons
                .map(p => p.values.filter(v => v.selected))
                .flat()
                .reduce((a, b) => a + b.price, 0);

            return Math.round(basePrice + addonPrice);
        },
        monthly() {
            const months = 60;
            const leasingPrice = this.total - 1000;
            const years = months / 12;
            const interests = leasingPrice * (5.95 / 100) * years;

            return (leasingPrice + interests) / 60;
        },
        months() {
            return 60;
        }
    }
};
</script>

<style lang="scss" src="@/assets/scss/views/Builder.scss"></style>
