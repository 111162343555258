<template>
  <div class="home-page">
    <div class="home-page__hero">
      <div class="home-page__hero__content">
          <div class="home-page__hero__content__orange">
              <div class="hero-img__wrapper">
                  <img src="@/assets/img/vespa-conf.png" srcset="@/assets/img/vespa-conf@2x.png" alt=""/>
              </div>
              <div class="home-page__hero__content__orange__text hero-text-wrap">
                  <h2 class="home-page__hero__content__orange__text__title">Vespa Konfigurator</h2>
                  <h4 class="home-page__hero__content__orange__text__subtitle">Konfiguriere jetzt deine Vespa online!</h4>
                  <div class="home-page__hero__content__orange__text__button">
                      <button class="home-page__hero__content__orange__text__button__shape" type="button" @click="$router.push('/configurator')">
                          <span>Jetzt Vespa konfigurieren</span>
                      </button>
                  </div>
              </div>
          </div>
          <div class="home-page__hero__content__small">
              <div class="home-page__hero__content__small__green">
                  <div class="hero-img__wrapper">
                      <img src="@/assets/img/new_cover.png" srcset=@/assets/img/new_cover@2x.png alt=""/>
                  </div>
                  <div class="home-page__hero__content__small__green__text hero-text-wrap">
                      <router-link to="/category/motolino-starterpaket">
                        <h2 class="home-page__hero__content__small__green__text__title">Starterpaket</h2>
                        <h4 class="home-page__hero__content__small__green__text__subtitle">Das motolino Paket für Anfänger! Einfach Starterpaket in den Warenkorb legen und profitieren.</h4>
                      </router-link>
                  </div>
              </div>
              <div class="home-page__hero__content__small__blue">
                  <div class="hero-img__wrapper">
                      <img src="@/assets/img/back-r-bottom.svg" alt=""/>
                  </div>
                  <div class="home-page__hero__content__small__blue__text hero-text-wrap">
                      <a href="https://news.motolino.ch" target="_blank">
                        <h2 class="home-page__hero__content__small__blue__text__title">Aktionen und Neuigkeiten</h2>
                        <h4 class="home-page__hero__content__small__blue__text__subtitle">Bleibe auf neuestem Stand und verpasse keine Rabatte!</h4>
                      </a>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import vespaImg from '@/assets/img/vespa-conf.png';
import vespaImg2x from '@/assets/img/vespa-conf@2x.png';
import newCoverImg from '@/assets/img/new_cover.png';
import newCoverImg2x from '@/assets/img/new_cover@2x.png';
import backRBottom from '@/assets/img/back-r-bottom.svg';

export default {
    data: () => ({
      vespaImg, vespaImg2x, newCoverImg, newCoverImg2x, backRBottom,
      x: true,
    }),
    methods: {
      y() {
        var p = this;
        setTimeout(function() { p.x = false;}, 1000);
      }
    }
}
</script>

<style scoped lang="scss" src="@/assets/scss/views/Hero.scss"></style>
